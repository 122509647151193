import * as moment from 'moment-timezone';

/**
 * @route GET|POST /token-metas
 */
export class TokenMeta {
    id: string;
    created: moment.Moment;
    lastUsed: moment.Moment;
    source: string;

    static fromJSON(data: any): TokenMeta {
        const token = new TokenMeta();
        token.id = data.id;
        // These timestamps were received with offset +00:00 but that's incorrect, their actual timezone is Eastern
        if (data.created) {
            token.created = moment.tz(data.created, 'America/New_York');
        }
        if (data.lastUsed) {
            token.lastUsed = moment.tz(data.lastUsed, 'America/New_York');
        }
        token.source = data.source;
        return token;
    }

    toJSON(): any {
        return {
            id: this.id,
            created: this.created.format(),
            lastUsed: this.lastUsed.format(),
            source: this.source
        };
    }
}
