import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@app/env';
import {tap} from 'rxjs/operators';
import {TokenService} from '../token/token.service';
import {Observable, lastValueFrom, throwError, timeout} from 'rxjs';
import {getLogger} from '@hrs/logging';

type LoginCredentials = PCMTCredentials | PCMCredentials | Credentials;

export type PCMTCredentials = {data: {type: string; device: string; time: string; key: string;}};
type PCMCredentials = {data: {type: string; code: string; username: string; source: string}};
type Credentials = {data: {type: string; username: string; password: string; source: string}}

/**
 * GatewayApi is a generic REST Api handler and Gateway for dev/prod has been added in.
 *
 * @todo we should phase this class out in the mobile apps and replace it with GatewayService and/or GatewayResourceService
 */
@Injectable({
    providedIn: 'root',
})
export class GatewayApi {
    url: string = environment.API_GATEWAY_URL;
    language: string = 'en';
    private _hrsUserAgentHeader: string;
    private _includeHRSHeaders: boolean = false;
    private _imei: string;
    private _hrsid: string;
    private static timeout: number = 120000; // 2 minutes
    private readonly localLogger = getLogger('GatewayApi');

    set hrsUserAgentHeader(hrsUserAgentHeader: string) {
        this._hrsUserAgentHeader = hrsUserAgentHeader;
    }

    set imei(imei: string) {
        this._imei = imei;
    }

    set hrsid(hrsid: string) {
        this._hrsid = hrsid;
    }

    set includeHRSHeaders(value: boolean) {
        this._includeHRSHeaders = value;
    }

    constructor(public http: HttpClient, private tokenService: TokenService) {}

    public login(credentials: LoginCredentials) {
        return this.getGatewayToken(credentials).pipe(tap((res: any) => {
            this.tokenService.token = res.data.attributes.token;
            this.tokenService.refresh = res.data.attributes.refresh;
        }));
    }

    public getGatewayToken(credentials: LoginCredentials) {
        let reqOpts = {Observe: 'response'};
        if (this._hrsUserAgentHeader) reqOpts['headers'] = {'hrs-user-agent': this._hrsUserAgentHeader};
        return this.post('v1/tokens', credentials, reqOpts);
    }

    public isLoggedIn(): boolean {
        return (
            this.tokenService.token !== null
        );
    }

    public async logout(): Promise<any> {
        // On PCMT, if signout occurs via a tablet unassignment or patient deactivation, the backend blacklists the gateway token before notifying the tablet of the change
        // Catching the request failure here to allow the log out flow to continue. Manual signout requires this step on PCM and in some cases PCMT can allow manual signout
        // Still need to make this delete request for PCMT just in case the sign out was manual due to task retrieval failures
        try {
            await this.deleteBackendToken();
        } catch (e) {
            this.localLogger.debug('deleteBackendToken() failed, the token is likely already blacklisted', e);
        }
        this.tokenService.removeTokens();
        this.tokenService.token = null;
        this.hrsid = null;
    }

    get<T>(endpoint: string, params?: any): Observable<any> {
        const reqOpts: any = this.getReqOpts();
        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            // eslint-disable-next-line guard-for-in
            for (let k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        return this.http.get<T>(this.url + '/' + endpoint, reqOpts).pipe(
            timeout({
                each: GatewayApi.timeout,
                with: () => throwError(() => new Error(`GET ${endpoint} request timed out`))
            })
        );
    }

    post<T>(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        // reqOpts will only be passed in when we are creating a gatewayToken
        if (!reqOpts) {
            reqOpts = this.getReqOpts();
        }

        return this.http.post<T>(this.url + '/' + endpoint, body, reqOpts).pipe(
            timeout({
                each: GatewayApi.timeout,
                with: () => throwError(() => new Error(`POST ${endpoint} request timed out`))
            })
        );
    }

    put<T>(endpoint: string, body: any): Observable<any> {
        const reqOpts = this.getReqOpts();
        return this.http.put<T>(this.url + '/' + endpoint, body, reqOpts).pipe(
            timeout({
                each: GatewayApi.timeout,
                with: () => throwError(() => new Error(`PUT ${endpoint} request timed out`))
            })
        );
    }

    delete<T>(endpoint: string, params?: any): Observable<any> {
        const reqOpts = this.getReqOpts();
        return this.http.delete<T>(this.url + '/' + endpoint, reqOpts).pipe(
            timeout({
                each: GatewayApi.timeout,
                with: () => throwError(() => new Error(`DELETE ${endpoint} request timed out`))
            })
        );
    }

    patch<T>(endpoint: string, body: any): Observable<any> {
        const reqOpts = this.getReqOpts();
        return this.http.patch<T>(this.url + '/' + endpoint, body, reqOpts).pipe(
            timeout({
                each: GatewayApi.timeout,
                with: () => throwError(() => new Error(`PATCH ${endpoint} request timed out`))
            })
        );
    }

    getReqOpts() {
        let headers = new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept-Language', this.language);

        if (this.tokenService.token) headers = headers.append('Authorization', 'Bearer ' + this.tokenService.token);
        if (this._hrsUserAgentHeader) headers = headers.append('hrs-user-agent', this._hrsUserAgentHeader);
        if (this._includeHRSHeaders) {
            if (this._imei) headers = headers.append('x-imei', this._imei);
            if (this._hrsid) headers = headers.append('x-hrsid', this._hrsid);
        }

        return {
            Observe: 'response',
            headers: headers
        };
    }

    // move to gateway.service when change over happens
    public async deleteBackendToken(): Promise<any> {
        if (!this.tokenService.token) return;
        const encodedToken = this.tokenService.token.split('.')[1];
        const decodedToken = JSON.parse(atob(encodedToken));
        return lastValueFrom(this.delete(`tokens/${decodedToken.jti}`));
    }
}
