import {Pipe, PipeTransform} from '@angular/core';

/**
 * Convert gender acronyms to display names.
 */
@Pipe({
    name: 'displayGender'
})
export class DisplayGenderPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'F':
                return 'Female';
            case 'M':
                return 'Male';
            case 'X':
                return 'Other';
            case 'U':
                return 'Unknown';
            default:
                return '';
        }
    }
}
