import * as moment from 'moment';

export class ConvertTime {
    /**
     * @param number minutes
     * @return number
     *
     * Formula:  s = m * 60
     */
    static minutesToSeconds(minutes: number): number {
        return minutes * 60;
    }

    /**
     * @param number hours
     * @return number
     *
     * Formula:  s = h * 3600
     */
    static hoursToSeconds(hours: number): number {
        return hours * 3600;
    }

    /**
     * @param number seconds
     * @return number
     *
     * Formula:  m = s / 60
     */
    static secondsToMinutes(seconds: number): number {
        return seconds / 60;
    }

    /**
     * @param number seconds
     * @return number
     *
     * Formula:  h = s / 3600
     */
    static secondsToHours(seconds: number): number {
        return seconds / 3600;
    }

    static isoToTimestamp(time: string): number {
        return new Date(time).getTime();
    }

    /**
     * Convert Provided Timestamp to Local Timezone.
     * @param string timestamp: the original timestamp.
     * @param string format: the original format of the timestamp.
     * @return string: a timestamp in the original format in the user's device timezone.
     */
    static convertToLocalTZ(timestamp: string, format: string): string {
        const userTZ = moment.tz.guess();
        const estTime = moment.tz(timestamp, format, 'America/New_York');
        const convertedTime = estTime.tz(userTZ).format(format);
        return convertedTime;
    }
}
