export class ObjectUtility {
    /*
        objectsAreTheSame allows for deep object comparisons, with optional strictness level, and key ignoring
        default strictness allows for loose equality comparisons allowing for 1 to equal '1'
        if ignoreKeys has values, those keys will be filtered out, allowing for core comparisons of values the dev knows to be important
     */
    static objectsAreTheSame(objA: {[key: string]: any}, objB: {[key: string]: any}, isStrict = false, ignoreKeys: string[] = []): boolean {
        let areTheSame = true;

        const strictLevel = isStrict ? 'isStrict' : 'isNotStrict';

        const valuesDoNotMatch = {
            'isStrict': (a, b) => a !== b,
            'isNotStrict': (a, b) => a != b
        };

        const isObject = (a, b) => typeof a === 'object' && !Array.isArray(a) && (!!a && !!b);

        const compareArrays = (a, b) => {
            if (a.length === b.length) {
                a.sort();
                b.sort();

                a.forEach((ele, idx) => compareValues(ele, b[idx]));
            } else {
                areTheSame = false;
            }
        };

        const compareValues = (a, b) => {
            if (Array.isArray(a)) {
                compareArrays(a, b);
            } else if (!isObject(a, b) && valuesDoNotMatch[strictLevel](a, b)) {
                areTheSame = false;
            } else if (isObject(a, b) && !this.objectsAreTheSame(a, b, isStrict, ignoreKeys)) {
                areTheSame = false;
            }
        };

        const keysA = Object.keys(objA).filter((key) => !ignoreKeys.includes(key));
        const keysB = Object.keys(objB).filter((key) => !ignoreKeys.includes(key));

        if (keysA.length !== keysB.length) return false;

        for (let key of keysA) compareValues(objA[key], objB[key]);

        return areTheSame;
    }

    /**
     * Method for checking if something is an object since JavaScript does not have a native method
     * @param param
     */
    static isObject(param: any): boolean {
        return param !== null && !Array.isArray(param) && !(param instanceof Set) && typeof param === 'object';
    }
}

