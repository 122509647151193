<ion-header class="c_admin--header">
    <ion-toolbar>
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button
                class="c_admin--home_button"
                (click)="exitAdmin()"
            >
                <hrs-icon icon="home"></hrs-icon>
                <span class="sr-only">{{ 'HOME_TITLE' | translate }}</span>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ 'ADMIN_TITLE' | translate }}
            </span>
            <ion-label
                    *ngIf="hasCorrectAccess"
                    for="settingsPanel"
            >| {{ selectedSetting.fullLabel | translate}}</ion-label>
        </ion-title>
        <ion-buttons slot="end" *ngIf="hasCorrectAccess">
            <ion-button (click)="user.logout()" fill="solid" [textContent]="'Logout'"></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="c_admin--content">

    <hrs-admin-access
            *ngIf="!hasCorrectAccess"
            (hasCorrectPassword)="hasCorrectPassword($event)"
    ></hrs-admin-access>

    <div class="c_admin_settings" *ngIf="hasCorrectAccess">
        <div class="c_admin_settings--panel">
            <ion-tabs class="c_admin_settings--tabs">
                <ion-tab-bar class="c_admin_settings--tabs" slot="bottom">
                    <ion-tab-button
                        *ngFor="let setting of settings"
                        (click)="selectSetting(setting)"
                        [ngClass]="{'tab-selected': setting.enum === selectedSetting.enum}"
                    >
                        <ion-icon name="{{setting.icon}}"></ion-icon>
                        <ion-label>{{setting.tabLabel | translate}}</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
            <ng-container
                id="settingsPanel"
                [ngSwitch]="selectedSetting.enum">
                <domain-settings *ngSwitchCase="adminSettings.DOMAIN"></domain-settings>
            </ng-container>
        </div>
    </div>

</ion-content>
