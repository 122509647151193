export class AllMetrics {
    static activity: MetricIdentifiers = {
        key: 'activity', name: 'Activity',
        shortname: 'Activity', metricdetails: ['duration'],
        risklabels: {medrisk: 'riskMedActivity', highrisk: 'riskHighActivity'}
    };
    static bloodpressure: MetricIdentifiers = {
        key: 'bloodpressure', name: 'Blood Pressure',
        shortname: 'BP', metricdetails: ['systolic', 'diastolic', 'heartrate'],
        risklabels: {medrisk: 'riskMedBloodPressure', highrisk: 'riskHighBloodPressure'}
    };
    static glucose: MetricIdentifiers = {
        key: 'glucose', name: 'Glucose',
        shortname: 'Glucose', metricdetails: ['glucose'],
        risklabels: {medrisk: 'riskMedGlucose', highrisk: 'riskHighGlucose'}
    };
    static survey: MetricIdentifiers = {
        key: 'survey', name: 'Survey',
        shortname: 'Survey', metricdetails: [],
        risklabels: {medrisk: 'riskMedSurvey', highrisk: 'riskHighSurvey'}
    };
    static medication: MetricIdentifiers = {
        key: 'medication', name: 'Medication',
        shortname: 'Medication', metricdetails: [],
        risklabels: {medrisk: 'riskMedMedication', highrisk: 'riskHighMedication'}
    };
    static pulseox: MetricIdentifiers = {
        key: 'pulseox', name: 'Pulse Ox',
        shortname: 'Pulse Ox', metricdetails: ['spo2', 'heartrate'],
        risklabels: {medrisk: 'riskMedPulseox', highrisk: 'riskHighPulseox'}
    };
    static temperature: MetricIdentifiers = {
        key: 'temperature', name: 'Temperature',
        shortname: 'Temperature', metricdetails: ['temperature', 'unit'],
        risklabels: {medrisk: 'riskMedTemperature', highrisk: 'riskHighTemperature'}
    };
    static weight: MetricIdentifiers = {
        key: 'weight', name: 'Weight',
        shortname: 'Weight', metricdetails: ['weight'],
        risklabels: {medrisk: 'riskMedWeight', highrisk: 'riskHighWeight'}
    };
    static steps: MetricIdentifiers = {
        key: 'steps', name: 'Steps',
        shortname: 'Steps', metricdetails: ['steps']
    };
    static patientconnectvoice: MetricIdentifiers = {
        key: 'patientconnectvoice', name: 'PatientConnect Voice',
        shortname: 'PatientConnect Voice', metricdetails: ['patientconnectvoice'],
        risklabels: {medrisk: 'riskMedPCVoice', highrisk: 'riskHighPCVoice'}
    };
}

export interface MetricIdentifiers {
    key: string;
    name: string;
    shortname: string;
    metricdetails: string[];
    risklabels?: { [medrisk: string]: string };
}
