<ion-header>
    <ion-toolbar class="c_app_header is-modal">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ "VIDEO_PANEL" | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="c_call c_video_call--details">
    <div class="c_call--status">
        <div class="c_call--name-callee">{{ callData.patientName }}</div>
        {{ callStatus | translate }}
    </div>
    <div id='videoCallPublisher'></div>
    <div id='videoCallSubscriber' class="c_call--view-callee" style="width: 100%; height: calc(90vh - 5em); position: absolute; top: 0; left: 0; z-index: 500; pointer-events:none;"></div>
</ion-content>
<ion-footer class="c_footer">
    <ion-button class="c_footer--button-back"
                (click)="dismiss()">
        <ion-icon class="c_footer--icon-back"></ion-icon>
    </ion-button>
    <ion-button class="c_footer--button"
                [ngClass]="{
                    'c_call--button-call': !calling,
                    'c_call--button-end': calling
                }"
                (click)="toggleCall()"
                [disabled]="modalClosing">
        <ion-icon class="c_call--icon-dial"
                  name="videocam"></ion-icon>
    </ion-button>
</ion-footer>
