import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalSettingsService} from './globalSettingsService/globalSettings.service';
import {GatewayService} from '../gateway/gateway.service';
import {environment} from '@app/env';
import {phiCompliantTransport, getLogger} from '@hrs/logging';
import {LogEvent, LogLevel, LogEventAction} from '@obsidize/rx-console';

/**
 * Allows custom treatment for log messaging.
 */
 @Injectable({
    providedIn: 'root',
})
export class HRSLoggerMonitor {
    private static LOG_URL_KEY: string = 'loggingURL';
    private static LOG_LEVEL_KEY: string = 'loggingLevel';

    private readonly logger = getLogger('HRSLoggerMonitor');

    private loggingURL: string = '';    // server URL
    private loggingLevel: string = '';  // restriction level for server logging
    private rxConsoleLoggingLevel: number = LogLevel.ERROR;
    private applicationName: string = '';
    private lastPostErrorTimestamp: number = 0;
    private postErrorCount: number = 0;
    private readonly rxConsoleDelegate: LogEventAction;

    private rxConsoleStringToLevel: {[key: string]: number} = {
        'trace':   LogLevel.TRACE,
        'debug':   LogLevel.DEBUG,
        'info':    LogLevel.INFO,
        'warn':    LogLevel.WARN,
        'error':   LogLevel.ERROR,
        'fatal':   LogLevel.FATAL
    };

    private rxConsoleLevelToString: {[key: string]: string} = {
        [LogLevel.TRACE]: 'trace',
        [LogLevel.DEBUG]: 'debug',
        [LogLevel.INFO]: 'info',
        [LogLevel.WARN]: 'warn',
        [LogLevel.ERROR]: 'error',
        [LogLevel.FATAL]: 'fatal'
    };

    constructor(
        private globalSettingsService: GlobalSettingsService,
        private gatewayService: GatewayService,
        private http: HttpClient
    ) {
        this.rxConsoleDelegate = this.onRxConsoleLog.bind(this);
        this.loggingURL = localStorage.getItem(HRSLoggerMonitor.LOG_URL_KEY);
        this.loggingLevel = localStorage.getItem(HRSLoggerMonitor.LOG_LEVEL_KEY);
        this.rxConsoleLoggingLevel = this.rxConsoleStringToLevel[this.loggingLevel];
    }

    public init(app: string, useRealTimeRemoteLogging: boolean = false): void {
        this.logger.debug(`init() app = ${app}, useRealTimeRemoteLogging = ${useRealTimeRemoteLogging}`);
        this.applicationName = app;
        this.globalSettingsService.globalSettingsLoading.then(() => {
            let globalAttributes = this.globalSettingsService.getGlobalAttributes();
            this.loggingURL = globalAttributes['LOG_URL'];
            this.loggingLevel = globalAttributes['LOG_LEVEL'];
            this.rxConsoleLoggingLevel = this.rxConsoleStringToLevel[this.loggingLevel];
            this.updateStorage();
            if (useRealTimeRemoteLogging) {
                phiCompliantTransport.addListener(this.rxConsoleDelegate);
            }
        }, (err) => {
            console.error('HRSLoggerMonitor: Loading global settings failed', err);
        });
    }

    private updateStorage(): void {
        if (this.loggingURL) {
            localStorage.setItem(HRSLoggerMonitor.LOG_URL_KEY, this.loggingURL);
        } else {
            localStorage.removeItem(HRSLoggerMonitor.LOG_URL_KEY);
        }
        if (this.loggingLevel) {
            localStorage.setItem(HRSLoggerMonitor.LOG_LEVEL_KEY, this.loggingLevel);
        } else {
            localStorage.removeItem(HRSLoggerMonitor.LOG_LEVEL_KEY);
        }
    };

    public onRxConsoleLog(log: LogEvent): void {
        const reportToElk = !!this.loggingURL && log.level >= this.rxConsoleLoggingLevel;
        if (reportToElk === false) return;

        const logInfo = {
            message: log.message,
            meta: {
                type: 'rx-console',
                environment: environment.current_environment,
                app: this.applicationName,
                userAgent: navigator.userAgent,
                hrsUserAgent: this.gatewayService.hrsUserAgentHeader,
                tag: log.tag,
                timestamp: new Date(log.timestamp).toJSON(),
                logLevel: this.rxConsoleLevelToString[log.level] || log.level
            }
        };

        this.postLogInfo(logInfo, log.params);
    }

    private postLogInfo(logInfo: Record<string, any>, additional: any[] = []): void {
        // add any additional info to the message
        for (const entry of additional) {
            if (!entry) {
                continue;
            }
            if (typeof entry === 'string') {
                logInfo.message += ` ${entry}`;
                continue;
            }
            // don't let JSON.stringify() explosions kill event posting
            try {
                logInfo.message += ` ${JSON.stringify(entry)}`;
            } catch (e) {
                logInfo.message += ` ${entry}`;
            }
        }

        const headers = new HttpHeaders().append('Content-Type', 'application/json');
        const options = {headers};

        this.http.post(this.loggingURL, JSON.stringify(logInfo), options).subscribe({
            error: (err) => this.notifyLogEventPostErrorRateLimited(err),
            next: () => this.postErrorCount = 0
        });
    }

    private notifyLogEventPostErrorRateLimited(err: any): void {
        this.postErrorCount++;
        const now = Date.now();
        const timeSinceLastError = now - this.lastPostErrorTimestamp;
        
        // rate-limit potential feedback loop between proxy logger and ELK post failures.
        // Prevents issue where below `proxyLogger.error` call would be fed back into
        // this system immediately, potentially causing another post error, which would
        // cause another call to `proxyLogger.error`, etc...
        if (timeSinceLastError < 60 * 1000) {
            return;
        }

        this.lastPostErrorTimestamp = now;
        this.logger.error(`failed to post ELK event! -> ${err}`
            + ` (${this.postErrorCount} errors in the last ${timeSinceLastError} ms)`, err);
    }
}
