/**
 * @route GET /patient-links?filter[caregiver]={hrsid}
 */
export interface PatientLinksRequest {
    data: [
        {
            caregiver: string,
            created: string,
            environment: string,
            id: number,
            patient: string,
            resourceType: string
        }
    ]
}
