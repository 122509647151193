import {Component} from '@angular/core';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {Validators, FormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {PatientProfileService} from '@clinician/providers';
import * as moment from 'moment';
import {ActivationStatus} from '../../enums';

@Component({
    selector: 'page-patient-status-change',
    templateUrl: 'patient-status-change.html',
    styleUrls: ['patient-status-change.scss'],
})
export class PatientStatusChange {
    public status = ActivationStatus;
    public action: string;
    private hrsid: string;
    public data: UntypedFormGroup;
    public date: string;
    public undischargeInfo: string;
    private translateHeader: string;

    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private patientProfileService: PatientProfileService,
        private toastCtrl: ToastController,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.hrsid = this.navParams.get('hrsid');
        this.action = this.navParams.get('action');
        this.date = this.navParams.get('date');
        this.translateHeader = 'PATIENT_' + this.action.toUpperCase();
        this.initializeData();
    }

    ngOnInit() {
        if (this.action == ActivationStatus.ActionUnDischarge) this.undischargeInfo = this.translate.instant('PATIENT_UNDISCHARGE.INFO', {date: this.date});
    }

    public dismiss(data?) {
        this.modalCtrl.dismiss(data);
    }

    public submit() {
        if (this.data.value.note == '') {
            this.data.value.note = 'none';
        }

        let messageSuccess = this.action == ActivationStatus.ActionDischarge ?
            this.translate.instant(this.translateHeader + '.TOAST.SUCCESS', {date: moment(this.data.value.date).format('MM-DD-YYYY')}) :
            this.translate.instant(this.translateHeader + '.TOAST.SUCCESS');
        return this.patientProfileService.changePatientStatus(this.action, this.hrsid, this.data.value).subscribe(
            {
                next: (res: any) => {
                    let message = messageSuccess;
                    let status = 'success';
                    this.throwToastMessage(message, status);
                    if (this.action == ActivationStatus.ActionDischarge || this.action == ActivationStatus.ActionUnDischarge) {
                        this.dismiss(this.data.value);
                    } else {
                        this.dismiss({status: res.status});
                    }
                },
                error: (err) => {
                    let messageFail = this.translate.instant(this.translateHeader + '.TOAST.FAIL');
                    let status = 'fail';
                    this.throwToastMessage(messageFail, status);
                }
            }
        );
    }

    async throwToastMessage(message: string, status: string) {
        let title: string;
        let duration: number;
        let cssClass: string;
        if (status === 'success') {
            title = this.translate.instant('PATIENT_STATUS_CHANGE.TOAST.SUCCESS');
            duration = 3000; // a little shorter for success messages than error messages - 3 seconds
            cssClass = 'toast-success';
        } else if (status === 'fail') {
            title = this.translate.instant('PATIENT_STATUS_CHANGE.TOAST.FAIL');
            duration = 5000; // a little longer for error to allow the user to read - 5 seconds
            cssClass = 'toast-fail';
        } else {
            title = this.translate.instant('PATIENT_STATUS_CHANGE.TOAST.SUCCESS');
            duration = 3000; // a little shorter for success messages than error messages - 3 seconds
        }

        let toast = await this.toastCtrl.create({
            header: title,
            message: message,
            duration: duration,
            cssClass: cssClass,
            position: 'top'
        });
        return await toast.present();
    }

    private initializeData(): void {
        switch (this.action) {
            case ActivationStatus.ActionPause:
                this.data = this.formBuilder.group({
                    patient: this.hrsid,
                    note: '',
                    edvisit: false,
                    readmitted: false
                });
                break;
            case ActivationStatus.ActionResume:
                this.data = this.formBuilder.group({
                    patient: this.hrsid,
                    note: '',
                    edvisit: false,
                    readmitted: false
                });
                break;
            case ActivationStatus.ActionDeactivate:
                this.data = this.formBuilder.group({
                    patient: this.hrsid,
                    note: '',
                    edvisit: false,
                    readmitted: false
                });
                break;
            case ActivationStatus.ActionReactivate:
                this.data = this.formBuilder.group({
                    patient: this.hrsid,
                    note: ''
                });
                break;
            case ActivationStatus.ActionDischarge:
                this.data = this.formBuilder.group({
                    patient: this.hrsid,
                    date: ''
                });
                break;
            case ActivationStatus.ActionUnDischarge:
                this.data = this.formBuilder.group({
                    patient: this.hrsid
                });
                break;
        }
    }
}
