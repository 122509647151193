import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GatewayApi} from '@hrs/providers';

/**
 * This is the service provider for the patient list API calls
 */
@Injectable({
    providedIn: 'root',
})
export class PatientDeviceService {
    patientListLoaded;

    constructor(
        public gatewayApi: GatewayApi,
        public http: HttpClient
    ) {}

    /**
     * Call api to get the device inventory
     */

    getAvailableDeviceInventory() {
        return this.gatewayApi.get(
            'apiv2/inventory?/',
            {available: true}
        );
    }

    /**
     * Call api to mark the patient as reviewed
     */

    saveEditToAssignedDevice(hrsid, selectedDevice) {
        return this.gatewayApi.post(
            'apiv2/patients/' + hrsid + '/device',
            {deviceId: selectedDevice.id}
        );
    }

    /**
     * Call api to get device information and connectivity for a specific patient
     */

    getPatientDeviceInformation(deviceId) {
        return this.gatewayApi.get(
            'apiv2/inventory/status/' + deviceId,
            { }
        );
    }

    /**
     * Call api to remove device from patient and reset to nothing assigned
     */

    removeDevice(detail) {
        return this.gatewayApi.delete(
            'apiv2/patients/' + detail.hrsid + '/device',
            { }
        );
    }
}
