import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {TranslateService} from '@ngx-translate/core';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {DeeplinkMatch, Deeplinks} from '@ionic-native/deeplinks/ngx';
import {
    ActionSheetController,
    AlertController,
    ModalController,
    Platform,
    ToastController,
    MenuController,
    LoadingController
} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import * as moment from 'moment';
import {AndroidPermissionResponse, AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {Device} from '@ionic-native/device/ngx';
import {environment} from '@app/env';
import {
    AdminService,
    CommunicationService,
    Environment,
    FirebaseNotifications,
    ModalService,
    PatientProfileService,
    UserAgentProvider
} from '@clinician/providers';
import {Settings} from './services/settings/settings';
import {User} from './services/user/user';
import {GatewayService} from '@hrs/gateway';
import {BroadcastService, GatewayApi, GlobalSettingsService, HRSLoggerMonitor, TokenService} from '@hrs/providers';
import {VideoPage} from './communication/video/video.page';
import {VoicePage} from './communication/voice/voice.page';
import {ChatPage} from './communication/chat/chat.page';
import {Router} from '@angular/router';
import {OrganizationalLoginPage} from './organizational-login/organizational-login.page';
import {Storage} from '@ionic/storage-angular';
import {BuildUtility} from '@hrs/utility';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {PermissionService} from './services/permissions/permission.service';
import {INotificationPayload} from 'cordova-plugin-firebase-messaging';
import {getLogger, primaryTransport} from '@hrs/logging';
import {FirebaseMessagingService} from './services/firebase/firebase-messaging.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: [Deeplinks]
})
export class AppComponent {
    private readonly logger = getLogger('AppComponent');
    messageData: any;
    modalState: any;
    versionNumber: string;
    showButton: any = false; // will not show the log out button by default
    isDisplaying: boolean = false; // toast for forced log outs
    deviceVersion: any;

    constructor(
        private adminService: AdminService,
        private appVersion: AppVersion,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private callNumber: CallNumber,
        private alertCtrl: AlertController,
        private toastCtrl: ToastController,
        private actionSheetCtrl: ActionSheetController,
        private loggerMonitor: HRSLoggerMonitor,
        private firebase: FirebaseNotifications,
        private gatewayApi: GatewayApi,
        private gateway: GatewayService,
        private globalSettingsService: GlobalSettingsService,
        private broadCastService: BroadcastService,
        private modalService: ModalService,
        public user: User,
        private settings: Settings,
        private storage: Storage,
        private tokenService: TokenService,
        private userAgentProvider: UserAgentProvider,
        private Environment: Environment,
        private communication: CommunicationService,
        public modalCtrl: ModalController,
        public menuCtrl: MenuController,
        private profile: PatientProfileService,
        private deeplinks: Deeplinks,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private loadingCtrl: LoadingController,
        private androidPermissions: AndroidPermissions,
        public device: Device,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private firebaseMessagingService: FirebaseMessagingService
    ) {
        this.init();
    }

    private init(): void {
        primaryTransport.enableDefaultBroadcast();
        this.loggerMonitor.init('ccmobile');
        this.initDeviceVersion();
        this.platform.ready().then(async () => {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            this.firebaseMessagingService.initialize().catch((e) => {
                this.logger.phic.warn(`failed to initialize FirebaseMessagingService: ${e}`);
            });
            this.statusBar.styleDefault();
            await this.userAgentProvider.getUserAgentInfo();
            this.logger.phic.log(environment.current_environment);
            this.menuCtrl.swipeGesture(false);
            this.settings.load().finally(async () => {
                // Check for any domain changes because app will lose those changes on force kill.
                await this.storage.get('updatedDomain').then((updatedDomain) => {
                    if (updatedDomain) {
                        BuildUtility.setDomain(updatedDomain).then((domain) => {
                            this.gatewayApi.url = domain;
                            this.tokenService.url = domain;
                        }).catch((err) => {
                            this.logger.phic.error(err);
                        });
                    }
                });

                if (this.platform.is('cordova')) {
                    const initialSetupComplete = this.settings.getValue('initialSetupComplete');
                    if (!initialSetupComplete) {
                        if (this.platform.is('ios')) {
                            this.firebase.askIOSPushPermission();
                        } else {
                            this.firebase.createAndroidNotificationChannel();
                        }
                        this.settings.setValue('initialSetupComplete', true);
                    }

                    // Firebase account switching is currently an Android only feature.
                    // Due to the stricter nature of Firebase iOS configuration, we are only able to reconfigure Firebase Apps at runtime for Android.
                    // Check the platform for Android and configure Firebase to the account associated with the current domain.
                    if (this.platform.is('android')) {
                        this.firebase.setFirebaseAccount(BuildUtility.getDomainName() === 'prod' ? 'prod' : 'test').catch((err) => {
                            this.logger.phic.error(err);
                        });
                    }
                }
                this.populateVersionNumber();
                this.initTranslate().then(() => {
                    if (this.platform.is('android')) {
                        this.permissionService.checkVideoCallPermissions(this.getDeviceVersion()).then(() => {
                            this.checkAndroidPermission(['android.permission.POST_NOTIFICATIONS'],
                                'ANDROID_NOTIFICATION_PERMISSION_TITLE', 'ANDROID_NOTIFICATION_PERMISSION_MESSAGE').then(() => { // we want permissions to be asked for one after the other
                                this.checkAndroidPermission(['android.permission.READ_MEDIA_IMAGES', 'android.permission.READ_MEDIA_VIDEO', 'android.permission.READ_MEDIA_AUDIO'],
                                    'ANDROID_MEDIA_PERMISSION_TITLE', 'ANDROID_MEDIA_PERMISSION_MESSAGE');
                            });
                        });
                    }
                });
                this.user.loadFromSettings(this.settings);
                const token = this.settings.getValue('token');
                const refresh = this.settings.getValue('refresh');
                if (token) {
                    this.tokenService.storeTokens({
                        attributes: {
                            token: token,
                            refresh: refresh
                        }
                    });
                }
                // persist login if tokens are still valid
                if (this.user.isLoggedIn()) {
                    this.globalSettingsService.loadGlobalSettings();
                    // patient list
                    this.Environment.getEnvironment().subscribe(
                        {
                            next: (res) => {
                                this.logger.phic.log('Successfully loaded Environment.');
                            },
                            error: (err) => {
                                this.logger.phic.error('Failed to get Environment.');
                                this.user.logout();
                            }
                        }
                    );
                    if (this.platform.is('cordova')) {
                        this.firebase.initializeFirebase(this.user);
                    }
                    this.getBackgroundFCMNotification();
                    this.router.navigateByUrl('/patient-list');
                } else {
                    // login
                    this.router.navigateByUrl('/welcome');
                }

                if (this.platform.is('cordova')) {
                    // iOS related source code has been completely removed from cordova-plugin-splashscreen
                    // since it has been integrated into the core of the Cordova-iOS 6.x platform.
                    if (this.platform.is('ios')) {
                        (navigator as any).splashscreen.hide();
                    } else {
                        this.splashScreen.hide();
                    }
                }
                this.initReceiveNotificationsListener();
            });
            this.routeWithDeeplinks();
            this.broadCastService.interceptorLogout.asObservable().subscribe((values) => {
                this.logger.phic.debug('Observing Interceptor', values);
                if (values) {
                    this.loadingCtrl.getTop().then((element) => {
                        if (element) {
                            this.loadingCtrl.dismiss();
                        }
                    });
                    if (!this.isDisplaying && values.error) {
                        this.isDisplaying = true;
                        setTimeout(() => {
                            this.isDisplaying = false;
                        }, 2000);
                        if (values.error.message === 'LOGIN_ERROR.CONCURRENT_SESSIONS') {
                            this.forcedLogoutToast(values.error.message);
                        } else {
                            this.forcedLogoutToast();
                        }
                    }
                    this.user.logout();
                }
            });
        });
    }

    public async initTranslate(): Promise<any> {
        // English will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
        // Apply the user's preferred language
        let language = this.translate.getBrowserLang() || 'en';
        await firstValueFrom(this.translate.use(language)).catch((err: any) => {
            this.logger.phic.error(`failed to set used language "${language}"`, err);
        });
        moment.locale(language);
        this.gatewayApi.language = language;
        this.gateway.language = language;
        await this.settings.setValue('language', language);
    }

    /**
    * Display reason for getting a forced 401 or 302 error from the api-interceptor
    * @param message
    */
    async forcedLogoutToast(message?: string): Promise<any> {
        let text = message || 'LOGIN_FORCED_LOGOUT';
        let toast = await this.toastCtrl.create({
            message: this.translate.instant(text),
            cssClass: 'toast-fail',
            position: 'top',
            buttons: [
                {
                    text: this.translate.instant('CLOSE_BUTTON'),
                    role: 'cancel'
                }
            ]
        });
        return await toast.present();
    }

    /**
    * Whenever the user is logged out, redirects to LoginPage
    */

    logoutClicked() {
        this.logger.phic.debug('Logout User');
        this.user.logout();
        this.menuCtrl.close().then(()=> {
            this.logger.phic.error('Closed menu ', this.menuCtrl.isOpen());
        });
    }

    public openAdmin(): void {
        this.adminService.openAdmin();
    }

    /**
    * Start support call
    */
    initSupportCall() {
        let supportNumber = '908-280-0420';

        if (Environment.supportNumber) {
            supportNumber = Environment.supportNumber;
        }

        if (this.platform.is('ios')) {
            // ios shows a prompt natively
            this.dialPhoneNumber(supportNumber);
        } else {
            // android: add a prompt to prevent accidental calling
            this.showSupportCallPrompt(supportNumber);
        }
        this.menuCtrl.close();
    }

    /**
    * Display prompt to start call on android
    * @param supportNumber
    */
    async showSupportCallPrompt(supportNumber) {
        let supportAlert = await this.alertCtrl.create({
            header: supportNumber,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    handler: () => {}
                },
                {
                    text: this.translate.instant('DIAL'),
                    handler: () => {
                        this.dialPhoneNumber(supportNumber);
                    }
                }
            ]
        });
        return await supportAlert.present();
    }

    /**
    * Dial support
    * @param phoneNumber
    */
    dialPhoneNumber(phoneNumber) {
        this.callNumber.callNumber(phoneNumber, true)
            .then((res) => {
                // maybe we should log when people call support
            })
            .catch((err) => {
                this.dialPhoneNumberFailToast();
            });
    }

    async dialPhoneNumberFailToast() {
        let dialSuccessToast = await this.toastCtrl.create({
            message: this.translate.instant('CALL_FAIL'),
            duration: 3000,
            position: 'top'
        });
        return await dialSuccessToast.present();
    }

    initReceiveNotificationsListener(): void {
        this.communication.incomingVideoCall$.subscribe((data: any) => {
            let answer = !!data.wasTapped;
            this.handleIncomingVideoCall(data, answer);
        });

        this.communication.incomingVoiceCall$.subscribe((data: any) => {
            let answer = !!data.wasTapped;
            this.handleIncomingVoiceCall(data, answer);
        });

        this.communication.newChatMessage$.subscribe((data: any) => {
            this.handleNewMessage(data);
        });
    }

    private getBackgroundFCMNotification(): void {
        this.firebase.getInitialPushPayload().then((payload: INotificationPayload) => {
            this.logger.phic.log('Received FCM when app is closed:', payload);
            if (payload && payload.jsonData) {
                payload = {...payload, ...JSON.parse(payload.jsonData)};
                this.logger.phic.debug('Updated Payload', payload);
            }
            if (payload && payload.wasTapped) {
                switch (payload.type) {
                    case 'text':
                    case 'chat':
                        this.handleNewMessage(payload);
                        break;
                    case 'video':
                    case 'video-zoom':
                        if (payload.action === 'incoming_call') {
                            this.handleIncomingVideoCall(payload);
                        }
                        break;
                    case 'voicecall':
                    case 'voice':
                        if (!payload.action || (payload.action && payload.action !== 'call_left')) {
                            this.handleIncomingVoiceCall(payload, false);
                        }
                }
            }
        });
    }

    async handleIncomingVideoCall(data: INotificationPayload, answer?: boolean): Promise<void> {
        let caller;
        if (typeof data.caller === 'string') {
            caller = JSON.parse(data.caller);
        } else {
            caller = data.caller;
        }
        const patientName = await lastValueFrom(this.profile.getPatientProfile(caller.hrsid)).then((response) => {
            return response['name'].first + ' ' + response['name'].last;
        }, () => {
            return 'Patient';
        });
        this.communication.videoParticipantId = data.participantId;
        this.communication.updateParticipantStatus('received');

        // if no call is in the foreground, open incoming call
        if (!this.modalService.getModalStatus('VideoPage') && !this.modalService.getModalStatus('VoicePage')) {
            let modal = await this.modalCtrl.create({
                component: VideoPage,
                componentProps: {
                    callData: {
                        patientName: patientName,
                        patientHrsId: caller.hrsid,
                        callId: data.callId,
                        answer: answer,
                        participantId: data.participantId
                    }
                }});
            return modal.present();
        } else {
            // if a call is in the foreground, show alert and offer option to switch calls
            this.actionSheetCtrl.create(<any>{
                title: this.translate.instant('NEW_INCOMING_CALL') + patientName,
                buttons: [
                    {
                        text: this.translate.instant('END_CURRENT_CALL'),
                        handler: () => {
                            // close current video call modal and open new incoming call
                            this.communication.exitVideoCallEnterNew.next(null);
                            this.communication.exitVoiceCallEnterNew.next(null);
                            this.handleIncomingVideoCall(data, true);
                        }},
                    {
                        text: this.translate.instant('IGNORE'),
                        handler: () => {
                            this.logger.phic.log('call ignored');
                            // tell comms service we hung up
                            this.communication.updateParticipantStatus('declined');
                        }
                    }]}).then((actionSheet) => {
                actionSheet.present();
            });
        }
    }

    async handleIncomingVoiceCall(data: INotificationPayload, answer: boolean): Promise<void> {
        let caller;
        if (typeof data.caller === 'string') {
            caller = JSON.parse(data.caller);
        } else {
            caller = data.caller;
        }
        const patientName = await lastValueFrom(this.profile.getPatientProfile(caller.hrsid)).then((response) => {
            return response['name'].first + ' ' + response['name'].last;
        }, () => {
            return 'Patient';
        });
        if (!this.modalService.getModalStatus('VideoPage') && !this.modalService.getModalStatus('VoicePage')) {
            const modal = await this.modalCtrl.create({
                component: VoicePage,
                componentProps: {
                    callData: {
                        patientName: patientName,
                        patientHrsId: caller.hrsid,
                        callId: data.callid,
                        answer: answer
                    }
                }
            });
            modal.present();
        } else {
            this.actionSheetCtrl.create(<any>{
                title: this.translate.instant('NEW_INCOMING_CALL') + patientName,
                buttons: [
                    {
                        text: this.translate.instant('END_CURRENT_CALL'),
                        handler: () => {
                            // close current call modal and open new incoming call
                            this.communication.exitVideoCallEnterNew.next(null);
                            this.communication.exitVoiceCallEnterNew.next(null);
                            this.handleIncomingVoiceCall(data, true);
                        }
                    },
                    {
                        text: this.translate.instant('IGNORE'),
                        handler: () => {
                            this.logger.phic.log('Voice call ignored');
                        }
                    }]
            }).then((actionSheet) => {
                actionSheet.present();
            });
        }
    }

    async handleNewMessage(data: INotificationPayload): Promise<void> {
        let caller;
        if (typeof data.caller === 'string'){
            caller = JSON.parse(data.caller);
        } else {
            caller = data.caller;
        }
        let modalState = this.modalService.getModalStatus('ChatMessagesModalPage');
        if (data.wasTapped) {
            // new message notification was tapped while the app was in the background
            const modal = await this.modalCtrl.create({
                component: ChatPage,
                componentProps: {patient: caller}
            });
            return await modal.present();
        } else {
            if (!modalState || !modalState.status || caller.hrsid !== modalState.patientHrsid) {
                this.messageData = data;
                if (modalState) {
                    this.modalState = modalState;
                }
                const patientName = await lastValueFrom(this.profile.getPatientProfile(caller.hrsid))
                    .then((response) => {
                        return response['name'].first + ' ' + response['name'].last;
                    }, () => {
                        return 'Patient';
                    });
                let toast = await this.toastCtrl.create({
                    message: this.translate.instant('NEW_CHAT_MESSAGE') + patientName,
                    duration: 10000,
                    position: 'top',
                    buttons: [{
                        text: this.translate.instant('VIEW'),
                        handler: () => this.messageClose('close'),
                    }]
                });

                return await toast.present();
            } else {
                // patient chat modal is open, update with incoming message
                this.communication.getChatNewMessage.next(data);
            }
        }
    }

    messageClose(data): void {
        if (data == 'close') {
            if (this.modalState && this.modalState.status) {
                this.communication.exitChatOpenNew.next(null);
            }
            this.messageData.wasTapped = true;
            this.handleNewMessage(this.messageData);
        }
    }

    populateVersionNumber() {
        if (this.platform.is('cordova')) {
            this.appVersion.getVersionNumber().then((versionNumber) => {
                if (versionNumber) {
                    this.versionNumber = 'v' + versionNumber;
                }
            });
        } else {
            this.versionNumber = 'v1.0.0';
        }
    }

    openAddPatientPage() {
        this.closeMenu().then(() => this.router.navigateByUrl('/add-patient'));
    }

    async closeMenu(): Promise<void> {
        if (await this.menuCtrl.isOpen()) {
            await this.menuCtrl.close();
            await this.menuCtrl.enable(false);
        }
    }

    routeWithDeeplinks(): void {
        // deeplinks allow navigation from the system browser to our app
        // To test via iOS Safari enter ccmobile:// in the browser and it will launch the app if it is installed
        this.deeplinks.route({
            '/login': OrganizationalLoginPage
        }).subscribe(
            {
                next: (match: DeeplinkMatch) => {
                    if (match.$link.path === '/login') {
                        this.user.logout().then(() => {
                            let login = () => {
                                if (match.$args && match.$args.auth) {
                                    let authData = JSON.parse(atob(match.$args.auth));
                                    // This tells the OrganizationalLoginPage that we are logging in so that it can show the spinner if it isn't already
                                    this.user.loggingInSSOSubject.next(null);
                                    // Login now. OrganizationalLoginPage is listening to the loggedInSubject to know when this is successful
                                    let loginSubscription = this.user.loginWithData(authData).subscribe(() => {
                                        loginSubscription.unsubscribe();
                                    });
                                }
                            };
                            if (this.activatedRoute.component !== OrganizationalLoginPage) {
                                // If we were on the welcome page then move forward to OrganizationalLoginPage
                                this.router.navigateByUrl('/organizational-login').then(() => {
                                    login();
                                });
                            } else {
                                login();
                            }
                        });
                    }
                },
                error: (nomatch) => {
                    // nomatch.$link - the full link data
                    this.logger.phic.error(`Got a deeplink that didn't match`, JSON.stringify(nomatch));
                }
            }
        );
    }

    close() {
        this.menuCtrl.close();
    }

    public checkAndroidPermission(permission: string[], permissionAlertTitle: string, permissionMessage: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.getDeviceVersion() > 12) {
                let requiredPermissions: string[] = permission;
                let missingPermissions: string[] = [];
                for (const requiredPermission of requiredPermissions) {
                    await this.checkForPermission(requiredPermission).then((result) => {
                        if (!result.hasPermission) {
                            missingPermissions.push(requiredPermission);
                        }
                    });
                }
                if (missingPermissions && missingPermissions.length > 0) {
                    {
                        let alert = await this.alertCtrl.create({
                            header: this.translateService.instant(permissionAlertTitle),
                            message: this.translateService.instant(permissionMessage),
                            buttons: [
                                {
                                    text: this.translateService.instant('CLOSE_BUTTON'),
                                    handler: () => {
                                        this.androidPermissions.requestPermissions(missingPermissions).then(
                                            (result) => {
                                                resolve();
                                            }, (error) => {
                                                this.logger.phic.error('Error requesting permission from Android' + error);
                                                reject(error);
                                            }
                                        );
                                    }
                                }
                            ]
                        });
                        return await alert.present();
                    }
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    public async checkForPermission(permission: string): Promise<AndroidPermissionResponse> {
        return this.androidPermissions.checkPermission(permission);
    }

    /**
 * @returns Quering the device version from Device plugin if it is not initialized yet
 */
    public getDeviceVersion(): number {
        if (!this.deviceVersion) {
            this.initDeviceVersion();
        }
        return this.deviceVersion;
    }

    /**
     * Initializing the device version
     */
    private initDeviceVersion() {
        let devVersion = Number(this.device.version);
        if (!Number.isNaN(devVersion)) {
            this.deviceVersion = devVersion;
        }
    }
}
