import {GatewayResponse} from '@hrs/gateway';
import {BehaviorSubject} from 'rxjs';

/**
 * This is to handle the circular dependency of the ApiInterceptor/ App Component / User
 */

export class BroadcastService {
    public interceptorLogout: BehaviorSubject<GatewayResponse<any>>;
    public miscAuthError: BehaviorSubject<GatewayResponse<any>>;

    constructor() {
        // initialize it by setting the rejection error object to null
        this.interceptorLogout = new BehaviorSubject<GatewayResponse<any>>(null);
        this.miscAuthError = new BehaviorSubject<GatewayResponse<any>>(null);
    }
}
