import { Injectable } from '@angular/core';

/**
 * This service is used in order to inform components whether or not a particular modal is open.
 */
@Injectable({
    providedIn: 'root',
})
export class ModalService {
    static modalStatus = {};

    constructor() {}

    setModalStatus(modal, status: boolean, patientHrsid?: string) {
        if (patientHrsid) {
            ModalService.modalStatus[modal] = {status: status, patientHrsid: patientHrsid};
        } else {
            ModalService.modalStatus[modal] = status;
        }
    }

    getModalStatus(modal) {
        return ModalService.modalStatus[modal];
    }

    isModalTypeOpen(modalType: string): boolean {
        return this.getModalStatus(modalType);
    }
}
