/**
 * @route GET /apiv2/cgc-patients/{hrsid}
 */
export interface CgcPatientApiv2Response {
    metrics: {
        [key: string]: {
            percentComplete: number,
            risk: string
        }
    },
    profile: {
        hrsid: string
    },
    ts: number
}
