<ion-header>
    <ion-toolbar class="c_app_header is-modal statusbar-padding">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">{{ 'CAREPLAN' | translate }}</span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="careplan-page">
    <ion-card class="c_card">
        <ion-card-header class="c_card--header">
            {{ 'CAREPLAN_PRESETS' | translate }}
        </ion-card-header>
        <ion-card-content class="c_card--content"
                          *ngIf="populateActiveItems('presets').length > 0">
            <ion-badge class="c_careplan--badge"
                       *ngFor="let preset of populateActiveItems('presets')"
                       item-start>
                {{ presets[preset].item.attributes.name }}
            </ion-badge>
        </ion-card-content>
        <ion-card-content class="c_card--content"
                          *ngIf="populateActiveItems('presets').length === 0">
            <ion-item class="c_careplan--no_content"
                      item-start>
                - {{ 'CAREPLAN_NO_CONTENT' | translate }} -
            </ion-item>
        </ion-card-content>
        <ion-button class="c_button-center"
                    (click)="openEditCarePlanModal(false)">
            {{ 'CAREPLAN_EDIT_PRESETS' | translate }}
        </ion-button>
    </ion-card>
    <ion-card class="c_card">
        <ion-card-header class="c_card--header">
            {{ 'CAREPLAN_MODULES' | translate }}
        </ion-card-header>
        <ion-card-content class="c_card--content"
                          *ngIf="populateActiveItems('modules').length > 0">
            <ion-badge class="c_careplan--badge"
                       *ngFor="let module of populateActiveItems('modules')"
                       item-start>
                {{ modules[module].item.attributes.name }}
            </ion-badge>
        </ion-card-content>
        <ion-card-content class="c_card--content"
                          *ngIf="populateActiveItems('modules').length === 0">
            <ion-item class="c_careplan--no_content"
                      item-start>
               - {{ 'CAREPLAN_NO_CONTENT' | translate }} -
            </ion-item>
        </ion-card-content>
        <ion-button class="c_button-center"
                    (click)="openEditCarePlanModal(true)">
            {{ 'CAREPLAN_EDIT_MODULES' | translate }}
        </ion-button>
    </ion-card>
    <ion-card class="c_card c_careplan--notice">
        <ion-icon class="c_careplan--notice_icon"></ion-icon>
        {{ 'CAREPLAN_ADVANCED_NOTICE' | translate }}
    </ion-card>
</ion-content>
