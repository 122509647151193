import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import {TokenResponse} from '@hrs/gateway';
import {GatewayApi, TokenService} from '@hrs/providers';
import {FirebaseNotifications} from '../firebase/firebase';
import {tap} from 'rxjs/operators';
import {Settings} from '../settings/settings';
import * as jwt from 'jwt-decode';
import {Storage} from '@ionic/storage-angular';
import {Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SessionData} from '../../../../../../libs/interfaces/session-data-response.interface';
import {BuildUtility, CollectionUtility} from '@hrs/utility';
import {Environment} from '../environment/environment';

/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/etc.
 *
 * This User provider makes calls to our API at the `login` endpoints.
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */
@Injectable({
    providedIn: 'root',
})
export class User {
    token: any;
    static role;
    static _data: any;
    data: any;
    session: SessionData;
    loggingInSSOSubject: Subject<any> = new Subject();
    loginResultSubject: Subject<boolean> = new Subject();
    sourceApp: string;

    constructor(
        public gatewayApi: GatewayApi,
        public http: HttpClient,
        private firebase: FirebaseNotifications,
        private platform: Platform,
        private settings: Settings,
        private storage: Storage,
        private tokenService: TokenService,
        private environment: Environment,
        private router: Router
    ) {
        this.sourceApp = 'ClinicianConnect Mobile';
        this.tokenService.sourceApp = this.sourceApp;

        this.tokenService.refreshTokenSubject.subscribe((tokenData: TokenResponse) => {
            this.settings.setValue('token', tokenData.attributes.token);
            this.settings.setValue('refresh', tokenData.attributes.refresh);
            this.data = jwt(tokenData.attributes.token);
            this.settings.setValue('userData', this.data);
        });
    }

    loadFromSettings(settings: Settings) {
        const data = settings.getValue('userData');
        if (data) {
            this.data = data;
        }
        const session = settings.getValue('sessionData');
        if (session) {
            this.session = session;
        }
    }

    getClinicianName(): string {
        return this.session.firstname + ' ' + this.session.lastname;
    }

    canContactPatient() {
        return (
            this.data && (
                this.data.roles.includes('Moderator') ||
                this.data.roles.includes('Clinician') ||
                this.data.roles.includes('Pharmacist')
            )
        );
    }

    canEditPatientInfo() {
        return (
            this.data && (
                this.data.roles.includes('Moderator') ||
                this.data.roles.includes('Clinician')
            )
        );
    }

    isReadOnlyClinician(): boolean {
        return (
            this.data && !this.canEditPatientInfo() && this.data.roles.includes('ReadOnly')
        );
    }

    isLoggedIn() {
        return (
            this.data &&
            this.data.exp > Math.floor(Date.now() / 1000) &&
            this.gatewayApi.isLoggedIn()
        );
    }

    login(accountInfo) {
        const credentials = {
            data: {
                type: 'credentials',
                username: accountInfo.user,
                password: accountInfo.pass,
                source: this.sourceApp
            }
        };
        return this.loginWithData(credentials);
    }

    loginWithData(credentials) {
        let data = this.gatewayApi.login(credentials);

        return data.pipe(
            tap(
                {
                    next: (res: any) => {
                        let resData = res.data.attributes;
                        this.data = jwt(resData.token);
                        if (this.data.type !== 'clinician') {
                            // Logout user if not of clinician user-type.
                            this.data.clear();
                            this.session = undefined;
                            this.router.navigateByUrl('/welcome');
                            this.loginResultSubject.next(false);
                            return;
                        }

                        // for backwards compatibility subgroups may come in as JSON strings
                        // this conversion to arrays allows for proper handling of clins with multiple subgroups
                        this.data.subgroup = CollectionUtility.convertToArray(this.data.subgroup);

                        this.tokenService.storeTokens(res.data);

                        if (this.platform.is('cordova')) {
                            this.firebase.initializeFirebase(this);
                        }

                        let getEnvironmentSubscription = this.environment.getEnvironment().subscribe(() => {
                            this.settings.setValue('userData', this.data);
                            this.settings.setValue('token', resData.token);
                            this.settings.setValue('refresh', resData.refresh);
                            getEnvironmentSubscription.unsubscribe();
                        });

                        let getSessionDataSubscription = this.getSessionData().subscribe(() => {
                            getSessionDataSubscription.unsubscribe();
                        });

                        this.loginResultSubject.next(true);
                    },
                    error: () => {
                        this.loginResultSubject.next(false);
                        return of({});
                    }
                }
            ));
    }

    get id(): string {
        if (User._data) {
            return User._data.sub;
        }
    }

    /**
    * Fetch for specific information like clinician name that are not included in the login or environment response
    */
    getSessionData(): Observable<any> {
        let getDetails = this.gatewayApi.get('apiv2/', {});

        return getDetails.pipe(tap((res: any) => {
            this.session = res;
            this.settings.setValue('sessionData', res);
        }));
    }

    /**
    * Log the user out, which forgets the session
    */
    public async logout(): Promise<void> {
        if (this.isLoggedIn()) {
            this.storage.clear();
            this.data = undefined;
            this.session = undefined;
            await this.gatewayApi.logout();
            if (this.platform.is('cordova')) {
                this.firebase.deleteInstanceId();
            }
            this.router.navigateByUrl('/welcome');
            // Store Domain so that the changes are not lost on force-kill.
            const domainName = BuildUtility.getDomainName();
            const updatedDomain = domainName === 'hrsdomain' ? BuildUtility.getHRSDomain() : domainName;
            this.storage.set('updatedDomain', updatedDomain);
        }
    }
}
