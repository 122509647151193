/**
 * hz: hertz
 * cpm: cycles per minute
 */

export class ConvertHeartRate {
    /**
     * @param number cpm
     * @return number
     *
     * Formula: 1 cpm = 1/60 hz
     */
    static cpmToHz(cpm: number): number {
        return cpm / 60;
    }

    /**
     * @param number hz
     * @return number
     *
     * Formula: 1hz = 60 cpm
     */
    static hzToCpm(hz: number): number {
        return hz * 60;
    }
}
