// Angular / Ionic
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule, Storage} from '@ionic/storage-angular';

// Native Plugins
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {Device} from '@ionic-native/device/ngx';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {NativeRingtones} from '@ionic-native/native-ringtones/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
// NGX
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {Zoom} from '@ionic-native/zoom/ngx';

// Services / Components
import {
    FirebaseNotifications, CommunicationService, ModalService, Environment, SurveyDetailsService, DataExchangeService, ReviewedStatusService
} from '@clinician/providers';
import {User} from './services/user/user';
import {Settings} from './services/settings/settings';
import {GatewayResourceService, GatewayService} from '@hrs/gateway';
import {GatewayApi, ApiInterceptor, BroadcastService, GlobalSettingsService, HRSLoggerMonitor, TokenService} from '@hrs/providers';

// Modals
import {AddDevicePageModule} from './patient/device/add-device.module';
import {ResetMetricsPageModule} from './patient/reset-metrics/reset-metrics.module';
import {PatientStatusChangeModule} from './patient/patient-status-change/patient-status-change.module';

// Communication
import {ChatPageModule} from './communication/chat/chat.module';
import {VideoPageModule} from './communication/video/video.module';
import {VoicePageModule} from './communication/voice/voice.module';
import {AuthGuard} from './auth.guard';

// Pipes
import {PipesModule} from '../pipes/pipes.module';

// App and Components
import {AdminModule} from './admin/admin.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CarePlanPageModule} from './patient/careplan/careplan.module';
import {EditCareplanModule} from './patient/careplan/edit-careplan/edit-careplan.module';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function provideSettings(storage: Storage, user: User) {
    /**
     * The Settings provider takes a set of default settings for your app.
     *
     * You can add new settings options at any time. Once the settings are saved,
     * these values will not overwrite the saved values (this can be done manually if desired).
     */
    return new Settings(storage).withDefaults({
        option1: true,
        option2: 'Ionitron J. Framework',
        option3: '3',
        option4: 'Hello',
    });
}

@NgModule({
    declarations: [AppComponent],
    exports: [],
    schemas: [NO_ERRORS_SCHEMA],
    imports: [
        AdminModule,
        BrowserModule,
        IonicModule.forRoot({mode: 'ios'}),
        AppRoutingModule,
        HttpClientModule,
        PipesModule,
        ResetMetricsPageModule,
        AddDevicePageModule,
        CarePlanPageModule,
        EditCareplanModule,
        ChatPageModule,
        VideoPageModule,
        VoicePageModule,
        PatientStatusChangeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot()
    ],
    providers: [
        AppVersion,
        AndroidPermissions,
        AuthGuard,
        BroadcastService,
        Camera,
        CallNumber,
        CommunicationService,
        CookieService,
        DataExchangeService,
        Deeplinks,
        Device,
        Dialogs,
        Environment,
        FirebaseNotifications,
        GatewayApi,
        GatewayResourceService,
        GatewayService,
        GlobalSettingsService,
        HRSLoggerMonitor,
        InAppBrowser,
        ModalService,
        NativeRingtones,
        ReviewedStatusService,
        ScreenOrientation,
        StatusBar,
        SplashScreen,
        SurveyDetailsService,
        TokenService,
        User,
        Zoom,
        {provide: Settings, useFactory: provideSettings, deps: [Storage]},
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function(broadcastService: BroadcastService, tokenService: TokenService) {
                return new ApiInterceptor(broadcastService, tokenService);
            },
            multi: true,
            deps: [BroadcastService, TokenService]
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: ErrorHandler, useClass: ErrorHandler},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
