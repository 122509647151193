/**
 * @route GET /tokens
 */
export interface TokenRequest {
    type: string,
    username?: string,
    password?: string,
    code?: string,
    key?: string,
    device?: string,
    time?: string
}
