export const environment = {
    production: false,
    app_id: 'app_id_value',
    app_name: 'app_name_value',
    app_version: 'app_version_value',
    app_version_code: 'app_version_code_value',
    current_environment: 'hrsdomain',
    HRS_DOMAIN: 'hrsdomain.com',
    API_GATEWAY_URL: 'https://gateway.hrsdomain.com',
    DEBUG: false,
    HRSTAB: 'HRSTAB_value',
    knox_manage: 'knox_manage_value',
    LEGACYDESIGN: 'LEGACYDESIGN_value',
    UPDATEINTERVAL: 3600000,
    ANALYTICS_INFO: {
        url: 'https://analytics.staging1.hrsqa.com/js/container_pfuOZ993_staging_d73f762586bdfa95efb842ca.js'
    },
};
