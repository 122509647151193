import {Component, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ModalController, NavParams, LoadingController, IonContent, AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService} from '@clinician/providers';
import {timer, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {ConvertString} from '@hrs/utility';
import moment from 'moment';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'page-chat',
    templateUrl: 'chat.page.html',
})
export class ChatPage {
    private readonly logger = getLogger('ChatPage');
    patient: any;
    messages: any[];
    data: UntypedFormGroup;
    textMessagesLoaded;
    messageStatus: string;
    poll: any;
    polling: boolean;
    getMessagesEvent: Subscription;
    openNewChatEvent: Subscription;
    @ViewChild(IonContent, {}) content: IonContent;

    constructor(
        private communication: CommunicationService,
        private loadingCtrl: LoadingController,
        private translateService: TranslateService,
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private modalService: ModalService,
        private alertCtrl: AlertController,
        private ref: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.patient = this.navParams.get('patient');
        this.modalService.setModalStatus('ChatMessagesModalPage', true, this.patient.hrsid);
    }

    ngOnInit() {
        this.getTextMessages(this.patient.hrsid);

        this.getMessagesEvent = this.communication.getChatNewMessage$.subscribe(() => {
            this.getTextMessages(this.patient.hrsid, true);
        });

        this.openNewChatEvent = this.communication.exitChatOpenNew$.subscribe(() => {
            this.dismiss();
        });

        this.data = this.formBuilder.group({
            message: new UntypedFormControl('', null)
        });
    }

    ionViewDidLeave() {
        this.stopPolling();
        this.getMessagesEvent.unsubscribe();
        this.openNewChatEvent.unsubscribe();
    }

    /**
     * Gets all of the messages for a particular patient
     * Incoming represents a new chat received via firebase notification while the chat modal is open
     * we don't want to show a loader if a new message is received while the modal is open
     */
    async getTextMessages(hrsid: string, incoming?: boolean) {
        if (!incoming) {
            this.textMessagesLoaded = await this.loadingCtrl.create({
                message: this.translateService.instant('LOADING'),
            });
            await this.textMessagesLoaded.present();
        }

        this.communication.getTextMessages(hrsid).subscribe(
            {
                next: (res: any) => {
                    this.messages = res;
                    this.ref.detectChanges();
                    if (!incoming) {
                        this.pollGetTexts();
                    }
                },
                error: (err: any) => {
                    this.handleGetTextMessagesError(err, hrsid, incoming);
                },
                complete: () => {
                    if (!incoming) {
                        this.dismissLoadingAlert();
                    }
                }
            }
        );
    }

    dismissLoadingAlert(): void {
        this.loadingCtrl.dismiss();
    }

    async handleGetTextMessagesError(err: any, hrsid: string, incoming?: boolean) {
        this.logger.phic.error('Error: ', err);
        let alert = await this.alertCtrl.create({
            header: this.translateService.instant('ERROR_TITLE'),
            message: this.translateService.instant('GET_MESSAGE_ERROR'),
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    role: 'cancel',
                    handler: () => {}
                },
                {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.getTextMessages(hrsid, incoming);
                    }
                }
            ]
        });
        return await alert.present();
    }

    /**
     * Poll get chat endpoint
     * If multiple clinicians are chatting with the same patient we want to be sure that everyone receives all new messages
     * this is a temporary fix until we can update firebase to notify all clinicians of any new message to a chat thread
     * We will hit the get chat endpoint every thirty seconds anytime the a chat is in view
     */
    pollGetTexts() {
        const poll = timer(30000, 30000).pipe(
            switchMap(() => this.communication.getTextMessages(this.patient.hrsid)));
        this.poll = poll
            .subscribe((res: any) => {
                this.polling = true;
                const messages = res;
                if (this.messages.length < messages.length) {
                    // the new response has more messages than the previous response
                    // add to dom and scroll to bottom
                    this.polling = false;
                    this.messages = messages;
                    this.callScrollFunction();
                }
            });
    }

    stopPolling() {
        if (this.poll) {
            this.poll.unsubscribe();
        }
    }

    /**
     * Convert time and date into a consistent format
     */
    convertTimeAndDate(timestamp: string): string {
        return moment.unix(parseInt(timestamp) / 1000).format('MM/DD/YYYY h:mm:ss A');
    }

    /**
     * Scrolls to bottom of chat message list
     */

    callScrollFunction() {
        this.content.scrollToBottom(0);
    }

    /**
     * Posts the new message written by the clinician to the patient
     */
    sendTextMessage() {
        // curly chars are replaced to prevent erroring out when saving to DB
        let text = ConvertString.replaceCurlyChars(this.data.value.message.trim());
        let message = {text: text, type: 'clinician', name: 'You', messageStatus: this.translateService.instant('SENDING'), id: new Date()};
        this.messages.push(message);
        this.callScrollFunction();
        this.communication.sendTextMessage(text, this.patient.hrsid).subscribe(
            {
                next: (res) => {
                    message.messageStatus = this.translateService.instant('SENT');
                    let updatedMessage = {
                        message: ''
                    };
                    this.data.setValue(updatedMessage, {emitEvent: true});
                },
                error: (err) => {
                    this.logger.phic.error('Error: ', err);
                    message.messageStatus = this.translateService.instant('FAILED_TO_SEND');
                    this.handleSendTextMessageError(message);
                }
            }
        );
    }

    async handleSendTextMessageError(message) {
        let alert = await this.alertCtrl.create({
            header: this.translateService.instant('ERROR_TITLE'),
            message: this.translateService.instant('SEND_MESSAGE_ERROR'),
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_BUTTON'),
                    role: 'cancel'
                },
                {
                    text: this.translateService.instant('RETRY_BUTTON'),
                    handler: () => {
                        this.messages = this.messages.filter((msg) => {
                            return (JSON.stringify(msg) !== JSON.stringify(message));
                        });
                        this.sendTextMessage();
                    },
                },
            ]
        });
        return await alert.present();
    }

    dismiss() {
        this.modalCtrl.dismiss();
        this.modalService.setModalStatus('ChatMessagesModalPage', false, null);
    }
}
