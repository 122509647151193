import {MetricEntry} from '@hrs/models';

export class WeightEntry extends MetricEntry {
    public weight: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.weight = entry.weight;
    }
}
