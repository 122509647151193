import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {PatientStatusChange} from './patient-status-change';
import {PatientDeviceService, PatientProfileService} from '@clinician/providers';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: PatientStatusChange
            }
        ]),
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [PatientStatusChange],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        PatientDeviceService,
        PatientProfileService
    ]
})
export class PatientStatusChangeModule {}
