import {Injectable} from '@angular/core';
import {GatewayResourceService} from '../../gateway/gateway-resource.service';
import {GatewayApi} from '../api/gateway-api';
import {GatewayService} from '../../gateway/gateway.service';

/**
 * Global settings provider.
 */
@Injectable({
    providedIn: 'root',
})
export class GlobalSettingsService {
    private globalAttributes: {[key: string]: string} = {};
    public globalSettingsLoading: Promise<void>;

    private resolveSettingsLoading: () => void;
    private rejectSettingsLoading: (error: any) => void;

    constructor(
        private gatewayResourceService: GatewayResourceService,
        private gatewayApi: GatewayApi,
        private gatewayService: GatewayService
    ) {
        this.globalSettingsLoading = new Promise((resolve, reject) => {
            this.resolveSettingsLoading = resolve;
            this.rejectSettingsLoading = reject;
        });
    }

    /**
     * Send a GET request to our global settings endpoint
     */
    public loadGlobalSettings(): void {
        let endpoint = 'v1/global-frontend-settings';
        this.gatewayResourceService.get({endpoint: endpoint}).subscribe({
            next: (res: any) => {
                this.extractAttributes(res);
                return this.globalAttributes;
            },
            error: (err: any) => this.rejectSettingsLoading(err),
            complete: () => this.resolveSettingsLoading()
        });
    }

    /**
     * processes the raw data and extracts attributes into an object of key value pairs,
     * filtering by latest updated value for any single attribute
     */
    private extractAttributes(data: any): void {
        let latestAttribute: {[key: string]: Date} = {};
        if (data && data.data) {
            data.data.forEach((dataElem) => {
                if (dataElem.attributes) {
                    let name = dataElem.attributes.name;
                    let value = dataElem.attributes.value;
                    let lastUpdated = new Date(dataElem.attributes.lastUpdated);

                    if (name) {
                        if (!this.globalAttributes[name] || (this.globalAttributes[name] && lastUpdated.valueOf() > latestAttribute[name].valueOf())) {
                            this.globalAttributes[name] = value;
                            latestAttribute[name] = lastUpdated;
                        }

                        if (name === 'INCLUDE_HRS_HEADERS') {
                            value = value === 'true';
                            this.gatewayApi.includeHRSHeaders = value;
                            this.gatewayService.includeHRSHeaders = value;
                        }
                    }
                }
            });
        }
    }

    /**
     * returns all global settings as an object of key value pairs
     *
     * @returns {[key: string]: string}
     */
    public getGlobalAttributes(): {[key: string]: string} {
        return this.globalAttributes;
    }
}
