<ion-header>
    <ion-toolbar class="c_app_header is-modal">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ 'RESET_METRICS' | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="resetMetricsModal">
    <ion-card>
        <ion-card-header>
            <b>{{ 'RESET_METRICS_HEADER' | translate }}</b>
        </ion-card-header>
    </ion-card>
    <form [formGroup]="metrics" (ngSubmit)="submitMetricReset()">
        <ion-list>
            <ion-item>
                <ion-label>{{ 'ACTIVITY' | translate }}</ion-label>
                <ion-checkbox formControlName="activity" (ionChange)="toggleResetButton()"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'CONSENT_FORM' | translate }}</ion-label>
                <ion-checkbox formControlName="consent" (ionChange)="toggleResetButton()"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'MEDICATIONS' | translate }}</ion-label>
                <ion-checkbox formControlName="medication" (ionChange)="toggleResetButton()"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'SURVEY' | translate }}</ion-label>
                <ion-checkbox formControlName="survey" (ionChange)="toggleResetButton()"></ion-checkbox>
            </ion-item>
            <ion-item>
                <ion-label>{{ 'WEIGHT' | translate }}</ion-label>
                <ion-checkbox formControlName="weight" (ionChange)="toggleResetButton()"></ion-checkbox>
            </ion-item>
        </ion-list>
        <ion-buttons class="c_buttons c_footer">
            <ion-button class="c_button-left c_footer--button-left" (click)="dismiss()">
                <ion-text class="c_footer--text">{{ 'CLOSE_BUTTON' | translate }}</ion-text>
            </ion-button>
            <ion-button class="c_button c_footer--button" type="submit" [disabled]="!metricSelected">
                <ion-text class="c_footer--text">{{ 'RESET_BUTTON' | translate }}</ion-text>
            </ion-button>
        </ion-buttons>
    </form>
</ion-content>
