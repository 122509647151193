<ion-header class="callHeader">
    <ion-toolbar class="c_app_header is-modal">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ "VOICE_PANEL" | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="callDetails c_call c_voice_call--details">
    <div class="calleeName c_call--name-callee">{{ callData.patientName }}</div>
    <div class="callStatus c_call--status">
        <div>{{ callStatus | translate }}</div>
    </div>
</ion-content>
<ion-footer class="c_footer">
    <ion-button class="c_footer--button-back"
                (click)="dismiss()">
        <ion-icon class="c_footer--icon-back"></ion-icon>
    </ion-button>
    <ion-button class="c_footer--button"
                [ngClass]="{
                    'c_call--button-call': true,
                    'c_call--button-end': calling
                }"
                (click)="toggleCall()"
                [disabled]="modalClosing">
        <ion-icon class="c_call--icon-dial"
                  name="call"></ion-icon>
    </ion-button>
</ion-footer>
