import {Component} from '@angular/core';
import {NavParams, ToastController, ModalController} from '@ionic/angular';
import {Validators, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {PatientProfileService} from '../../services/providers';

@Component({
    selector: 'page-reset',
    templateUrl: 'reset-metrics.page.html',
    styleUrls: ['reset-metrics.page.scss'],
})
export class ResetMetricsPage {
    patientHRSId: string;
    metricSelected: boolean;
    metrics: UntypedFormGroup;

    constructor(
        private toastCtrl: ToastController,
        private modalCtrl: ModalController,
        private patientProfileService: PatientProfileService,
        private translateService: TranslateService,
        private navParams: NavParams,
        private formBuilder: UntypedFormBuilder
    ) {
        this.metrics = this.formBuilder.group({
            activity: new UntypedFormControl(false),
            medication: new UntypedFormControl(false),
            survey: new UntypedFormControl(false),
            weight: new UntypedFormControl(false),
            consent: new UntypedFormControl(false),
        });
    }

    ionViewWillEnter() {
        this.patientHRSId = this.navParams.get('patientHRSId');
    }

    toggleResetButton() {
        if (
            this.metrics.value.activity === true ||
            this.metrics.value.medication === true ||
            this.metrics.value.survey === true ||
            this.metrics.value.weight === true ||
            this.metrics.value.consent === true
        ) {
            this.metricSelected = true;
        } else {
            this.metricSelected = false;
        }
    }

    submitMetricReset() {
        this.patientProfileService.resetPatientMetrics(this.patientHRSId, this.metrics.value).subscribe(
            {
                next: (res: any) => {
                    this.throwToastMessage('RESET_METRICS_SUCCESS');
                },
                error: (err) => {
                    this.throwToastMessage('RESET_METRICS_FAIL');
                }
            }
        );
        this.dismiss();
    }

    async throwToastMessage(message: string) {
        let toast = await this.toastCtrl.create({
            message: this.translateService.instant(message),
            duration: 3000,
            position: 'top'
        });
        return await toast.present();
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
