import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth.guard';

const routes: Routes = [
    {path: '', redirectTo: 'patient-list', pathMatch: 'full'},
    {path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomePageModule)},
    {path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule)},
    {path: 'patient-list', canActivate: [AuthGuard], loadChildren: () => import('./patient-list/patient-list.module').then((m) => m.PatientListPageModule)},
    {path: 'patient-profile', loadChildren: () => import('./patient/patient-profile.module').then((m) => m.PatientProfilePageModule)},
    {path: 'add-patient', loadChildren: () => import('./add-patient/add-patient.module').then((m) => m.AddPatientPageModule)},
    {path: 'organizational-login', loadChildren: () => import('./organizational-login/organization-login.module').then((m) => m.OrganizationalLoginPageModule)},
    {path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
