export class ConvertTemperature {
    /**
     * @param number celsius
     * @return number
     *
     * Formula: F = C * (9.0/5.0) + 32
     */
    static celsiusToFarenheit(celsius: number): number {
        return celsius * (9.0 / 5.0) + 32;
    }

    /**
     * @param number fahrenheit
     * @return number
     *
     * Formula: K = (F + 459.67) x 5/9
     */
    static fahrenheitToKelvin(fahrenheit: number): number {
        return (fahrenheit + 459.67) * (5 / 9);
    }

    /**
     * @param number kelvin
     * @return number
     *
     * Formula: C = K - 273.15
     */
    static kelvinToCelsius(kelvin: number): number {
        return kelvin - 273.15;
    }

    /**
     * @param number kelvin
     * @return number
     *
     * Formula: F = K * 9/5 - 459.67
     */
    static kelvinToFahrenheit(kelvin: number): number {
        return (kelvin * (9 / 5)) - 459.67;
    }

    /**
     * @param number celsius
     * @return number
     *
     * Formula: K = C + 273.15
     */
    static celsiusToKelvin(celsius: number): number {
        return celsius + 273.15;
    }
}
