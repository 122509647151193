import {MetricEntry} from '@hrs/models';

export class GlucoseEntry extends MetricEntry {
    public glucose: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.glucose = entry.glucose;
    }
}
