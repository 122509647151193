import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {AddDevicePage} from './add-device.page';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: 'add-device',
                component: AddDevicePage
            }
        ]),
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        AddDevicePage
    ],
    schemas: [NO_ERRORS_SCHEMA],
})
export class AddDevicePageModule {}
