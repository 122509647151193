export const environment = {
    production: false,
    app_id: 'ionic.hrsmobile.byod.clinician',
    app_name: 'DEV ClinicianConnect Mobile',
    app_version: '9.9.9',
    app_version_code: 90909,
    current_environment: 'hrsdomain',
    HRS_DOMAIN: 'jerry.hrsqa.com',
    API_GATEWAY_URL: 'https://gateway.jerry.hrsqa.com',
    DEBUG: false,
    HRSTAB: 'HRSTAB_value',
    knox_manage: 'knox_manage_value',
    LEGACYDESIGN: 'LEGACYDESIGN_value',
    UPDATEINTERVAL: 3600000,
    ANALYTICS_INFO: {
        url: 'https://analytics.staging1.hrsqa.com/js/container_pfuOZ993_staging_d73f762586bdfa95efb842ca.js'
    },
};
