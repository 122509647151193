import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@app/env';
import {GatewayHttpOptions} from './gateway-http-options';
import {Observable, throwError, timeout} from 'rxjs';
import {TokenService} from '../providers/token/token.service';

@Injectable({
    providedIn: 'root',
})
export class GatewayService {
    public language: string = 'en';

    private _hrsUserAgentHeader: string;
    private _imei: string;
    private _hrsid: string;
    private static timeout: number = 120000; // 2 minutes
    private _includeHRSHeaders: boolean = false;

    constructor(
        private http: HttpClient,
        private tokenService: TokenService
    ) {}

    set hrsUserAgentHeader(hrsUserAgentHeader: string) {
        this._hrsUserAgentHeader = hrsUserAgentHeader;
    }

    get hrsUserAgentHeader(): string {
        return this._hrsUserAgentHeader;
    }

    set imei(imei: string) {
        this._imei = imei;
    }

    set hrsid(hrsid: string) {
        this._hrsid = hrsid;
    }

    set includeHRSHeaders(value: boolean) {
        this._includeHRSHeaders = value;
    }

    public isLoggedIn(): boolean {
        return this.tokenService.token !== null;
    }

    public logout(): void {
        this.tokenService.token = null;
        this.hrsid = null;
    }

    public get<T>(options: GatewayHttpOptions): Observable<T> {
        return this.sendRequest<T>('GET', options).pipe(
            timeout({
                each: GatewayService.timeout,
                with: () => throwError(() => new Error(`GET ${options.path} request timed out`))
            })
        );
    }

    public post<T>(options: GatewayHttpOptions): Observable<T> {
        return this.sendRequest<T>('POST', options).pipe(
            timeout({
                each: GatewayService.timeout,
                with: () => throwError(() => new Error(`POST ${options.path} request timed out`))
            })
        );
    }

    public put<T>(options: GatewayHttpOptions): Observable<T> {
        return this.sendRequest<T>('PUT', options).pipe(
            timeout({
                each: GatewayService.timeout,
                with: () => throwError(() => new Error(`PUT ${options.path} request timed out`))
            })
        );
    }

    public patch<T>(options: GatewayHttpOptions): Observable<T> {
        return this.sendRequest<T>('PATCH', options).pipe(
            timeout({
                each: GatewayService.timeout,
                with: () => throwError(() => new Error(`PATCH ${options.path} request timed out`))
            })
        );
    }

    public delete<T>(options: GatewayHttpOptions): Observable<T> {
        return this.sendRequest<T>('DELETE', options).pipe(
            timeout({
                each: GatewayService.timeout,
                with: () => throwError(() => new Error(`DELETE ${options.path} request timed out`))
            })
        );
    }

    private sendRequest<T>(method: string, options: GatewayHttpOptions): Observable<T> {
        let reqOptions = {
            headers: options.headers || {}
        };

        reqOptions.headers['Content-Type'] = options.contentType ? options.contentType : 'application/json';

        reqOptions.headers['Accept-Language'] = this.language;

        if (this._hrsUserAgentHeader) {
            reqOptions.headers['hrs-user-agent'] = this._hrsUserAgentHeader;
        }

        if (this._includeHRSHeaders) {
            if (this._imei) reqOptions.headers['x-imei'] = this._imei;
            if (this._hrsid) reqOptions.headers['x-hrsid'] = this._hrsid;
        }

        if (options.responseType) {
            reqOptions['responseType'] = options.responseType;
        }
        if (this.tokenService.token) {
            reqOptions.headers['Authorization'] = 'Bearer ' + this.tokenService.token;
        }

        let queryString = '';
        if (options.query) {
            queryString = Object.keys(options.query)
                .map((key) => {
                    let value = options.query[key];

                    if (value && typeof value === 'object') {
                        return Object.keys(value)
                            .map((nestedKey) => `${key}[${nestedKey}]=${value[nestedKey]}`)
                            .join('&');
                    } else {
                        return `${key}=${value}`;
                    }
                })
                .join('&');
        }

        const url = environment.API_GATEWAY_URL + '/' + options.path + (queryString ? '?' + queryString : '');
        method = method.toLowerCase();
        if (method === 'get' || method === 'delete') {
            return (this.http[method] as any)(
                url,
                reqOptions
            ) as Observable<T>;
        } else {
            return (this.http[method] as any)(
                url,
                options.body,
                reqOptions
            ) as Observable<T>;
        }
    }
}
