import {Logger, LoggerTransport, getPrimaryLoggerTransport, Config} from '@obsidize/rx-console';

export const globalLogConfig: Config = Config.sharedInstance;
export const primaryTransport: LoggerTransport = getPrimaryLoggerTransport();

/**
 * Special transport for logs that are PHI (protected health information)
 * compliant, and can be sent to ELK or other server-based logging services.
 * 
 * https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html
 */
export const phiCompliantTransport: LoggerTransport = new LoggerTransport().pipeTo(primaryTransport);

export class HRSLogger extends Logger {

    /**
     * Special logger that will produce logs on the PHI-Compliant transport.
     * Logs produced from here will go to both ELK and local log file,
     * whereas logs produced from the top-level logger (instance that owns this property)
     * will only go to the local log file.
     */
    public readonly phic: Logger;

    constructor(tag: string) {
        super(tag, primaryTransport);
        this.phic = new Logger(tag + `-PHIC`, phiCompliantTransport);
    }
}

export function getLogger(tag: string): HRSLogger {
    return new HRSLogger(tag);
}
