import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {BuildUtility} from '@hrs/utility';

/**
 * A simple settings/config class for storing key/value pairs
 * Persistence requires Env to have flag "SYSTEM_MOBILE_SECURITY_OPTIONAL" enabled
 */
@Injectable({
    providedIn: 'root',
})
export class Settings {
    private SETTINGS_KEY: string = 'settings';
    private hasStorage: boolean = false;
    settings: any = {};
    _defaults: any;
    _readyPromise: Promise<any>;
    private mStorage: Storage;
    private mStorageReady: Promise<any>;

    constructor(
        private storageCreator: Storage
    ) {
        this.mStorageReady = this.init();
    }

    get allSettings(): any {
        return this.settings;
    }

    private async init(): Promise<void> {
        this.mStorage = await this.storageCreator.create();
    }

    public ready(): Promise<any> {
        return this.mStorage ? Promise.resolve() : this.mStorageReady;
    }

    withDefaults(defaults: any): this {
        this._defaults = defaults;
        return this;
    }

    /**
     * For security purposes the default behavior is to not use Storage unless Env opts out of advanced security
     * This method gets triggered by User.loginWithData once the environment data has loaded and flag
     * "SYSTEM_MOBILE_SECURITY_OPTIONAL" (locally known as: Environment.SETTING_OPT_OUT_ADVANCED_SECURITY) is present
     */
    public async configureStorage(hasStorage: boolean): Promise<void> {
        return hasStorage ? this.enableDataStorage() : this.disableDataStorage();
    }

    enableDataStorage(): void {
        this.hasStorage = true;
    }

    public async disableDataStorage(): Promise<void> {
        this.hasStorage = false;
        await this.ready();
        await this.mStorage.clear();
        // Store Domain so that the changes are not lost on force-kill.
        const domainName = BuildUtility.getDomainName();
        const updatedDomain = domainName === 'hrsdomain' ? BuildUtility.getHRSDomain() : domainName;
        await this.mStorage.set('updatedDomain', updatedDomain);
    }

    public async load(): Promise<void> {
        await this.ready();
        const value = await this.mStorage.get(this.SETTINGS_KEY);

        if (value) {
            this.settings = value;
            return this._mergeDefaults(this._defaults);
        }

        return this.setAll(this._defaults).then((val) => {
            this.settings = val;
        });
    }

    _mergeDefaults(defaults: any): Promise<any> {
        // eslint-disable-next-line guard-for-in
        for (let k in defaults) {
            if (!(k in this.settings)) {
                this.settings[k] = defaults[k];
            }
        }

        return this.hasStorage ? this.setAll(this.settings) : Promise.resolve();
    }

    public async merge(settings: any): Promise<void> {
        for (let k in settings) {
            if (k) {
                this.settings[k] = settings[k];
            }
        }
        if (this.hasStorage) {
            return this.save();
        }
    }

    public async setValue(key: string, value: any): Promise<void> {
        await this.ready();
        this.settings[key] = value;

        if (this.hasStorage) {
            await this.mStorage.set(this.SETTINGS_KEY, this.settings);
        }
    }

    public async setAll(value: any): Promise<any> {
        await this.ready();
        return this.mStorage.set(this.SETTINGS_KEY, value);
    }

    public getValue(key: string): any {
        return this.settings[key];
    }

    public save(): Promise<void> {
        return this.setAll(this.settings);
    }
}
