<div class="c_admin_kiosk">
    <ion-list>
        <ion-radio-group [(ngModel)]="selected" [value]="selected">

            <ion-list-header>
                <ion-label>{{'ADMIN_DOMAIN_SETTINGS.HEADER' | translate}}</ion-label>
            </ion-list-header>
            <p>{{currentDomain}}</p>

            <ion-item *ngFor="let domain of domains">
                <ion-label>{{domain}}</ion-label>
                <ion-radio color="success" slot="start" value="{{domain}}"></ion-radio>
            </ion-item>
            <ion-item>
                <ion-label class="hrsdomain--label" (click)="handleLabelClick('hrsdomain')">
                    {{'hrsdomain:'}}
                    <ion-input class="c_input"
                               type="text"
                               placeholder="type domain here..."
                               [disabled]="selected !== 'hrsdomain'"
                               [(ngModel)]="hrsdomainText"></ion-input>
                </ion-label>
                <ion-radio color="success" slot="start" value="hrsdomain"></ion-radio>
            </ion-item>
        </ion-radio-group>

        <ion-buttons class="c_app_header--buttons">
            <ion-button class="c_button" (click)="setDomain()">{{'SAVE_BUTTON' | translate}}</ion-button>
        </ion-buttons>
    </ion-list>
</div>
