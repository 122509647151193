<ion-app class="ios">
    <ion-menu class="c_app_menu"
              contentId="content"
              type="overlay"
              side="start"
              persistant="true"
              enable-menu-with-back-views="true"
              [swipeGesture]="false">
        <ion-header class="c_app_menu--header">
            <h5 class="app-menu-title c_app_menu--title">
                {{ 'MENU' | translate }}
            </h5>
            <ion-menu-toggle class="c_app_menu--btn-close">
                <ion-icon class="c_app_menu--btn-close-icon"></ion-icon>
            </ion-menu-toggle>
        </ion-header>
        <ion-content>
            <ion-list class="c_app_menu--list">
                <ion-item class="c_app_menu--item"
                          button
                          slot="start"
                          (click)="openAddPatientPage()"
                          *ngIf="!user.isReadOnlyClinician()">
                    <ion-icon class="c_app_menu--icon c_app_menu--icon-add_patient"></ion-icon>
                    <ion-label>{{ 'ADD_PATIENT' | translate }}</ion-label>
                </ion-item>
                <ion-item class="c_app_menu--item"
                          button
                          slot="start"
                          (click)="initSupportCall()"
                          detail-none>
                    <ion-icon class="c_app_menu--icon c_app_menu--icon-support"></ion-icon>
                    <ion-label>
                        {{ 'CALL_SUPPORT' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item class="c_app_menu--item"
                          button
                          slot="start"
                          (click)="logoutClicked()">
                    <ion-icon class="c_app_menu--icon c_app_menu--icon-logout"></ion-icon>
                    <ion-label>
                        {{ 'LOGOUT' | translate }}
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
        <div class="app-version" (click)="openAdmin()">
            {{ versionNumber }}
        </div>
    </ion-menu>
    <ion-router-outlet id="content"></ion-router-outlet>
</ion-app>
