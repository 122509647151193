/* eslint camelcase: 0*/

export interface ClinicianSessionResponse {
    env: string;
    exp: number;
    firebase: {
        authentication: string;
        application: {[key: string]: string};
    };
    firstname: string;
    lastname: string;
    roles: string[]
    time_zone: string;
    token: string;
    user: string;
    username: string;
    usertype: string;
}
