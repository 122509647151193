import {MetricEntry} from '@hrs/models';

export class ActivityEntry extends MetricEntry {
    public duration: number;
    public unit: string;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.duration = entry.duration;
    }
}
