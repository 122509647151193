import {NgModule} from '@angular/core';

import {DisplayGenderPipe} from './display-gender.pipe';

@NgModule({
    declarations: [
        DisplayGenderPipe
    ],
    exports: [
        DisplayGenderPipe
    ]

})
export class PipesModule { }
