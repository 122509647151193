<ion-content class="c_splash--page"
             scroll="false">
    <div class="c_splash--wrapper">
        <div class="c_splash--logo_container">
            <div class="c_splash--logo_bg"></div>
            <div class="c_splash--logo"></div>
        </div>
        <div class="c_splash--card_container is-tall_card is-login">
            <div class="c_splash--card_bg"></div>
            <main class="c_splash--card">
                <header class="c_splash--card_header c_card--header">
                    <div class="c_splash--header_bg"></div>
                    <ion-button class="c_splash--back_btn"
                                [routerLink]="['/welcome']"
                                routerDirection="back"></ion-button>
                    <span class="c_splash--title_text">
                        {{ 'ORGANIZATIONAL_LOGIN.TITLE' | translate }}
                    </span>
                </header>
                <div class="c_splash--card_content c_card--content">
                    <form class="c_splash--form">
                        <ion-list class="c_splash--input_wrapper">
                            <ion-item class="c_splash--input">
                                <ion-label class="c_splash--input_label"
                                           position="floating">
                                    {{ 'ORGANIZATIONAL_LOGIN.EMAIL_LABEL' | translate }}
                                </ion-label>
                                <ion-input class="c_splash--input_field"
                                           type="email"
                                           autocapitalize="none"
                                           [(ngModel)]="email"
                                           name="email"></ion-input>
                            </ion-item>
                        </ion-list>
                    </form>
                    <div class="c_splash--text">
                        {{ 'ORGANIZATIONAL_LOGIN.HELP_TEXT' | translate }}
                    </div>
                </div>
                <footer class="c_splash--card_footer c_card--buttons">
                    <div class="c_splash--footer_bg"></div>
                    <ion-button class="c_button login"
                                (click)="email && doLogin()"
                                type="submit"
                                [disabled]="!email || loggingIn">
                        {{ 'ORGANIZATIONAL_LOGIN.SUBMIT_BUTTON' | translate }}
                    </ion-button>
                </footer>
            </main>
        </div>
    </div>
</ion-content>
