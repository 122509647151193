import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {AdminService} from '@clinician/providers';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'hrs-admin-access',
    templateUrl: 'admin-access.component.html',
    styleUrls: ['admin-access.component.scss']
})

export class AdminAccessComponent {
    @Output() hasCorrectPassword = new EventEmitter<boolean>();

    hasError: boolean = false;
    enteredPasscode: string;
    errorMessage: string;

    private reroute: ReturnType<typeof setTimeout>;
    private readonly timeout: () => ReturnType<typeof setTimeout>;

    constructor(
        private adminService: AdminService,
        private translate: TranslateService
    ) {
        this.timeout = () => setTimeout(() => {
            this.adminService.rerouteHome();
        }, 45000);
        this.reroute = this.timeout();
        this.errorMessage = this.translate.instant('ADMIN_PASSCODE_ERROR');
    }

    ngOnDestroy() {
        clearTimeout(this.reroute);
    }

    /**
     * On changes to the input, the reroute gets reset and isCorrect gets emitted to parent.
     */
    passcodeChangeHandler(): void {
        const isCorrect = AdminService.PASSCODE === this.enteredPasscode;
        clearTimeout(this.reroute);
        if (isCorrect) {
            this.hasCorrectPassword.emit(isCorrect);
        } else {
            this.checkErrorState(isCorrect);
            this.reroute = this.timeout();
        }
    }

    checkErrorState(isCorrect): void {
        const haveSameLength = AdminService.PASSCODE.length === this.enteredPasscode.length;
        this.hasError = !isCorrect && haveSameLength;
    }
}
