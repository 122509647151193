import {MetricEntry} from '@hrs/models';

export class TemperatureEntry extends MetricEntry {
    public temperature: number;
    public unit: string;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.temperature = entry.temperature;
        this.unit = entry.unit;
    }
}
