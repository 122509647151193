import {ObjectUtility} from './ObjectUtility';

export class CollectionUtility {
    /**
     * For converting values to arrays.
     * Can handle JSON, Sets, Objects, Arrays, strings, integers, undefined, null
     * @param collection
     * @private
     */
    static convertToArray(collection: any): any[] {
        try {
            collection = JSON.parse(collection);
        } catch (e) {}

        if (!collection) collection = [];

        if (collection instanceof Set) collection = Array.from(collection);

        if (typeof collection === 'string') collection = collection.split(', ');

        if (Number.isInteger(collection) || ObjectUtility.isObject(collection)) collection = [collection];

        return collection;
    }
}
