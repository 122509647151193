import {Injectable} from '@angular/core';
import {GatewayApi} from '@hrs/providers';
import {CustomPatientAttribute, EnvironmentData, EnvironmentSettings} from './interfaces/index';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Settings} from '../settings/settings';

/**
 *Environment provider.
 */
@Injectable({
    providedIn: 'root',
})
export class Environment {
    static supportNumber: string;
    static environmentSettings: EnvironmentSettings;
    static SETTING_PATIENT_CUSTOMATTRIBUTES = 'PATIENTINFO_CUSTOMATTRIBUTES';
    static SETTING_ENV_SUBGROUPS = 'SYSTEM_SUBGROUPS';
    static SETTING_OPT_OUT_ADVANCED_SECURITY = 'SYSTEM_MOBILE_SECURITY_OPTIONAL';
    public data: EnvironmentData;

    constructor(
        private gatewayApi: GatewayApi,
        private settings: Settings
    ) {}

    /**
     * Send a GET request to our environment endpoint on login.
     * This will be called once.
     */
    public getEnvironment(): Observable<any> {
        let getEnv = this.gatewayApi.get('apiv2/environment', {});

        return getEnv.pipe(
            tap((res: any) => {
                this.data = res;
                if (res.settings.phone) {
                    Environment.supportNumber = res.settings.phone;
                }
                if (res.environmentSettings) {
                    Environment.environmentSettings = res.environmentSettings;
                }
            }),
            tap(() => {
                this.settings.configureStorage(this.hasSetting(Environment.SETTING_OPT_OUT_ADVANCED_SECURITY));
            })
        );
    }

    public hasSetting(flag: string): boolean {
        if (this.data && this.data.environmentSettings) {
            for (let i = 0; i < this.data.environmentSettings.length; i++) {
                if (this.data.environmentSettings[i].flag == flag) {
                    return true;
                }
            }
        }
        return false;
    }

    public getSubgroups(): string[] {
        if (this.data && this.data.settings) {
            return this.data.settings.subgroups;
        }
    }

    public getCustomFields(): CustomPatientAttribute[] {
        if (this.data) {
            return this.data.PATIENTINFO_CUSTOMATTRIBUTES;
        }
    }
}
