import {MetricEntry} from '@hrs/models';

export class PulseOxEntry extends MetricEntry {
    public spo2: number;
    public heartrate: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.spo2 = entry.spo2;
        this.heartrate = entry.heartrate;
    }
}
