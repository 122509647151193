/**
 * mgdl: milligrams per deciliter
 * gl: grams per liter
 */

export class ConvertGlucose {
    /**
     * @param number mgdl
     * @return number
     *
     * Formula:  1 g/L = 100 mg/dL
     *
     */
    static mgdlToGl(mgdl: number): number {
        return mgdl / 100;
    }

    /**
     * @param number gl
     * @return number
     *
     * Formula:  1 mg/dL = 0.01 g/L
     */
    static glToMgdl(gl: number): number {
        return gl * 100;
    }
}
