import {Injectable} from '@angular/core';
import {GatewayResourceService, GatewayResponse, VideoSecretsResponse} from '@hrs/gateway';
import {Zoom} from '@ionic-native/zoom/ngx';
import {catchError, map, Subscription, of} from 'rxjs';
import {Platform} from '@ionic/angular';
import {Settings} from '../settings/settings';
import {getLogger} from '@hrs/logging';

declare var cordova: any;

@Injectable({
    providedIn: 'root',
})
export class ZoomService {
    private readonly logger = getLogger('ZoomService');
    public initialized: boolean;
    private initializing: boolean;
    private subscription: Subscription;

    constructor(
        private gateway: GatewayResourceService,
        private zoom: Zoom,
        private platform: Platform,
        private settings: Settings
    ) {}

    initialize(callback?: () => void): void {
        if (this.initialized || this.initializing) {
            return;
        }
        this.initializing = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.gateway.get({endpoint: 'v1/video-secrets'}).pipe(
            map((res: GatewayResponse<VideoSecretsResponse>) => {
                let key = res.data.attributes.key;
                let secret = res.data.attributes.secret;

                if (this.platform.is('cordova') && key && secret) {
                    this.zoom.initialize(key, secret).then(() => {
                        this.logger.phic.info('Zoom SDK initialized');
                        this.initialized = true;
                        this.initializing = false;
                        this.zoom.setLocale(this.settings.getValue('language') || 'en');
                        if (callback) {
                            callback();
                        }
                    }).catch((err) => {
                        this.logger.phic.error('Error initializing Zoom SDK', err);
                        this.initializing = false;
                    });
                } else {
                    this.initializing = false;
                }
            }),
            catchError((err) => {
                this.logger.phic.error(err);
                this.initializing = false;
                return of({});
            })
        ).subscribe();
    }

    /**
     * Method to initialize Zoom SDK. This needs a JWT token which is associated with a meeting number.
     * Thus before joining a call, we init zoom with this token.
     * @param jwtToken
     */
    public async initWithJWTToken(jwtToken: string): Promise<void> {
        if (this.platform.is('cordova') && jwtToken ) {
            await cordova.plugins.Zoom.initializeWithJWT(jwtToken).then((message) => {
                this.zoom.setLocale(this.settings.getValue('language') || 'en');
                this.logger.phic.log('Initialized zoom sdk with jwt token');
            }).catch((err) => {
                this.logger.phic.error('Error initializing Zoom SDK', err);
            });
        } else {
            this.logger.phic.log('JWT token not present, unable to init zoom');
        }
    }

    public joinMeeting(meetingNumber: string, meetingPassword: string, displayName: string) {
        return this.zoom.joinMeeting(meetingNumber, meetingPassword, displayName, {
            'no_dial_in_via_phone': true,
            'no_dial_out_to_phone': true,
            'participant_id': ''
        });
    }
}
