import {PatientResponseDataInterfaces} from '../interfaces/patient-response-data.interface';
import {PatientProfile} from '../interfaces/patient-profile.interface';
import * as moment from 'moment';
import {RecentMetricEntries} from '../interfaces/metrics-entries/recent-metrics.interface';
import {StartingWeight} from '../interfaces/metrics-entries/starting-weight.interface';
import {AllMetrics} from '../classes/all-metrics';
import {ModelWithEvents} from './model-with-events';

export class PatientModel extends ModelWithEvents {
    public events: {[name: string]: string} = {
        patientLoaded: 'patientLoaded',
        overviewDataLoaded: 'overviewDataLoaded',
        startingWeightLoaded: 'startingWeightLoaded',
        trendsDataLoaded: 'trendsDataLoaded',
        historicalDataLoaded: 'historicalDataLoaded',
        updatedRecentMetrics: 'updatedRecentMetrics',
    };

    public hrsid: string;
    public profile: PatientProfile;
    public data: PatientResponseDataInterfaces;
    public historical: PatientResponseDataInterfaces[] = [];
    public overview: PatientResponseDataInterfaces[] = [];
    public recentMetrics: RecentMetricEntries = {};
    public lastDateRequested: moment.Moment;
    public stillLoading = true;
    public startingWeight: StartingWeight = {weightFirst: '', weightFirstTime: ''};
    public modules: string[];

    get patientLoaded() {
        return this.getFlag(this.events.patientLoaded);
    }

    set patientLoaded(value: boolean) {
        this.setFlag(this.events.patientLoaded, value);
    }

    get overviewDataLoaded() {
        return this.getFlag(this.events.overviewDataLoaded);
    }

    set overviewDataLoaded(value: boolean) {
        this.setFlag(this.events.overviewDataLoaded, value);
    }

    get startingWeightLoaded() {
        return this.getFlag(this.events.startingWeightLoaded);
    }

    set startingWeightLoaded(value: boolean) {
        this.setFlag(this.events.startingWeightLoaded, value);
    }

    get trendsDataLoaded() {
        return this.getFlag(this.events.trendsDataLoaded);
    }

    set trendsDataLoaded(value: boolean) {
        this.setFlag(this.events.trendsDataLoaded, value);
    }

    get historicalDataLoaded() {
        return this.getFlag(this.events.historicalDataLoaded);
    }

    set historicalDataLoaded(value: boolean) {
        this.setFlag(this.events.historicalDataLoaded, value);
    }

    get updatedRecentMetrics() {
        return this.getFlag(this.events.updatedRecentMetrics);
    }

    set updatedRecentMetrics(value: boolean) {
        this.setFlag(this.events.updatedRecentMetrics, value, false);
    }

    get all() {
        return this.overview.concat(this.historical);
    }

    initializeWithData(data: PatientResponseDataInterfaces): void {
        if (!this.patientLoaded) {
            this.data = data;
            this.overview[0] = data;
            this.profile = data.profile;
            this.setRecentMetrics();
            this.getLatestRiskLevels();
            this.patientLoaded = true;

            // account for difference in unreviewed patients' responses between patient list (returns []) and
            // patient profile data (omits the 'reviewed' property) that causes a flicker between the 'Reviewed' and 'Non-reviewed' buttons
            if (this.profile.reviewed && (typeof this.profile.reviewed !== 'boolean') && (this.profile.reviewed.length == 0)) {
                this.profile.reviewed = undefined;
            }
        }
    }

    public setRecentMetrics(data?: PatientResponseDataInterfaces[]): void {
        if (!!data) this.updatedRecentMetrics = false;
        if (!data) this.recentMetrics = {};

        const metrics = Object.keys(this.data.metrics);
        let entries = !data ? this.all : data;
        for (let metric of metrics) {
            if (RegExp(metric).test('survey woundimaging medication')) continue;
            Object.assign(this.recentMetrics, this.getLatestReadingsForMetric(entries, metric));
        }

        // trigger trends charts if patient is newer than 15 days
        if (this.data.day <= 15) {
            let recordedDays = this.all.filter((day) => day['day'] > 0);
            // because the data will loaded in batches even for a small range patient - be sure that all the data days are loaded before flagging
            if (recordedDays.length === this.data.day) {
                this.updatedRecentMetrics = true;
                this.trendsDataLoaded = true;
            }
        }

        if (!!data) {
            this.updatedRecentMetrics = true;
        } else {
            this.getLatestRiskLevels();
        }
    }

    private getLatestReadingsForMetric(data: PatientResponseDataInterfaces[], metric: string): RecentMetricEntries {
        let readings = {};
        for (let day of data) {
            let entries = day.metrics[metric];

            if ((this.recentMetrics[metric + 'Today'] && this.recentMetrics[metric + 'Last']) ||
                (readings[metric + 'Today'] && readings[metric + 'Last'])) {
                return readings;
            }
            if (entries && entries.data) {
                entries = entries.data.filter((entry) => !entry.deleted && entry.status !== 'removed');
                entries = entries.sort((entry1, entry2) => entry2.ts - entry1.ts);
                if (!entries.length) continue;
                if (!this.recentMetrics[metric + 'Today'] && !readings[metric + 'Today'] && moment().startOf('day') <= entries[0].ts) {
                    readings[metric + 'Today'] = {};
                    Object.assign(readings[metric + 'Today'], entries[0]);
                    if (entries.length > 1) {
                        readings[metric + 'Last'] = {};
                        Object.assign(readings[metric + 'Last'], entries[1]);
                    }
                } else if (!this.recentMetrics[metric + 'Last'] && !readings[metric + 'Last']) {
                    readings[metric + 'Last'] = {};
                    Object.assign(readings[metric + 'Last'], entries[0]);
                    if (!this.recentMetrics[metric + 'Today']) {
                        readings[metric + 'Today'] = readings[metric + 'Today'] || {};
                    }
                }
            }
        }
        return readings;
    }

    private getLatestRiskLevels(): void {
        if (this.data.risk.length && this.data.risk[0].details) {
            const riskDetails = this.data.risk[0].details;
            if (riskDetails) {
                for (let riskDetail of riskDetails) {
                    if (!riskDetail.metrics) continue;
                    for (let metric of riskDetail.metrics) {
                        let riskFlag = AllMetrics[metric].risklabels[riskDetail.type];
                        this.recentMetrics[riskFlag] = true;
                    }
                }
            }
        }
    }
}
