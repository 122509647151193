import {MetricEntry} from './metric-entry.model';

export class SurveyEntry extends MetricEntry {
    public question: string;
    public answer: string[];
    public schedule: {type: string, schedule?: string};
    public points: number;
    public isGrouped: boolean = false;
    constructor(entry) {
        super(parseInt(entry.answered), entry.id);
        this.question = entry.question;
        this.schedule = entry.schedule;
        this.answer = entry.answer;
        this.points = parseInt(entry.points);
    }
}
