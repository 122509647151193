import {
    ActivityEntry,
    BloodPressureEntry,
    GlucoseEntry,
    MedicationEntry,
    PulseOxEntry,
    StethoscopeEntry,
    SurveyEntry,
    TemperatureEntry,
    WeightEntry,
    WoundImageEntry,
    StepsEntry
} from './models';
import * as moment from 'moment';
import {Moment} from 'moment';
import {PatientRiskResponse} from '../interfaces/interfaces';

export const MetricType = {
    ACTIVITY: 'activity',
    BLOOD_PRESSURE: 'bloodpressure',
    GLUCOSE: 'glucose',
    MEDICATION: 'medication',
    PULSE_OX: 'pulseox',
    TEMPERATURE: 'temperature',
    WEIGHT: 'weight',
    WOUND_IMAGING: 'woundimaging',
    STETHOSCOPE: 'stethoscope',
    SURVEY: 'survey',
    STEPS: 'steps'
};

export class DailyMetricsModel {
    static init(data) {
        return new DailyMetricsModel(data);
    }
    public date: Moment;
    public surveyAnswerTotal: number;
    public medsTakenTotal: number;
    public activity: ActivityEntry[];
    public bloodpressure: BloodPressureEntry[];
    public glucose: GlucoseEntry[];
    public pulseox: PulseOxEntry[];
    public temperature: TemperatureEntry[];
    public weight: WeightEntry[];
    public stethoscope: StethoscopeEntry[];
    public survey: SurveyEntry[];
    public medication: MedicationEntry[];
    public woundimaging: WoundImageEntry[];
    public riskClasses: {[key: string]: string};
    public steps: StepsEntry[];

    constructor(
        data
    ) {
        this.parseMetrics(data.metrics);
        this.setDate(data);
        if (data.risk && data.risk.length > 0) {
            this.getMetricRiskClasses(data.risk[0]);
        }
    }

    private setDate(data) {
        this.date = moment.unix(data.ts / 1000);
    }

    private parseMetrics(metrics) {
        if (!metrics) return;

        if (metrics.activity && metrics.activity.data) {
            this.activity = this.parseEntry(MetricType.ACTIVITY, metrics.activity.data);
        }

        if (metrics.bloodpressure && metrics.bloodpressure.data) {
            this.bloodpressure = this.parseEntry(MetricType.BLOOD_PRESSURE, metrics.bloodpressure.data);
        }

        if (metrics.glucose && metrics.glucose.data) {
            this.glucose = this.parseEntry(MetricType.GLUCOSE, metrics.glucose.data);
        }

        if (metrics.medication && metrics.medication.medications) {
            this.medication = this.parseEntry(MetricType.MEDICATION, metrics.medication.medications);
        }

        if (metrics.pulseox && metrics.pulseox.data) {
            this.pulseox = this.parseEntry(MetricType.PULSE_OX, metrics.pulseox.data);
        }
        if (metrics.temperature && metrics.temperature.data) {
            this.temperature = this.parseEntry(MetricType.TEMPERATURE, metrics.temperature.data);
        }

        if (metrics.weight && metrics.weight.data) {
            this.weight = this.parseEntry(MetricType.WEIGHT, metrics.weight.data);
        }

        if (metrics.stethoscope && metrics.stethoscope.data) {
            this.stethoscope = this.parseEntry(MetricType.STETHOSCOPE, metrics.stethoscope.data);
        }

        if (metrics.survey && metrics.survey.questions) {
            this.survey = this.parseEntry(MetricType.SURVEY, metrics.survey.questions);
        }

        if (metrics.woundimaging && metrics.woundimaging.data) {
            this.woundimaging = this.parseEntry(MetricType.WOUND_IMAGING, metrics.woundimaging.data);
        }

        if (metrics.steps && metrics.steps.data) {
            this.steps = this.parseEntry(MetricType.STEPS, metrics.steps.data);
        }
    }

    private parseEntry(type: string, entries) {
        let entryModels = [];
        let entryCount = 0;
        for (let i = 0; i < entries.length; i++) {
            const currentEntry = entries[i];
            const entryModel = this.newEntryModel(type, currentEntry);
            if (currentEntry.deleted) entryModel.deleted = true;
            if (currentEntry.type === 'manual' || currentEntry.type === 'Manual') entryModel.manual = true;
            if (type === MetricType.SURVEY && currentEntry.answer) entryCount++;
            if (type === MetricType.MEDICATION && currentEntry.ts) entryCount++;
            entryModels.unshift(entryModel);
        }
        if (type === MetricType.SURVEY) this.surveyAnswerTotal = entryCount;
        if (type === MetricType.MEDICATION) this.medsTakenTotal = entryCount;
        return entryModels;
    }

    private newEntryModel(type: string, entry) {
        switch (type) {
            case MetricType.ACTIVITY:
                return new ActivityEntry(entry);
            case MetricType.BLOOD_PRESSURE:
                return new BloodPressureEntry(entry);
            case MetricType.GLUCOSE:
                return new GlucoseEntry(entry);
            case MetricType.MEDICATION:
                return new MedicationEntry(entry);
            case MetricType.PULSE_OX:
                return new PulseOxEntry(entry);
            case MetricType.TEMPERATURE:
                return new TemperatureEntry(entry);
            case MetricType.WEIGHT:
                return new WeightEntry(entry);
            case MetricType.WOUND_IMAGING:
                return new WoundImageEntry(entry);
            case MetricType.STETHOSCOPE:
                return new StethoscopeEntry(entry);
            case MetricType.SURVEY:
                return new SurveyEntry(entry);
            case MetricType.STEPS:
                return new StepsEntry(entry);
            default:
                break;
        }
    }

    private getMetricRiskClasses(riskData: PatientRiskResponse): void {
        const metricClasses = {};
        if (riskData.details) {
            let riskDetails = riskData.details;
            for (let i = 0; i < riskDetails.length; i++) {
                let metricType = riskDetails[i].metrics[0];
                metricClasses[metricType + 'Risk'] = riskDetails[i].type;
            }
        }
        this.riskClasses = metricClasses;
    }
}
