/**
 * @route GET /v1/video-secrets
 */
export interface VideoSecretsResponse {
    attributes: {
        key: string,
        secret: string
    },
    resourceType: string
}
