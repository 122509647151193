import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {VoicePage} from './voice.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: VoicePage
            }
        ]),
        TranslateModule.forChild()
    ],
    declarations: [VoicePage]
})
export class VoicePageModule {}
