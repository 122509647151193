import {Injectable} from '@angular/core';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private readonly logger = getLogger('PermissionService');
    hasVideoCallPermissions: boolean = false; // For android we need camera and audio permissions for opentok video calls

    constructor(
        private platform: Platform,
        private androidPermissions: AndroidPermissions,
        private alertCtrl: AlertController,
        private translateService: TranslateService,
    ) {}

    /**
     * Opentok SDK 2.26 requires that before initiating video calls,
     * This method helps to inform user and check for camera and microphone permissions.
     */
    public async checkVideoCallPermissions(deviceVersion: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.platform.is('android') && deviceVersion > 6) {
                let requiredPermissions: string[] = ['android.permission.CAMERA', 'android.permission.RECORD_AUDIO'];
                let missingPermissions: string[] = [];

                return await Promise.all(requiredPermissions.map((requiredPermission) => {
                    return this.androidPermissions.checkPermission(requiredPermission).then((result) => {
                        if (!result.hasPermission) {
                            this.logger.phic.log('Missing permission ' + requiredPermission);
                            missingPermissions.push(requiredPermission);
                        }
                    });
                })).then(async () => {
                    if (missingPermissions && missingPermissions.length > 0) {
                        let alert = this.alertCtrl.create({
                            header: this.translateService.instant('VIDEO.ANDROID_PERMISSIONS_TITLE'),
                            message: this.translateService.instant('VIDEO.ANDROID_PERMISSIONS_DETAIL'),
                            buttons: [
                                {
                                    text: this.translateService.instant('CLOSE_BUTTON'),
                                    handler: () => {
                                        this.androidPermissions.requestPermissions(missingPermissions).then(
                                            (result) => {
                                                resolve();
                                            }, (error) => {
                                                this.logger.phic.error('Error requesting permission from Android' + error);
                                                reject(error);
                                            }
                                        );
                                    }
                                }
                            ]
                        });
                        return (await alert).present();
                    } else {
                        this.hasVideoCallPermissions = true;
                        resolve();
                    }
                });
            } else if (this.platform.is('ios')) {
                this.hasVideoCallPermissions = true;
                resolve();
            }
        });
    }
}
