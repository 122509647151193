import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GatewayService} from './gateway.service';
import {GatewayResponse} from './gateway-response';
import {GatewayRequest} from './gateway-request';
import {GatewaySelectRequest} from './gateway-select-request';
import {GatewayUpdateRequest} from './gateway-update-request';
import {TokenRequest, TokenResponse} from './resources';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TokenService} from '../providers/token/token.service';

/**
 * This is the service to use for interacting with Gateway endpoints that deal with RESTful resources and follow our newer API standard formats.
 * If instead you need to call Gateway endpoints that don't work with these standards, then you can use the more general GatewayService instead.
 *
 * Some reasons to use this:
 * - strict typing of parameters for enforcing consistency
 * - outbound data transformation of the resource you submit if it implements a toJSON function
 * - inbound data transformation of the response if you provide a factory function
 */
@Injectable({
    providedIn: 'root',
})
export class GatewayResourceService {
    constructor(
        private gateway: GatewayService,
        private tokenService: TokenService
    ) {}

    get token(): string {
        return this.tokenService.token;
    }

    set token(token: string) {
        this.tokenService.token = token;
    }

    public login(credentials: TokenRequest): Observable<GatewayResponse<TokenResponse>> {
        return this.post<any, TokenResponse>({endpoint: 'v1/tokens'}, credentials).pipe(
            tap((res: GatewayResponse<TokenResponse>) => {
                this.token = res.data.attributes.token;
            })
        );
    }

    public isLoggedIn(): boolean {
        return this.gateway.isLoggedIn();
    }

    public logout(): void {
        this.gateway.logout();
    }

    public get<T>(options: GatewaySelectRequest, factory?: (data) => T): Observable<GatewayResponse<T|T[]>> {
        return this.sendRequest<undefined, T>('GET', options, undefined, factory);
    }

    public post<T, U>(options: GatewayRequest, resource: T, factory?: (data) => U): Observable<GatewayResponse<U|U[]>> {
        return this.sendRequest<T, U>('POST', options, resource, factory);
    }

    public put<T, U>(options: GatewayUpdateRequest, resource: T, factory?: (data) => U): Observable<GatewayResponse<U|U[]>> {
        return this.sendRequest<T, U>('PUT', options, resource, factory);
    }

    public patch<T, U>(options: GatewayUpdateRequest, resource: T, factory?: (data) => U): Observable<GatewayResponse<U|U[]>> {
        return this.sendRequest<T, U>('PATCH', options, resource, factory);
    }

    public delete<T>(options: GatewayUpdateRequest): Observable<GatewayResponse<T|T[]>> {
        return this.sendRequest<undefined, T>('DELETE', options);
    }

    private sendRequest<T, U>(method: string, options: GatewayRequest, resource?: T, factory?: (data) => U): Observable<GatewayResponse<U|U[]>> {
        const query = {};
        if (options['filter']) {
            query['filter'] = options['filter'];
        }

        return this.gateway[method.toLowerCase()]({
            path: options.endpoint + (options['id'] ? '/' + options['id'] : ''),
            query: query,
            responseType: options.responseType,
            body: resource ? {
                data: typeof resource['toJSON'] === 'function' ? (resource as any).toJSON() : resource
            } : undefined
        }).pipe(tap((res: HttpResponse<U>) => {
            this.parseResponse<U>(res, factory);
        }));
    }

    private parseResponse<T>(res: HttpResponse<T|T[]>, factory?: (data) => T): GatewayResponse<T|T[]> {
        const result = {};
        if (res) {
            if (res.status) result['status'] = res.status;
            if (res.body) {
                if (res.body['data']) {
                    const data = res.body['data'];
                    if (Array.isArray(data)) {
                        result['data'] = data.map((x) => factory ? factory(x) : x);
                    } else {
                        result['data'] = factory ? factory(data) : data;
                    }
                }
                result['meta'] = res.body['meta'];
            }
        }
        return result;
    }
}
