/* eslint camelcase: 0*/

export interface ClinicianSettingsResponse {
    clinician_insecure_reports: boolean;
    clinician_change_email: boolean;
    email: string;
    filter_activity: {visible: boolean, sort: string};
    filter_blood_pressure: {visible: boolean, sort: string};
    filter_clinician: {visible: boolean, sort: string};
    filter_conditions: {visible: boolean, sort: string};
    filter_enrolled: {visible: boolean, sort: string};
    filter_first_name: {visible: boolean, sort: string};
    filter_glucose: {visible: boolean, sort: string};
    filter_imaging: {visible: boolean, sort: string};
    filter_last_name: {visible: boolean, sort: string};
    filter_medications: {visible: boolean, sort: string};
    filter_patient_id: {visible: boolean, sort: string};
    filter_pulse_ox: {visible: boolean, sort: string};
    filter_reviewed: {visible: boolean, sort: string};
    filter_risk: {visible: boolean, sort: string};
    filter_status: {visible: boolean, sort: string, search: number[]};
    filter_subgroup: {visible: boolean, sort: string};
    filter_survey: {visible: boolean, sort: string};
    filter_tablet: {visible: boolean, sort: string};
    filter_temperature: {visible: boolean, sort: string};
    filter_weight: {visible: boolean, sort: string};
    first_name: string;
    last_name: string;
    middle_name: string;
    risk_email: true
    risk_text: true
    session_timeout: string;
    telephone: string;
    time_zone: string;
}
