import {Injectable} from '@angular/core';
import {GatewayApi} from '../api/gateway-api';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject, take} from 'rxjs';
import {getLogger} from '@hrs/logging';

declare function escape(s: string): string;

export enum VideoCallType {
    NONE = 'none',
    OPENTOK = 'opentok',
    ZOOM = 'zoom'
}

export enum VoiceCallStatus {
    NONE = 'NONE',
    CONNECTING = 'CONNECTING',
    CALL_CONNECTED = 'CALL_CONNECTED',
    CALL_ENDED = 'CALL_ENDED',
    CALL_FAILED = 'CALL_FAILED'
}

export enum VideoCallStatus {
    NONE = 'none',
    READY = 'ready',
    ACTIVE = 'active',
    MISSED = 'missed',
    DECLINED = 'declined',
    LEFT = 'left'
}

export enum CallActionOrigin {
    NONE = 'none',
    LOCAL = 'local',
    REMOTE = 'remote'
}

@Injectable({
    providedIn: 'root',
})
export class CommunicationService {
    private readonly logger = getLogger('CommunicationService');

    private readonly activeVideoCallChangeSubject = new BehaviorSubject<VideoCallType>(VideoCallType.NONE);
    private readonly activeVideoCallStatusChangeSubject = new BehaviorSubject<VideoCallStatus>(VideoCallStatus.NONE);
    private readonly activeVoiceCallChangeSubject = new BehaviorSubject<VoiceCallStatus>(VoiceCallStatus.NONE);
    private readonly activeCallInitiationTypeSubject = new BehaviorSubject<CallActionOrigin>(CallActionOrigin.NONE);

    private readonly endVoiceCall = new Subject();
    private readonly chatMessageSent = new Subject();
    private readonly chatMessageSendError = new Subject();
    private readonly videoCallConnected = new Subject();
    private readonly videoCallDeclinedLocally = new Subject();
    private readonly videoCallEndedLocally = new Subject();
    private readonly remoteVideoCalleeDidNotRespond = new Subject();
    private readonly remoteVideoCalleeLeft = new Subject();
    private readonly voiceCallDeclinedLocally = new Subject();
    private readonly voiceCallAnsweredLocally = new Subject();
    private readonly voiceCallStartedLocally = new Subject();
    private readonly voiceCallEndedLocally = new Subject();
    private readonly remoteVoiceCalleeDidNotRespond = new Subject();
    private readonly remoteVoiceCalleeLeft = new Subject();

    private mVideoParticipantId: any;
    private mVideoCallId: any;

    // FIXME:
    // These subjects should be made private.
    // Only observable variants should be exposed,
    // and communication states should only be changed via
    // one of the notifyXXX() methods on this service.
    public readonly endVideoCall = new Subject();
    public readonly callerLeft = new Subject();
    public readonly incomingVideoCall = new Subject();
    public readonly incomingVoiceCall = new Subject();
    public readonly newChatMessage = new Subject();
    public readonly getChatNewMessage = new Subject();
    public readonly exitVideoCallEnterNew = new Subject();
    public readonly exitVoiceCallEnterNew = new Subject();
    public readonly exitChatOpenNew = new Subject();

    public readonly endVideoCall$: Observable<any>;
    public readonly endVoiceCall$: Observable<any>;
    public readonly callerLeft$: Observable<any>;
    public readonly exitVideoCallEnterNew$: Observable<any>;
    public readonly exitVoiceCallEnterNew$: Observable<any>;
    public readonly exitChatOpenNew$: Observable<any>;
    public readonly getChatNewMessage$: Observable<any>;
    public readonly incomingVideoCall$: Observable<any>;
    public readonly incomingVoiceCall$: Observable<any>;
    public readonly newChatMessage$: Observable<any>;
    public readonly chatMessageSent$: Observable<any>;
    public readonly chatMessageSendError$: Observable<any>;
    public readonly videoCallConnected$: Observable<any>;
    public readonly activeVideoCallChange$: Observable<VideoCallType>;
    public readonly activeVideoCallStatusChange$: Observable<VideoCallStatus>;
    public readonly activeVoiceCallChange$: Observable<VoiceCallStatus>;
    public readonly activeCallInitiationType$: Observable<CallActionOrigin>;
    public readonly videoCallDeclinedLocally$: Observable<any>;
    public readonly videoCallEndedLocally$: Observable<any>;
    public readonly remoteVideoCalleeDidNotRespond$: Observable<any>;
    public readonly remoteVideoCalleeLeft$: Observable<any>;
    public readonly voiceCallDeclinedLocally$: Observable<any>;
    public readonly voiceCallAnsweredLocally$: Observable<any>;
    public readonly voiceCallStartedLocally$: Observable<any>;
    public readonly voiceCallEndedLocally$: Observable<any>;
    public readonly remoteVoiceCalleeDidNotRespond$: Observable<any>;
    public readonly remoteVoiceCalleeLeft$: Observable<any>;

    constructor(
        public gatewayApi: GatewayApi
    ) {
        this.exitVideoCallEnterNew$ = this.exitVideoCallEnterNew.asObservable();
        this.exitVoiceCallEnterNew$ = this.exitVoiceCallEnterNew.asObservable();
        this.exitChatOpenNew$ = this.exitChatOpenNew.asObservable();
        this.getChatNewMessage$ = this.getChatNewMessage.asObservable();
        this.newChatMessage$ = this.newChatMessage.asObservable();
        this.chatMessageSent$ = this.chatMessageSent.asObservable();
        this.chatMessageSendError$ = this.chatMessageSendError.asObservable();
        this.incomingVideoCall$ = this.incomingVideoCall.asObservable();
        this.incomingVoiceCall$ = this.incomingVoiceCall.asObservable();
        this.endVideoCall$ = this.endVideoCall.asObservable();
        this.endVoiceCall$ = this.endVoiceCall.asObservable();
        this.callerLeft$ = this.callerLeft.asObservable();
        this.videoCallConnected$ = this.videoCallConnected.asObservable();
        this.activeVideoCallChange$ = this.activeVideoCallChangeSubject.asObservable();
        this.activeVideoCallStatusChange$ = this.activeVideoCallStatusChangeSubject.asObservable();
        this.activeVoiceCallChange$ = this.activeVoiceCallChangeSubject.asObservable();
        this.activeCallInitiationType$ = this.activeCallInitiationTypeSubject.asObservable();
        this.videoCallDeclinedLocally$ = this.videoCallDeclinedLocally.asObservable();
        this.remoteVideoCalleeDidNotRespond$ = this.remoteVideoCalleeDidNotRespond.asObservable();
        this.remoteVideoCalleeLeft$ = this.remoteVideoCalleeLeft.asObservable();
        this.voiceCallDeclinedLocally$ = this.voiceCallDeclinedLocally.asObservable();
        this.voiceCallAnsweredLocally$ = this.voiceCallAnsweredLocally.asObservable();
        this.voiceCallStartedLocally$ = this.voiceCallStartedLocally.asObservable(); 
        this.remoteVoiceCalleeDidNotRespond$ = this.remoteVoiceCalleeDidNotRespond.asObservable();
        this.videoCallEndedLocally$ = this.videoCallEndedLocally.asObservable();
        this.voiceCallEndedLocally$ = this.voiceCallEndedLocally.asObservable();
        this.remoteVoiceCalleeLeft$ = this.remoteVoiceCalleeLeft.asObservable();
    }

    public get videoParticipantId(): any {
        return this.mVideoParticipantId;
    }

    public set videoParticipantId(value: any) {
        this.mVideoParticipantId = value;
    }

    public get videoCallId(): any {
        return this.mVideoCallId;
    }

    public set videoCallId(value: any) {
        this.mVideoCallId = value;
    }

    private get activeVideoCall(): VideoCallType {
        return this.activeVideoCallChangeSubject.value;
    }

    private set activeVideoCall(value: VideoCallType) {
        this.activeVideoCallChangeSubject.next(value);
    }

    public get activeVoiceCallStatus(): VoiceCallStatus {
        return this.activeVoiceCallChangeSubject.value;
    }

    public set activeVoiceCallStatus(value: VoiceCallStatus) {
        this.activeVoiceCallChangeSubject.next(value);
    }

    public get activeCallInitiationType(): CallActionOrigin {
        return this.activeCallInitiationTypeSubject.value;
    }

    public set activeCallInitiationType(value: CallActionOrigin) {
        this.activeCallInitiationTypeSubject.next(value);
    }

    public get isIncomingCall(): boolean {
        return this.activeCallInitiationType === CallActionOrigin.REMOTE;
    }

    public notifyIncomingVideoCall(data: any): void {
        this.activeCallInitiationType = CallActionOrigin.REMOTE;
        this.incomingVideoCall.next(data);
    }
    
    public notifyEndVideoCall(data: any): void {
        this.endVideoCall.next(data);
    }

    public notifyVideoCallEndedLocally(data: any = null): void {
        this.videoCallEndedLocally.next(data);
    }
    
    public notifyCallerLeft(data: any): void {
        this.callerLeft.next(data);
    }

    public notifyRemoteVideoCalleeDidNotRespond(data: any): void {
        this.notifyEndVideoCall(data);
        this.remoteVideoCalleeDidNotRespond.next(data);
    }

    public notifyRemoteVideoCalleeLeft(data: any): void {
        this.notifyCallerLeft(data);
        this.remoteVideoCalleeLeft.next(data);
    }

    public notifyRemoteVoiceCalleeDidNotRespond(data: any): void {
        this.remoteVoiceCalleeDidNotRespond.next(data);
    }

    public notifyRemoteVoiceCalleeLeft(data: any): void {
        this.notifyCallerLeft(data);
        this.remoteVoiceCalleeLeft.next(data);
    }

    public notifyVoiceCallEndedLocally(data: any): void {
        this.voiceCallEndedLocally.next(data);
    }
    
    public notifyNewChatMessage(data: any): void {
        this.newChatMessage.next(data);
    }
    
    public notifyIncomingVoiceCall(data: any): void {
        this.activeCallInitiationType = CallActionOrigin.REMOTE;
        this.incomingVoiceCall.next(data);
    }

    public notifyVideoCallConnected(data: any): void {
        this.videoCallConnected.next(data);
    }

    public notifyExitVideoCallEnterNew(data: any): void {
        this.exitVideoCallEnterNew.next(data);
    }

    public notifyExitVoiceCallEnterNew(data: any): void {
        this.exitVoiceCallEnterNew.next(data);
    }

    public notifyGetChatNewMessage(data: any): void {
        this.getChatNewMessage.next(data);
    }

    public notifyVideoCallDeclinedLocally(data: any): void {
        this.videoCallDeclinedLocally.next(data);
    }

    public notifyVoiceCallDeclinedLocally(data: any): void {
        this.voiceCallDeclinedLocally.next(data);
    }

    public notifyVoiceCallAnsweredLocally(data: any): void {
        this.voiceCallAnsweredLocally.next(data);
    }

    public notifyVoiceCallStartedLocally(data: any): void {
        this.voiceCallStartedLocally.next(data);
    }

    /**
     * Send a GET request to get the chat message history
     */
    public getTextMessages(hrsid?: string, targetHrsid?: string, chatroomId?: number): Observable<any> {
        this.logger.debug(`getTextMessages()`, {hrsid, targetHrsid, chatroomId});
        let request;
        if (chatroomId) {
            // caregiver
            const caregiverRequestUrl = 'chat-messages?filter[chatroomId]=' + chatroomId;
            this.logger.debug(`getTextMessages() caregiverRequestUrl = ${caregiverRequestUrl}`);
            request = this.gatewayApi.get(caregiverRequestUrl);
        } else if (hrsid) {
            // clinician app
            const clinicianRequestUrl = 'apiv2/chat/patient/' + hrsid;
            this.logger.debug(`getTextMessages() clinicianRequestUrl = ${clinicianRequestUrl}`);
            request = this.gatewayApi.get(clinicianRequestUrl, {});
        } else {
            // patient app
            const patientRequestUrl = 'apiv2/chat/clinician';
            this.logger.debug(`getTextMessages() patientRequestUrl = ${patientRequestUrl}`);
            request = this.gatewayApi.get(patientRequestUrl, {}).pipe(
                map((res: any) => {
                    this.logger.debug(`getTextMessages() got patient response`);
                    res.chatdata = res.chatdata.map((message) => {
                        try {
                            message.text = atob(message.text.replace(/-/g, '+').replace(/_/g, '/').replace(/,/g, '='));
                        } catch (e) {
                            this.logger.error('getTextMessages() Error decoding patient message', e);
                        }

                        return message;
                    });
                    return res;
                })
            );
        }

        return request.pipe(map((res: any) => {
            const messages = [];
            const mychats = chatroomId ? res.data : res.chatdata;

            for (const key in mychats) {
                if (mychats.hasOwnProperty(key)) {
                    mychats[key].text = this.decodeMessage(mychats[key].text);
                    messages.push(mychats[key]);
                }
            }
            this.logger.debug(`getTextMessages() decoded ${messages.length} messages`);
            return messages;
        }));
    }

    // decodes special characters
    private decodeMessage(message): string {
        try {
            return decodeURIComponent(escape(message));
        } catch (e) {
            return '[Cannot display message]';
        }
    }

    /**
     * Send a POST request to send a chat message
     */
    public sendTextMessage(text: string, hrsid?: string, targetHrsId?: string, chatroomId?: number): Observable<any> {
        this.logger.debug(`sendTextMessage()`, {text, hrsid, targetHrsId, chatroomId});
        let url;
        let message;
        if (targetHrsId || chatroomId) {
            // cgc
            url = 'chat-messages/';
            message = {
                data: {
                    chatroomId: chatroomId,
                    targetHrsid: targetHrsId,
                    message: text,
                    hrsid: hrsid
                }
            };
        } else if (hrsid) {
            // cc
            url = 'apiv2/chat/patient/' + hrsid;
            message = {msg: text};
        } else {
            // pc
            url = 'apiv2/chat/clinician';
            message = {msg: btoa(text)};
        }

        this.logger.debug(`sendTextMessage() to url -> ${url}`);
        return this.gatewayApi.post(url, message).pipe(
            tap({
                next: (ev) => this.chatMessageSent.next(ev),
                error: (err) => this.chatMessageSendError.next(err)
            })
        );
    }

    /**
     * Send a GET request for list of chatrooms that match the participant(s)
     */
    public getChatrooms(participants: string[]): Observable<any> {
        this.logger.debug(`getChatrooms()`, {participants});
        let url = 'chatrooms';
        participants.forEach((participant, i) => {
            url += i === 0 ? '?' : '&';
            url += 'filter[participants][]=' + participant;
        });

        this.logger.debug(`getChatrooms() with url = ${url}`);
        return this.gatewayApi.get(url);
    }

    public hasActiveVideoCall(): boolean {
        const hasActiveCall: boolean = this.activeVideoCall !== VideoCallType.NONE;
        this.logger.debug(`hasActiveVideoCall(): ${hasActiveCall}`);
        return hasActiveCall;
    }

    public videoCallType(): VideoCallType {
        this.logger.debug(`videoCallType(): ${this.activeVideoCall}`)
        return this.activeVideoCall;
    }

    public setVideoCallActive(callType: VideoCallType): void {
        this.logger.debug(`setVideoCallActive()`, {callType});
        this.activeVideoCall = callType;
    }

    public setVideoCallInactive(): void {
        this.logger.debug(`setVideoCallInactive()`);
        this.activeVideoCall = VideoCallType.NONE;
    }

    /**
     * Send a post request to the gateway to start a video call
     */
    public getVideoCallId(callerHrsID: string, calleeHrsID?: string): Observable<any> {
        this.logger.debug(`getVideoCallId()`, {callerHrsID, calleeHrsID});
        let participantData;
        if (calleeHrsID) {
            // clinician and caregiver app
            participantData = {
                data: {
                    participants: [
                        {
                            hrsid: callerHrsID,
                            role: 'initiator',
                        },
                        {
                            hrsid: calleeHrsID,
                            role: 'participant',
                        },
                    ],
                },
            };
        } else {
            // patient app
            participantData = {
                data: {
                    participants: [
                        {
                            hrsid: callerHrsID,
                            role: 'initiator',
                        }
                    ],
                }
            };
        }

        const url = 'video-calls';
        this.logger.debug(`getVideoCallId() with url = ${url}`, {participantData});
        return this.gatewayApi.post(
            url,
            participantData
        );
    }

    /**
     * creates opentok video call token
     */
    public getVideoCallToken(callId, calleeHrsId): Observable<any> {
        this.logger.debug(`getVideoCallToken()`, {callId, calleeHrsId});
        const callData = {
            data: {
                callId: callId,
                participant: {
                    hrsid: calleeHrsId,
                },
            },
        };
        const url = 'video-call-tokens';
        this.logger.debug(`getVideoCallToken() with url = ${url}`, {callData});
        return this.gatewayApi.post(
            url,
            callData
        );
    }

    public getVideoCallStatus(callId): Observable<any> {
        const url = `video-calls/${callId}`;
        this.logger.debug(`getVideoCallToken() with callId = ${callId}, url = ${url}`);
        return this.gatewayApi.get(url).pipe(
            tap({
                next: (res: any) => {
                    if (res?.data?.status) {
                        this.activeVideoCallStatusChangeSubject.next(res.data.status);
                    }
                }
            })
        );
    }

    /**
     * updates the backend with active call participants
     * creates a log or 'call history'
     */
    public updateParticipantStatus(status): void {
        this.logger.debug(`updateParticipantStatus()`, {status});
        if (this.videoParticipantId) {
            this.logger.debug(`updateParticipantStatus() has videoParticipantId`);
            const participantData = {
                data: {
                    status: status,
                }
            };
            const url = 'video-participants/' + this.videoParticipantId;
            this.logger.debug(`updateParticipantStatus() with url = ${url}`, {participantData});
            const request = this.gatewayApi.patch(
                url,
                participantData
            );

            request.subscribe((res)=>{
                this.logger.debug('updateParticipantStatus() Video participant status successfully updated to: ' + status, res);
            }, (err) => {
                this.logger.debug('updateParticipantStatus() Video participant status failed to update', err);
            });

            this.activeVideoCallStatusChangeSubject.next(status);

            switch (status) {
                case VideoCallStatus.MISSED:
                case VideoCallStatus.DECLINED:
                case VideoCallStatus.LEFT:
                    this.logger.debug(`updateParticipantStatus() clearing videoParticipantId & videoCallId`);
                    this.videoParticipantId = null;
                    this.videoCallId = null;
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Send a GET request to start the voice call
     */
    public initializeOutgoingVoiceCall(hrsid?: string): Observable<any> {
        this.logger.debug(`initializeOutgoingVoiceCall()`, {hrsid});
        let url;
        if (hrsid === 'techsupport') {
            url = 'apiv2/voice/start?type=techsupport';
        } else if (hrsid) {
            // cc
            url = 'apiv2/voice/patient/start/' + hrsid;
        } else {
            // pc
            url = 'apiv2/voice/start?type=clinician';
        }
        this.logger.debug(`initializeOutgoingVoiceCall() with url = ${url}`);
        return this.gatewayApi.get(url, {});
    }

    /**
     * Accept incoming call, get call token
     */
    public acceptIncomingVoiceCall(callId: any): Observable<any> {
        const url = 'apiv2/voice/patient/accept';
        const data = {callid: callId};
        this.logger.debug(`acceptIncomingVoiceCall() url = ${url}`, data);
        return this.gatewayApi.post(url, data);
    }

    // notifies the other participant that the user has left the call
    public voiceCallLeft(callId): void {
        const url = 'apiv2/voice/leave';
        const data = {callid: callId};
        this.logger.debug(`voiceCallLeft() url = ${url}`, data);
        this.gatewayApi.post(url, data)
            .pipe(take(1))
            .subscribe((res) => {
                this.logger.debug('voiceCallLeft() Successfully sent call left status', res);
            }, (err) => {
                this.logger.error('voiceCallLeft() Failed to send call left status', err);
        });
    }
}
