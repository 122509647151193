import * as moment from 'moment';
import { Moment } from 'moment';

export class MetricEntry {
    public date: Moment;
    public manual: boolean = false;
    public deleted: boolean = false;
    public id: string;
    constructor(
        timestamp: number,
        id: string
    ) {
        this.date = moment.unix(timestamp / 1000);
        this.id = id;
    }
}
