import {environment} from '@app/env';
export {environment as prod} from '../environments/environment.prod';
export {environment as kramer} from '../environments/environment.kramer';
export {environment as newman} from '../environments/environment.newman';
export {environment as puddy} from '../environments/environment.puddy';
export {environment as hrsdomain} from '../environments/environment.hrsdomain';

export class BuildUtility {
    static MIN_TABLET_SCREEN_SIZE = 600;
    // static isModeSet: boolean = false;
    // static isDesignModeSet: boolean = false;
    static domains: {
        domain: 'prod' | 'staging2' | 'staging1' | 'hrsdomain',
        HRS_DOMAIN: string,
        API_GATEWAY_URL: string
    }[] = [
        {
            domain: 'prod',
            HRS_DOMAIN: 'healthrecoverysolutions.com',
            API_GATEWAY_URL: 'https://gateway.healthrecoverysolutions.com'
        },
        {
            domain: 'staging1',
            HRS_DOMAIN: 'staging1.hrsqa.com',
            API_GATEWAY_URL: 'https://gateway.staging1.hrsqa.com'
        },
        {
            domain: 'staging2',
            HRS_DOMAIN: 'staging2.hrsqa.com',
            API_GATEWAY_URL: 'https://gateway.staging2.hrsqa.com'
        },
    ];

    /**
     * Check for HRSTAB flag.
     * @return boolean
     */
    static isHRSTab(): boolean {
        return environment.HRSTAB === 'true';
    }

    /**
     * Get Current Build Environment.
     * @return string
     */
    static getEnvironment(): string {
        return environment.current_environment;
    }

    /**
     * Get Name of Current Domain that the Device is Pointed to.
     * @return string
     */
    static getDomainName(): 'prod' | 'staging1' | 'staging2' | 'hrsdomain' {
        const domainInfo = BuildUtility.domains.filter((domain) => domain.API_GATEWAY_URL === environment.API_GATEWAY_URL)[0];
        return domainInfo ? domainInfo.domain : 'hrsdomain';
    }

    /**
     * Get Current API_GATEWAY_URL.
     * @return string
     */
    static getGatewayURL(): string {
        return environment.API_GATEWAY_URL;
    }

    /**
     * Get Current HRS_DOMAIN.
     * @return string
     */
    static getHRSDomain(): string {
        return environment.HRS_DOMAIN;
    }

    /**
     * Set Current Build Gateway URL to User's Selection in Admin Domain Settings.
     * @return Promise<string>
     */
    static setDomain(domain: 'prod' | 'staging1' | 'staging2' | string): Promise<string> {
        const isHRSDomain = domain?.includes('.com') ?? false;
        const newDomain = isHRSDomain ? {
            HRS_DOMAIN: domain,
            API_GATEWAY_URL: `https://gateway.${domain}`
        } : BuildUtility.domains.filter((option) => option.domain === domain)[0];

        if (!newDomain) {
            return Promise.reject(new Error('Chosen domain is not accepted.'));
        }

        for (const property in newDomain) {
            if (newDomain[property]) {
                environment[property] = newDomain[property];
            }
        }

        return Promise.resolve(environment.API_GATEWAY_URL);
    }
}
