import {AdminService} from './admin/admin.service';
import {CommunicationService, ModalService, SurveyDetailsService} from '@hrs/providers';
import {DataExchangeService} from './data-exchange/data-exchange.service';
import {Environment} from './environment/environment';
import {FirebaseNotifications} from './firebase/firebase';
import {HistoricalDataService} from './historical-data/historical-data.service';
import {PatientDeviceService} from './patientProfile/patientDevice.service';
import {PatientListService} from './patientList/patientList.service';
import {PatientProfileService} from './patientProfile/patientProfile.service';
import {ReviewedStatusService} from './reviewed-status/reviewed-status.service';
import {Settings} from './settings/settings';
import {User} from './user/user';
import {UserAgentProvider} from './user-agent/user-agent';
import {ZoomService} from './zoom/zoom.service';
import {PermissionService} from './permissions/permission.service';

export {
    AdminService,
    CommunicationService,
    DataExchangeService,
    Environment,
    FirebaseNotifications,
    HistoricalDataService,
    ModalService,
    PatientDeviceService,
    PatientListService,
    PatientProfileService,
    PermissionService,
    ReviewedStatusService,
    Settings,
    SurveyDetailsService,
    User,
    UserAgentProvider,
    ZoomService
};
