<ion-header>
    <ion-toolbar class="c_app_header is-modal">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
                {{ 'ADD_EDIT_DEVICE' | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card class="c_card">
        <ion-card-header>
            <b>{{ 'CURRENT_DEVICE' | translate }} </b> {{ currentAssignedDevice }}
        </ion-card-header>
    </ion-card>

    <ion-list>
        <ion-item *ngIf="availableDevices == 'None'" class="noDevicesAvailable">
            <ion-label>{{ 'NO_DEVICE' | translate }}</ion-label>
        </ion-item>

        <ion-item class="c_add_device--select" *ngIf="availableDevices != 'None'">
            <ion-label>{{ 'NEW_DEVICE' | translate }}</ion-label>
            <ion-select [(ngModel)]="selectedValue" [value]="selectedValue" multiple="false">
                <ion-label>{{ 'NEW_DEVICE' | translate }}</ion-label>
                <ion-select-option *ngFor="let device of availableDevices" value="{{device.name}}">
                    {{device.name}}
                </ion-select-option>
            </ion-select>
        </ion-item>
    </ion-list>

</ion-content>
<ion-footer class="c_footer">
    <ion-button class="c_footer--button c_footer--button-cancel"
                (click)="dismiss()">
        {{ 'CANCEL_BUTTON' | translate }}
    </ion-button>
    <ion-button class="c_footer--button c_footer--button-save"
                (click)="saveEditToAssignedDevice()" 
                *ngIf="availableDevices != 'None'" 
                [disabled]="!selectedValue"><ion-icon class="c_footer--icon-save"></ion-icon>
        {{ 'SAVE_BUTTON' | translate }}
    </ion-button>
</ion-footer>
