import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {enableProdMode} from '@angular/core';
import {environment} from '@app/env';
import {shim as shimPromiseFinally} from 'promise.prototype.finally';

shimPromiseFinally();

if (environment.HRS_DOMAIN === 'healthrecoverysolutions.com') {
    environment.production = true;
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
