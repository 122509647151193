import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {CareplanService} from '../../services/patientProfile/careplan/careplan.service';
import {ModalService} from '@clinician/providers';
import {EditCareplanPage} from './edit-careplan/edit-careplan.page';
import {getLogger} from '@hrs/logging';

// observables
import {forkJoin, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
    CareplanToggle,
    CareplanToggleMap,
    MedicationReminderDetail,
    ReminderTimeAndWindow,
    TimeReminderDetail
} from '../../../common/interfaces/careplan.interface';
import {Patient} from '@clinician/models';
import {EnvPresetsResponse} from '../../../common/interfaces/env-presets-response.interface';
import {GlobalModulesResponse} from '../../../common/interfaces/global-modules-response.interface';

@Component({
    selector: 'careplan-page',
    templateUrl: './careplan.page.html',
    styleUrls: ['./careplan.page.scss'],
})
export class CareplanPage implements OnInit {
    private readonly logger = getLogger('CareplanPage');
    activeModules: string[];
    activePresets: string[];
    hasChanges: boolean;
    modules: CareplanToggleMap = {};
    patient: Patient;
    presets: CareplanToggleMap = {};
    activityReminders: ReminderTimeAndWindow = {};
    surveyReminders: ReminderTimeAndWindow = {};
    weightReminders: ReminderTimeAndWindow = {};
    medicationReminders: MedicationReminderDetail[];

    constructor(
        private carePlan: CareplanService,
        private modalCtrl: ModalController,
        private modalService: ModalService,
        private navParams: NavParams,
    ) {
        this.patient = this.navParams.get('patient');
        this.modalService.setModalStatus('CarePlanPage', true, this.patient.hrsid);
    }

    ngOnInit() {
        this.loadPatientCareplan();
    }

    loadPatientCareplan(): void {
        forkJoin(
            [this.carePlan.getEnvPresets().pipe(catchError((error) => of(error))),
                this.carePlan.getPatientModules().pipe(catchError((error) => of(error))),
                this.carePlan.getPatientModulesWithReminders(this.patient.hrsid).pipe(catchError((error) => of(error)))]
        ).subscribe(([presetsRes, modulesRes, remindersRes]: any) => {
            this.parseCareplanResponse(presetsRes.data, modulesRes.data);
            this.populateActiveItems('presets');
            this.getModulesWithReminders(remindersRes);
        });
    }

    parseCareplanResponse(presetsResponse: EnvPresetsResponse[], modulesResponse: GlobalModulesResponse[]): void {
        const careplanSettings = [...Object.values(presetsResponse), ...Object.values(modulesResponse)];
        const patientPresets = this.navParams.get('activePresets');
        const patientModules = this.navParams.get('activeModules');
        careplanSettings.forEach((item) => {
            let role = item['resourceType'];
            let isModules = role === 'modules';
            let settings = isModules ? patientModules : patientPresets;
            let key = isModules ? item['attributes']['shortname'] : item['id'];
            // Assign preset and module objects accounting for variations in naming schemes
            this[role][key] = {
                item: item,
                selected: isModules ? settings.includes(key) : settings.includes(item['attributes'].name)
            };
        });
    }

    async openEditCarePlanModal(isModules: boolean) {
        const careplanSettings = isModules ? this.modules : this.presets;
        const keys = Object.keys(careplanSettings);
        const modal = await this.modalCtrl.create({
            component: EditCareplanPage,
            componentProps: {careplanSettings, keys, isModules}
        });

        modal.onDidDismiss().then(({data}) => {
            if (data === undefined) {
                this.hasChanges = false;
                this.logger.phic.debug(data, 'data is undefined');
            } else if (data.updatedItems.length) {
                this.hasChanges = true;
                this.updateCarePlanSettings(data.updatedItems, data.role);
                const modules = this.assemblePayload();
                this.carePlan.savePatientModuleInfo(this.patient.hrsid, modules, this.activityReminders, this.surveyReminders, this.weightReminders, this.medicationReminders).subscribe((res) => {
                    if (res) {
                        this.logger.phic.log('Care Plan settings saved: ', res);
                    } else {
                        this.logger.phic.warn('Unable to save Care Plan settings: ', res);
                    }
                });
            } else {
                this.hasChanges = false;
                this.logger.phic.info('No changes made');
            }
        });
        await modal.present();
    }

    getModulesWithReminders(remindersRes: any): void {
        const patientmodule = remindersRes['data'].patientmoduleinfo;

        if (!patientmodule.activityreminders) {
            this.activityReminders.window = '-';
            this.activityReminders.time = 20;
        } else {
            this.activityReminders.window = '-';
            this.activityReminders.time = 20;
        }

        if (!patientmodule.surveyreminders) {
            this.surveyReminders.window = '60';
            this.surveyReminders.time = 600;
        } else {
            this.surveyReminders.window = patientmodule.surveyreminders.window;
            this.surveyReminders.time = this.calculateHoursAndMinutes(patientmodule.surveyreminders);
        }

        if (!patientmodule.weightreminders) {
            this.weightReminders.window = '60';
            this.weightReminders.time = 540;
        } else {
            this.weightReminders.window = patientmodule.weightreminders.window;
            this.weightReminders.time = this.calculateHoursAndMinutes(patientmodule.weightreminders);
        }

        if (patientmodule.medicationreminders.length !== 0) {
            this.medicationReminders = patientmodule.medicationreminders;
            // The GET response sends "times" instead of time - annoyingly inconsistent
            for (let i = 0; i < this.medicationReminders.length; i++) {
                this.medicationReminders[i].time = this.medicationReminders[i]['times'];
            }

            for (let i = 0; i < this.medicationReminders.length; i++) {
                if (this.medicationReminders[i].time !== 'PRN') {
                    this.medicationReminders[i].time = this.calculateMedicationHoursAndMinutes(String(this.medicationReminders[i].time));
                } else {
                    this.medicationReminders[i].window = '30';
                }
            }
        }
    }

    calculateHoursAndMinutes(reminderDetail: TimeReminderDetail): number | string {
        if (reminderDetail.ampm === 'PM') {
            return (parseInt(reminderDetail.hours) + 12) * 60 + parseInt(reminderDetail.minutes);
        } else {
            return parseInt(reminderDetail.hours) * 60 + parseInt(reminderDetail.minutes);
        }
    }

    calculateMedicationHoursAndMinutes(reminderDetail: string): number {
        // It doesn't return an "AMPM" key like the others do
        let splitReminderTimes = reminderDetail.match(/[a-z]+|[^a-z]+/gi);
        let splitHourAndMinutes = splitReminderTimes[0].split(/\s*(?:\bas\b|:)\s*/);
        let hours = splitHourAndMinutes[0];
        let minutes = splitHourAndMinutes[1];
        if (splitReminderTimes[1] === 'PM') {
            return (parseInt(hours) + 12) * 60 + parseInt(minutes);
        } else {
            return parseInt(hours) * 60 + parseInt(minutes);
        }
    }

    updateCarePlanSettings(updatedItems: [string, CareplanToggle][], roleUpdated: string): void {
        updatedItems.forEach((item: [string, CareplanToggle]) => {
            this[roleUpdated][item[0]].selected = item[1].selected;
            if (roleUpdated === 'presets' && item[1].selected) {
                // Enable related modules for toggled presets
                this.enablePresetModules(item);
            }
        });
        this.populateActiveItems('presets');
        this.populateActiveItems('modules');
    }

    enablePresetModules(preset: [string, CareplanToggle]): void {
        const key = preset[0];
        const status = preset[1].selected;
        const relationships = this.presets[key].item['relationships'].modules.data;
        if (status) {
            relationships.forEach((rel) => {
                let mod = Object.values(this.modules).filter((mod) => mod['item'].id === rel.id)[0];
                this.modules[mod['item'].attributes['shortname']].selected = true;
            });
        }
    }

    populateActiveItems(role: string): string[] {
        const items = [];
        let capName = role.replace(/(p|m)/, (match) => match.toUpperCase());
        for (let key in this[role]) {
            if (this[role].hasOwnProperty(key)) {
                let name = role === 'modules' ? this[role][key].item.attributes['shortname'] : this[role][key].item.id;
                if (this[role][key].selected) items.push(name);
            }
        }
        this['active' + capName] = items;
        return items;
    }

    assemblePayload(): string[] {
        const modules = this.activeModules.filter((module) => this.modules[module].selected);
        const presets = this.activePresets.filter((preset) => this.presets[preset].selected);
        return modules.concat(presets);
    }

    dismiss(): void {
        this.modalCtrl.dismiss({modules: this.modules, presets: this.presets, hasChanges: this.hasChanges});
        this.modalService.setModalStatus('CarePlanPage', false, null);
    }
}
