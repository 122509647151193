<ion-header>
    <ion-toolbar class="c_app_header is-modal statusbar-padding">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="cancel()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="isModules" class="c_app_header--title">
            <span class="c_app_header--text">{{ 'CAREPLAN' | translate }} - {{ 'CAREPLAN_EDIT_MODULES' | translate }}</span>
        </ion-title>
        <ion-title *ngIf="!isModules" class="c_app_header--title">
            <span class="c_app_header--text">{{ 'CAREPLAN' | translate }} - {{ 'CAREPLAN_EDIT_PRESETS' | translate }}</span>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="edit-careplan-page">
    <ion-list class="c_careplan--list">
        <ion-item class="c_careplan--toggle"
                  [ngClass]="{'is-selected': careplanToggleMap[key].selected}"
                  *ngFor="let key of keys"
                  (click)="toggleCareplanItem(careplanToggleMap[key].item)">
            <ion-label class="c_careplan--name">
                {{ careplanToggleMap[key].item.attributes.name }}
            </ion-label>
            <ion-checkbox class="c_card--checkbox is-radio"
                          [checked]="careplanToggleMap[key].selected"></ion-checkbox>
        </ion-item>
    </ion-list>
</ion-content>
<ion-footer class="c_footer">
    <ion-button class="c_footer--button c_footer--button-cancel"
                (click)="cancel()">
        {{ 'CANCEL_BUTTON' | translate }}
    </ion-button>
    <ion-button class="c_footer--button c_footer--button-save"
                [disabled]="isCareplanEmpty()"
                (click)="dismiss()">
        <ion-icon class="c_footer--icon-save"></ion-icon>
        {{ 'SAVE_BUTTON' | translate }}
    </ion-button>
</ion-footer>
