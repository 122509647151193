import {Component} from '@angular/core';
import {ModalController, NavParams, Platform, NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {PatientDeviceService, PatientProfileService} from '@clinician/providers';
import {NavigationExtras} from '@angular/router';

@Component({
    selector: 'add-device',
    templateUrl: 'add-device.page.html',
    styleUrls: ['add-device.page.scss'],
})
export class AddDevicePage {
    availableDevices: any;
    currentAssignedDevice: any;
    selectedValue: any;
    patientHrsid: string;
    unassignedDevice: string;
    hrsid: string;
    deviceInformation: any;

    constructor(
        public platform: Platform,
        public http: HttpClient,
        public translateService: TranslateService,
        public navParams: NavParams,
        public modalCtrl: ModalController,
        public patientDeviceService: PatientDeviceService,
        public patientProfileService: PatientProfileService,
        public navCtrl: NavController
    ) {
        let patient = [];
        patient.push(this.navParams.data);
        this.patientHrsid = this.navParams.get('patient');
        this.getPatientProfile(this.patientHrsid);
    }

    ngOnInit() {
        this.getAvailableDeviceInventory();
    }

    /**
     * Checking for all available device inventory
     */

    getAvailableDeviceInventory() {
        return this.patientDeviceService.getAvailableDeviceInventory().subscribe((res: any) => {
            if (res.length == 0) {
                this.availableDevices = 'None';
            } else {
                this.availableDevices = res;
            }
        });
    }

    /**
     * Calling updated patient profile after an edit
     */

    getPatientProfile(hrsid): object {
        this.hrsid = hrsid;
        this.translateService.get('UNASSIGNED_DEVICE').subscribe((value) => {
            this.unassignedDevice = value;
        });

        return this.patientProfileService.getPatientProfile(hrsid).subscribe((res: any) => {
            if (res.device) {
                this.deviceInformation = res.device;
                this.currentAssignedDevice = res.device.name;
            } else {
                this.currentAssignedDevice = this.unassignedDevice;
            }
        });
    }

    /**
     * Changing or assigning a device to a patient
     */

    saveEditToAssignedDevice() {
        let selectedDeviceName = this.selectedValue;

        let selectedDevice = this.availableDevices.find(function(deviceName) {
            return deviceName.name === selectedDeviceName;
        });
        return this.patientDeviceService.saveEditToAssignedDevice(this.patientHrsid, selectedDevice).subscribe((res: HttpEvent<{status: string}>) => {
            this.modalCtrl.dismiss(selectedDevice.id);
        });
    }

    dismiss() {
        let currentDevice = this.deviceInformation;
        if (currentDevice == undefined) {
            this.modalCtrl.dismiss();
        } else {
            return this.patientDeviceService.saveEditToAssignedDevice(this.patientHrsid, currentDevice.devid).subscribe((res: HttpEvent<{status: string}>) => {
                this.modalCtrl.dismiss(currentDevice.devid);
            });
        }
    }
}
