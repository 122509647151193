import {Injectable} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {Subject, Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {isString} from 'lodash';
import {
    FirebaseMessaging,
    INotificationPayload,
    FirebaseMessagingEvent,
    FirebaseMessagingEventType
} from 'cordova-plugin-firebase-messaging';

const MAX_DELEGATE_SET_ATTEMPTS = 3;

@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingService {
    private readonly logger = getLogger('FirebaseMessagingService');

    private readonly tokenRefreshSubject = new Subject<string>();
    private readonly notificationSubject = new Subject<INotificationPayload>();

    private sharedEventDelegateSuccessProxy: (ev: FirebaseMessagingEvent) => void;
    private sharedEventDelegateErrorProxy: (err: any) => void;
    private setEventDelegateAttempts: number = 0;

    public readonly tokenRefresh$: Observable<string>;
    public readonly notification$: Observable<INotificationPayload>;

    constructor() {
        this.sharedEventDelegateSuccessProxy = this.onSharedEventDelegateSuccess.bind(this);
        this.sharedEventDelegateErrorProxy = this.onSharedEventDelegateError.bind(this);
        this.tokenRefresh$ = this.tokenRefreshSubject.asObservable().pipe(share());
        this.notification$ = this.notificationSubject.asObservable().pipe(share());
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        this.trySetEventDelegate();
    }

    private trySetEventDelegate(): void {
        this.logger.trace(`trySetEventDelegate()`);
        if (this.setEventDelegateAttempts >= MAX_DELEGATE_SET_ATTEMPTS) {
            this.logger.warn(`trySetEventDelegate() max attempts exceeded! -> ${MAX_DELEGATE_SET_ATTEMPTS}`);
            return;
        }

        this.setEventDelegateAttempts++;
        FirebaseMessaging.setSharedEventDelegate(
            this.sharedEventDelegateSuccessProxy,
            this.sharedEventDelegateErrorProxy
        );
    }

    private onSharedEventDelegateSuccess(ev: FirebaseMessagingEvent): void {
        this.logger.debug(`onSharedEventDelegateSuccess()`, ev);
        switch (ev?.type) {
            case FirebaseMessagingEventType.NOTIFICATION:
                this.notificationSubject.next(ev.data as any);
                break;
            case FirebaseMessagingEventType.TOKEN_REFRESH:
                if (isString(ev?.data?.token)) {
                    this.tokenRefreshSubject.next(ev.data.token);
                } else {
                    this.logger.warn(`firebase token event is missing token value!`);
                }
                break;
            default:
                this.logger.warn(`received unknown event type!`);
                break;
        }
    }

    private onSharedEventDelegateError(error: any): void {
        this.logger.warn(`onSharedEventDelegateError()`, error);
        this.logger.warn(`will attempt to re-initialize the event delegate`);
        setTimeout(() => this.trySetEventDelegate(), 1000);
    }
}
