export const environment = {
    production: false,
    app_id: 'app_id_value',
    app_name: 'app_name_value',
    app_version: 'app_version_value',
    app_version_code: 'app_version_code_value',
    current_environment: 'newman',
    HRS_DOMAIN: 'staging1.hrsqa.com',
    API_GATEWAY_URL: 'https://gateway.staging1.hrsqa.com',
    DEBUG: true,
    HRSTAB: 'HRSTAB_value',
    knox_manage: 'knox_manage_value',
    LEGACYDESIGN: 'LEGACYDESIGN_value',
    UPDATEINTERVAL: 3600000,
};
