export class ConvertRatio {
    /**
     * @param number $leftValue
     * @param number $rightValue
     * @return number
     *
     *  Formula:  % = n / d * 100
     */
    static ratioToPercentage(leftValue: number , rightValue: number): number {
        return (leftValue / rightValue) * 100;
    }

    /**
     * @param string ratio
     * @return number
     */
    static ratioStringToPercentage(ratio: string): number {
        const ratioArray = ratio.split(':');
        if (ratioArray.length !== 2) {
            throw new Error('Invalid ratio: ' + ratio);
        }

        const ratioLeft = Number(ratioArray[0]);
        const ratioRight = Number(ratioArray[1]);
        if (Number.isNaN(ratioLeft) || Number.isNaN(ratioRight)) {
            throw new Error('One side of this ratio is not a number: ' + ratio);
        }

        return this.ratioToPercentage(ratioLeft, ratioRight);
    }

    /**
     * @param number percentage
     * @return number
     */
    static percentageToRatio(percentage: number): number {
        const base = 100;
        const ratio = percentage / base;
        return ratio;
    }
}
