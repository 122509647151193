import {MetricEntry} from '@hrs/models';

export class MedicationEntry extends MetricEntry {
    public name: string;
    public dose: number;
    public schedule: {type: string, schedule: string};
    public time: string;
    public ts: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.name = entry.med;
        this.dose = entry.dose;
        this.schedule = entry.schedule;
        this.time = entry.time;
        this.ts = entry.ts;
    }
}
