import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataExchangeService {
    static UpdatePatient = 'updatedPatient';
    static UpdatedReviewedStatus = 'updatedReviewedStatus';
    static UpdatedActivationStatus = 'updatedActivationStatus';

    private data = new Map();

    constructor() { }

    setData(id, data) {
        this.data.set(id, data);
    }

    getData(id, clearData?) {
        const data = this.data.get(id);
        if (clearData) this.data.delete(id);
        return data;
    }

    hasData(id) {
        return this.data.has(id);
    }
}
