import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@hrs/providers';
import {CareplanItem, CareplanToggleMap} from '../../../../common/interfaces/careplan.interface';

@Component({
    selector: 'app-edit-careplan',
    templateUrl: './edit-careplan.page.html',
    styleUrls: ['./edit-careplan.page.scss'],
})
export class EditCareplanPage implements OnInit {
    isModules: boolean = false;
    careplanToggleMap: CareplanToggleMap = {};
    keys: string[];
    originalSettings: string;
    updatedItems: string[] = [];

    constructor(
        public modalCtrl: ModalController,
        public modalService: ModalService,
        public navParams: NavParams,
        public platform: Platform,
        public translateService: TranslateService,
        private alertCtrl: AlertController
    ) {
        this.modalService.setModalStatus('EditCarePlanPage', true);
    }

    ngOnInit() {
        this.originalSettings = JSON.stringify(this.navParams.get('careplanSettings'));
        this.keys = this.navParams.get('keys');
        this.isModules = this.navParams.get('isModules');
        this.careplanToggleMap = JSON.parse(this.originalSettings);
    }

    toggleCareplanItem(item: CareplanItem): void {
        const key = this.isModules ? item.attributes['shortname'] : item.id;
        this.careplanToggleMap[key].selected = !this.careplanToggleMap[key].selected;
        if (!this.updatedItems.includes(key)) this.updatedItems.push(key);
        if (this.isCareplanEmpty() && this.isModules) this.alertCareplanStatus().then();
    }

    isCareplanEmpty(): boolean {
        // When editing presets, clinicians should be able to save with no selections.
        if (!this.isModules) return false;
        return !Object.values(this.careplanToggleMap).some((item) => item.selected);
    }

    async alertCareplanStatus(): Promise<any> {
        let alertMessage;
        this.translateService.get('CAREPLAN_EMPTY_ALERT').subscribe((value) => alertMessage = value);
        const alertOptions = {message: alertMessage};
        let alert = await this.alertCtrl.create(alertOptions);
        return await alert.present();
    }

    determineStateChange() {
        const originalSettings = JSON.parse(this.originalSettings);
        return Object.entries(this.careplanToggleMap).filter((item) => {
            let key = item[0];
            let status = item[1]['selected'];
            if (originalSettings[key].selected !== status) return item;
        });
    }

    cancel(): void {
        // this.careplanSettings does exist, but is not explicitly defined. Instead it is created from NavParams;
        // @ts-ignore
        this.careplanSettings = JSON.parse(this.originalSettings);
        this.modalCtrl.dismiss();
        this.modalService.setModalStatus('EditCarePlanPage', false);
    }

    dismiss(): void {
        const role = this.isModules ? 'modules' : 'presets';
        const updatedItems = this.determineStateChange();
        this.modalCtrl.dismiss({
            updatedItems,
            role
        });
        this.modalService.setModalStatus('EditCarePlanPage', false);
    }
}
