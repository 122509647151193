import {Component, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ModalController, NavParams, Platform, IonContent, AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService} from '@clinician/providers';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Subscription} from 'rxjs';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {getLogger} from '@hrs/logging';

declare var Twilio: any;

export interface VoiceCallLeftData {
    hrsid: string,
    callid: string
}

@Component({
    selector: 'page-voice',
    templateUrl: 'voice.page.html',
})
export class VoicePage {
    private readonly logger = getLogger('VoicePage');
    backAction: any;
    calling: boolean = false;
    callStatus: string;
    patientGender: string;
    riskClass: string;
    callData: any;
    modalClosing: boolean;
    endCallEvent: Subscription;
    exitCallEnterNewEvent: Subscription;
    private callerLeft: Subscription;

    @ViewChild(IonContent, {}) content: IonContent;

    constructor(
        private platform: Platform,
        private communication: CommunicationService,
        private translateService: TranslateService,
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private screenOrientation: ScreenOrientation,
        private alertCtrl: AlertController,
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private dialogs: Dialogs
    ) {
        this.callData = this.navParams.get('callData');
        this.modalService.setModalStatus('VoicePage', true);
    }

    ngOnInit() {
        if (this.platform.is('cordova')) {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
        // add handler so modal isn't closed accidentally during a call by the hardware back button
        this.backAction = this.platform.backButton.subscribeWithPriority((1), () => {
            this.dismiss();
        });
        // incoming call, show ring status
        if (this.callData.callId) {
            this.callStatus = 'RINGING';
        }
        // tapped with app in background
        if (this.callData.answer) {
            this.initializeVoiceCall();
        }

        this.initNotificationListeners();
    }

    ngOnDestroy() {
        if (this.callerLeft) this.callerLeft.unsubscribe();
    }

    ionViewDidLeave() {
        // removes hardware back button handler
        this.backAction.unsubscribe();
        // unsubscribe from listeners
        this.endCallEvent.unsubscribe();
        this.exitCallEnterNewEvent.unsubscribe();

        if (this.platform.is('cordova')) {
            this.screenOrientation.unlock();
        }
    }

    initNotificationListeners() {
        // the person we we're calling missed or ignored the call
        this.endCallEvent = this.communication.endVoiceCall$.subscribe((data: any) => {
            if (
                data &&
                (data.action === 'call_unanswered' || data.action === 'call_declined')
            ) {
                this.endCall();
            }
        });

        this.callerLeft = this.communication.callerLeft$.subscribe((data: VoiceCallLeftData) => {
            // if the call has not been answered and the callid's match, end the call - called when the call is still ringing but the caller has hung up
            if (!this.calling && data.callid === this.callData.callId) {
                this.endCall();
            }
        });
        // End current call and join incoming call
        this.exitCallEnterNewEvent = this.communication.exitVoiceCallEnterNew$.subscribe(() => {
            this.endCall();
        });
    }

    /**
     *  Toggle button from place call to end call
     */

    toggleCall() {
        if (this.calling) {
            this.endCall();
        } else {
            if (!this.modalClosing) {
                this.initializeVoiceCall();
            }
        }
    }

    /**
     * Start voice call with the patient
     */
    initializeVoiceCall() {
        this.calling = true;
        this.callStatus = 'CONNECTING';
        this.ref.detectChanges();
        if (this.callData.callId) {
            this.acceptIncomingCall();
        } else {
            this.initializeOutgoingCall();
        }
    }

    /**
     * Begins outgoing call
     */
    initializeOutgoingCall() {
        this.communication.initializeOutgoingVoiceCall(this.callData.patientHrsId).subscribe(
            {
                next: (res: any) => {
                    this.callData = res;
                    this.connectCall(res);
                },
                error: (err) => {
                    this.calling = false;
                    this.callStatus = 'CALL_FAILED';
                    this.ref.detectChanges();
                    this.logger.phic.error('Error: ', err);
                }
            }
        );
    }

    /**
     * Get incoming voice call token
     */
    acceptIncomingCall() {
        this.communication.acceptIncomingVoiceCall(this.callData.callId).subscribe(
            {
                next: (res: any) => {
                    const data = {callid: this.callData.callId, access: res.access};
                    this.connectCall(data);
                },
                error: (err) => {
                    this.calling = false;
                    this.callStatus = 'CALL_FAILED';
                    this.ref.detectChanges();
                    this.logger.phic.error('Failed to obtain call token');
                }
            }
        );
    }

    /**
     * Connect to voice call
     * @param data
     */
    connectCall(data) {
        let _this = this;
        this.callStatus = 'CALL_CONNECTED';

        Twilio.TwilioVoiceClient.onClientInitialized(() => {
            this.logger.phic.log('Twilio client initialized.');
            let twilioParams = {
                'To': 'conference:' + data.callid,
                'calltype': 'startcall',
            };
            Twilio.TwilioVoiceClient.call(data.access, twilioParams);
        });

        Twilio.TwilioVoiceClient.initialize(data.access);

        // Handle Errors
        Twilio.TwilioVoiceClient.onError((err) => {
            setTimeout(() => {
                _this.calling = false;
                _this.callStatus = 'CALL_FAILED';
                _this.logger.phic.error('Failed (' + err.message + ')');
                _this.ref.detectChanges();
            }, 1000); // to ensure that error is not overwritten by disconnect
        });

        // Handle Call Connection
        Twilio.TwilioVoiceClient.onCallDidConnect((call) => {
            this.logger.phic.log('Successfully established call');
            setTimeout(() => {
                _this.callStatus = 'CALL_CONNECTED';
                _this.ref.detectChanges();
            }, 0);
        });

        // Handle Call Disconnect
        Twilio.TwilioVoiceClient.onCallDidDisconnect((call) => {
            this.logger.phic.log('Call Ended');
            setTimeout(() => {
                _this.calling = false;
                _this.callStatus = 'CALL_ENDED';
                _this.dismiss();
                _this.ref.detectChanges();
            }, 0);
        });

        Twilio.TwilioVoiceClient.onCallInviteReceived(() => {
            setTimeout(() => {
                _this.calling = true;
                _this.callStatus = 'CALL_CONNECTED';
                _this.ref.detectChanges();
            }, 0);
        });
    }

    /**
     * Stop voice call with the patient
     */
    private endCall(): void {
        if (this.platform.is('cordova')) {
            Twilio.TwilioVoiceClient.disconnect();
        }
        this.callStatus = 'CALL_ENDED';
        this.calling = false;
        this.communication.voiceCallLeft(this.callData.callid);
        this.dismiss();
    }

    async dismiss() {
        if (this.calling) {
            // native dialog bc ionic alert would not display over video
            this.dialogs.confirm(
                this.translateService.instant('END_CALL_MESSAGE'),
                this.translateService.instant('END_CALL_TITLE'),
                [
                    this.translateService.instant('END_CALL'),
                    this.translateService.instant('CONTINUE')
                ]
            ).then((e: any)=>{
                // if e is 1 the user clicked End Call
                // e refers to the selections index in the buttonLabels array
                // this index in this case starts at 1
                if (e && e === 1) {
                    this.endCall();
                }
            });
        } else {
            this.modalService.setModalStatus('VoicePage', false);
            this.modalClosing = true;
            this.modalCtrl.dismiss();
        }
    }
}
