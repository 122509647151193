export class ConvertWeight {
    /**
     * @param number pounds
     * @return number
     *
     * Formula:  g = lbs * 453.59237
     */
    static poundsToGrams(pounds: number): number {
        return pounds * 453.59237;
    }

    /**
     * @param number kilograms
     * @return number
     *
     * Formula:  g = kg * 1000
     */
    static kilogramsToGrams(kilograms: number): number {
        return kilograms * 1000;
    }

    /**
     * @param number grams
     * @return number
     *
     * Formula:  lbs = g / 453.59237
     */
    static gramsToPounds(grams: number): number {
        return grams / 453.59237;
    }

    /**
     * @param number grams
     * @return number
     *
     * Formula:  kg = g / 1000
     */
    static gramsToKilograms(grams: number): number {
        return grams / 1000;
    }
}
