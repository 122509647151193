import {Injectable} from '@angular/core';
import {GatewayApi} from '@hrs/providers';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ReviewedStatusService {
    public static REVIEWED: string = 'reviewed';
    public static IN_PROGRESS: string = 'inprogress';
    public static NON_REVIEWED: string = 'unreviewed';

    constructor(
        private gatewayApi: GatewayApi,
        private translateService: TranslateService,
    ) {}

    public popupReviewedStatusClass(reviewedStatus?: string): string {
        if (!reviewedStatus) reviewedStatus = 'ERROR';
        const cssStatusClassMap = {
            [ReviewedStatusService.REVIEWED]: 'toast-risk_low',
            [ReviewedStatusService.IN_PROGRESS]: 'toast-risk_med',
            [ReviewedStatusService.NON_REVIEWED]: 'toast-risk_high',
            ['ERROR']: 'toast-fail'
        };
        return cssStatusClassMap[reviewedStatus];
    }

    public translateReviewedStatus(reviewedStatus: string): string {
        const translatedStatusMap = {
            [ReviewedStatusService.REVIEWED]: this.translateService.instant('REVIEWED_STATUS_REVIEWED'),
            [ReviewedStatusService.IN_PROGRESS]: this.translateService.instant('REVIEWED_STATUS_IN_PROGRESS'),
            [ReviewedStatusService.NON_REVIEWED]: this.translateService.instant('REVIEWED_STATUS_NON_REVIEWED')
        };
        return translatedStatusMap[reviewedStatus];
    }

    public requestedReviewedStatus(currentStatus:string): string {
        // this tool selects the new reviewed status to submit when triggered from the patient list
        const requestedStatusMap = {
            [ReviewedStatusService.REVIEWED]: ReviewedStatusService.IN_PROGRESS,
            [ReviewedStatusService.IN_PROGRESS]: ReviewedStatusService.NON_REVIEWED,
            [ReviewedStatusService.NON_REVIEWED]: ReviewedStatusService.REVIEWED
        };
        return requestedStatusMap[currentStatus];
    }

    public submitRequestedStatus(patientHrsid: string, requestedStatus: string): Observable<any> {
        return this.gatewayApi.post(
            'apiv2/patient/' + patientHrsid + '/profile',
            {reviewedStatus: requestedStatus}
        );
    }
}
