import {MetricEntry} from '@hrs/models';

export class BloodPressureEntry extends MetricEntry {
    public heartrate: number;
    public systolic: number;
    public diastolic: number;
    public map: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.heartrate = entry.heartrate;
        this.systolic = entry.systolic;
        this.diastolic = entry.diastolic;
        this.map = BloodPressureEntry.calculateMAP(entry.systolic, entry.diastolic);
    }

    static calculateMAP(systolic: number, diastolic: number): number {
        const map = Math.round(((2 * diastolic) + systolic) / 3);
        return map;
    }
}
