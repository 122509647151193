import {Injectable} from '@angular/core';
import {GatewayApi} from '@hrs/providers';
import {User} from '../../user/user';
import {
    CareplanUpdatePayload,
    MedicationReminderDetail,
    ReminderTimeAndWindow} from '../../../../common/interfaces/careplan.interface';

@Injectable({
    providedIn: 'root'
})
export class CareplanService {
    // this.modules contains both the conditions (presets) and the metrics (aka modules),
    // which is confusing to have them combined but is unfortunately how the data is stored on the back end.
    public modules: string[];
    // this.moduleInfo contains some fields which seem to be required by the backend to POST, but whose values,
    // or the handling thereof, we have not yet added into the CCM careplan.
    public moduleInfo: CareplanUpdatePayload = {};

    constructor(
        private gatewayApi: GatewayApi,
        private user: User
    ) { }

    public getPatientModules() {
        return this.gatewayApi.get(`v1/modules`, {});
    }

    public getPatientModulesWithReminders(hrsid: string) {
        return this.gatewayApi.get(`apiv2/patients/` + hrsid + `/moduleinfo`, {});
    }

    public getEnvPresets() {
        return this.gatewayApi.get(`v1/environments/${this.user.data.environment}/presets`, {});
    }

    public savePatientModuleInfo(
        hrsid: string,
        modules: string[],
        activityReminders: ReminderTimeAndWindow,
        surveyReminders: ReminderTimeAndWindow,
        weightReminders: ReminderTimeAndWindow,
        medicationReminders: MedicationReminderDetail[]
    ) {
        if (!modules.length) modules = ['empty'];
        this.moduleInfo = {};
        this.moduleInfo['modules'] = modules;
        if (this.moduleInfo['modules'].includes('activity')) {
            this.moduleInfo['activityreminder'] = activityReminders;
        }

        if (this.moduleInfo['modules'].includes('survey')) {
            this.moduleInfo['surveyreminder'] = surveyReminders;
        }

        if (this.moduleInfo['modules'].includes('weight')) {
            this.moduleInfo['weightreminder'] = weightReminders;
        }

        if (this.moduleInfo['modules'].includes('medication')) {
            this.moduleInfo['medicationreminders'] = medicationReminders;
        }

        return this.gatewayApi.post(`apiv2/patients/` + hrsid + `/moduleinfo`, {moduleInfo: this.moduleInfo});
    }
}
