export class ConvertString {
    /**
     * curly - 'smart' - qoutes and apostrophes cause errors when inserting into database
     * database needs those chars to be straight - 'dumb' - chars
     * @param originalString
     * return string with 'smart' chars replaced with 'dumb' chars
     */
    static replaceCurlyChars(originalString: string): string {
        const cleanedString = originalString.replace(/((\u2019)|(\u2018)|(\u201c)|(\u201d))/g, '\u0027');
        return cleanedString;
    }

    /**
     * non utf8 chars cause errors when inserting into database - this may strip out accented chars from different languages
     * @param originalString
     * return string free of non utf8 chars
     */
    static removeNonUtf8Chars(originalString: string): string {
        const cleanedString = originalString.replace(/[^\x20-\x7E]+/g, '');
        return cleanedString;
    }
}
