import { Injectable } from '@angular/core';
import { GatewayApi } from '../api/gateway-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyEntry, SurveyGroupModel, DailySurveyDetailsModel } from '../../models/models';
import * as moment from 'moment';
import {SurveyGroup} from './survey-group.interface';
import {SurveyQuestion} from './survey-question.interface';
import {SurveyScoreType} from './survey-score-type.enum';
import {isNumber} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class SurveyDetailsService {
    private surveyMap: {[key: string]: SurveyEntry};
    private dailySurveyDetails: DailySurveyDetailsModel;

    constructor(private gatewayApi: GatewayApi) {}
    // IONIC UPGRADE had to remove type here temporarily Observable<DailySurveyDetailsModel>

    public getSurveyDetails(hrsid: string, date: string): Observable<DailySurveyDetailsModel> {
        return this.gatewayApi.get(
            `apiv2/patients/${hrsid}/surveydetails/${date}`,
            {}
        ).pipe(map((surveyDetails: any) => {
            this.dailySurveyDetails = new DailySurveyDetailsModel();
            this.dailySurveyDetails.date = moment(date);
            if (surveyDetails && surveyDetails.questions) {
                this.buildSurveyMap(surveyDetails.questions);
                // we know that only answered questions exist in the question map, one entry for each answered question
                this.dailySurveyDetails.answeredCount = Object.keys(this.surveyMap).length;
                this.dailySurveyDetails.grouped = this.parseGroupedSurveys(surveyDetails.groups);
                this.dailySurveyDetails.ungrouped = this.parseUngroupedSurveys();
            }
            return this.dailySurveyDetails;
        }));
    }

    // creates an object that stores the most recent answer for each question id
    private buildSurveyMap(questions: SurveyQuestion[]): void {
        this.surveyMap = {};
        questions.forEach((question: SurveyQuestion) => {
            if (!question.answer) return question;
            const answeredQuestion = new SurveyEntry(question);
            // check that we are including the most recent answered survey in the survey map
            const id = answeredQuestion.id;
            if (this.surveyMap[id]) {
                if (answeredQuestion.date.isAfter(this.surveyMap[id].date)) {
                    this.surveyMap[id] = answeredQuestion;
                }
            } else {
                this.surveyMap[id] = answeredQuestion;
            }
        });
    }

    private parseGroupedSurveys(groups: SurveyGroup[]): SurveyGroupModel[] {
        let groupModels: SurveyGroupModel[] = [];
        if (groups) {
            groups.forEach((group) => {
                let groupModel = new SurveyGroupModel();
                groupModel.name = group.name;
                groupModel.scoreType = group.scoretype;
                groupModel.questions = [];

                group.questionids.forEach((id: number) => {
                    if (this.surveyMap[id]) {
                        groupModel = this.calculateGroupScore(groupModel, id);
                        groupModel.questions.push(this.surveyMap[id]);
                        // mark question as grouped so we don't include it in ungrouped questions
                        this.surveyMap[id].isGrouped = true;
                    }
                });

                groupModel.scoreMessage = this.getGroupScoreText(group, groupModel);

                if (groupModel.questions.length) {
                    groupModels.push(groupModel);
                }
            });
        }

        return groupModels;
    }

    private calculateGroupScore(groupModel: SurveyGroupModel, id: number): SurveyGroupModel {
        if (!groupModel.scoreTotal) {
            groupModel.scoreTotal = 0;
            groupModel.answerCount = 0;
        }

        if (this.surveyMap[id].answer.length > 0) {
            groupModel.answerCount += 1;
        }

        groupModel.scoreTotal += this.surveyMap[id].points;
        // if the question is marked as grouped it has already passed through this code
        if (!this.surveyMap[id].isGrouped) {
            // wrapping this string in an array to maintain type string[];
            this.surveyMap[id].answer = [`${ this.surveyMap[id].answer } (${ this.surveyMap[id].points })`];
        }

        if (groupModel.scoreType === SurveyScoreType.Average) {
            groupModel.averageScore = (groupModel.scoreTotal / groupModel.answerCount).toPrecision(2);
        }

        return groupModel;
    }

    private parseUngroupedSurveys(): SurveyEntry[] {
        let ungroupedQuestions = [];
        Object.keys(this.surveyMap).forEach((id) => {
            if (!this.surveyMap[id].isGrouped) {
                ungroupedQuestions.push(this.surveyMap[id]);
            }
        });

        return ungroupedQuestions;
    }

    private getGroupScoreText(group: SurveyGroup, groupModel: SurveyGroupModel): string {
        let scoreMessage: string;
        if (groupModel.questions.length) {
            if (groupModel.scoreTotal) {
                let scoreDisplay = group.scoredisplay;
                let averageScore = parseInt(groupModel.averageScore)
                let score = isNumber(averageScore) && averageScore !== 0 ? averageScore : groupModel.scoreTotal;
                if (scoreDisplay.length > 0) {
                    for (let i = 0; i < scoreDisplay.length; i++) {
                        if (score >= parseInt(scoreDisplay[i].min) && score <= parseInt(scoreDisplay[i].max)) {
                            scoreMessage = scoreDisplay[i].text ? `(${scoreDisplay[i].text})` : undefined;
                        }
                    }
                }
            }
        }
        return scoreMessage;
    }
}

