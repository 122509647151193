
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GatewayApi} from '@hrs/providers';
import {Observable} from 'rxjs';
// import { NewPatient } from '../../pages/add-patient/new-patient.model';
import {PatientSettingsResponse} from '@clinician/models';
import {UntypedFormGroup} from '@angular/forms';
import {ActivationStatus} from '../../enums';

/**
 * This is the service provider for the patient list API calls
 */
@Injectable({
    providedIn: 'root',
})
export class PatientProfileService {
    patientListLoaded;

    constructor(
        public gatewayApi: GatewayApi,
        public http: HttpClient
    ) {}

    /**
     * Call api to get the patient profile
     */

    getPatientProfile(hrsid) {
        return this.gatewayApi.get(
            'apiv2/patient/' + hrsid + '/profile',
            {}
        );
    }

    /**
     * Call api to get the patient current metrics
     */

    getPatientCurrentMetrics(hrsid) {
        return this.gatewayApi.get(
            'apiv2/patients/' + hrsid,
            {}
        );
    }

    /**
     * Call api to get the surveys
     */

    getSurveyQuestionAndAnswerMetric(hrsid, currentDate) {
        return this.gatewayApi.get(
            'apiv2/patients/' + hrsid + '/surveydetails/' + currentDate,
            {}
        );
    }

    /**
     * Call api to mark the patient as reviewed
     */

    markPatientReviewed(patient) {
        return this.gatewayApi.post(
            'apiv2/patient/' + patient.hrsid + '/profile',
            {review: true}
        );
    }

    /**
     * Call api to mark the patient as not reviewed
     */

    markPatientUnreviewed(patient) {
        return this.gatewayApi.post(
            'apiv2/patient/' + patient.hrsid + '/profile',
            {review: false}
        );
    }

    /**
     * Call api to save or edit the quick note with today's date
     */

    saveQuickNote(detail, quicknote) {
        return this.gatewayApi.post(
            'apiv2/patient/' + detail.hrsid + '/profile',
            {'quicknote': {'note': quicknote}}
        );
    }

    resetPatientMetrics(hrsId, metrics) {
        return this.gatewayApi.post(
            'apiv2/patient/' + hrsId + '/metrics/reset', metrics
        );
    }

    changePatientStatus(
        action: string,
        hrsId: string,
        data: UntypedFormGroup
    ) {
        if (action === ActivationStatus.ActionUnDischarge) {
            return this.gatewayApi.delete('apiv2/patients/' + hrsId + '/discharge', data);
        }
        // The end point does not accept 'resume' as a param. It will return 'pre-activated' for all resume and reactivate events.
        // For 'resume' events the patient's status will persist as 'activated' locally and the in the DB regardless of what gets returned
        // from the request. https://healthrecoverysolutions.atlassian.net/browse/JIR-5387
        if (action == ActivationStatus.ActionResume) {
            action = ActivationStatus.ActionReactivate;
        }
        return this.gatewayApi.post('apiv2/patients/' + hrsId + '/' + action, data);
    }

    public addPatient(newPatientData: any): Observable<any> {
        return this.gatewayApi.post('apiv2/patient', newPatientData);
    }

    public savePatientSettings(hrsid: string, data: PatientSettingsResponse): Observable<any> {
        return this.gatewayApi.post(
            'apiv2/patient/' + hrsid + '/profile',
            JSON.stringify(data)
        );
    }
}
