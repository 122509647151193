import * as moment from 'moment';

/**
 * @route GET /patient/{hrsid}/profile/v2
 */

export class PatientSettingsResponse {
    public gender: string;
    public dob: string;
    public PATIENTINFO_CUSTOMATTRIBUTES: {[key: string]: any}[];
    public alternaterelationship: string;
    public alternatetelephone: string;
    public alternatelastname: string;
    public alternatemiddlename: string;
    public alternatefirstname: string;
    public audioreminders: boolean;
    public volume: number;
    public language: string;
    public lasthospitalization: string | Date;
    public testpatient: any | string;
    public subgroup: any | ((pn: string) => string) | string;
    public phone: string;
    public name: {first: string, middle: string, last: string};
    public pid: string;
    public timezone: string;

    constructor(response) {
        this.pid = response.data.pid;
        this.name = response.data.name;
        this.gender = response.data.gender;
        this.dob = (response.data.dob ? moment(response.data.dob, 'MM-DD-YYYY').format('MM/DD/YYYY') : null);
        this.phone = response.data.phone;
        this.subgroup = response.data.subgroup;
        this.testpatient = response.data.testpatient ? 'Yes' : 'No';
        this.language = response.data.language;
        this.volume = response.data.volume;
        this.audioreminders = response.data.audioreminders;
        this.alternatefirstname = response.data.alternatefirstname;
        this.alternatemiddlename = response.data.alternatemiddlename;
        this.alternatelastname = response.data.alternatelastname;
        this.alternatetelephone = response.data.alternatetelephone;
        this.alternaterelationship = response.data.alternaterelationship;
        this.PATIENTINFO_CUSTOMATTRIBUTES = response.data.PATIENTINFO_CUSTOMATTRIBUTES;
        this.lasthospitalization = this.formatLastHospitalization(response);
        this.timezone = response.data.timezone;
    }

    formatLastHospitalization(response): string {
        // Last Hospitalization timestamp varies between milliseconds and seconds creating issues
        // Convert all TimeStamp values to milliseconds
        let lastHospitalization = response.data.lasthospitalization < 10000000000 &&
        typeof response.data.lasthospitalization === 'number' ?
            response.data.lasthospitalization * 1000 :
            response.data.lasthospitalization;

        return response.data.lasthospitalization = lastHospitalization ?
            moment(new Date(lastHospitalization)).format('MM/DD/YYYY') : null;
    }
}
