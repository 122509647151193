import {Injectable} from '@angular/core';
import {GatewayService} from '@hrs/gateway';
import {map, share} from 'rxjs/operators';
import {Observable, zip} from 'rxjs';
import {ExpandedPatient} from '../../../common/interfaces/expanded-patient.interface';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {getLogger} from '@hrs/logging';

class RequestObservable {
    next: (value) => void;
    observable: Observable<any[]>;
    response: any[] = [];

    constructor() {
        this.observable = new Observable((observer) => {
            this.next = (value) => observer.next(value);
        });
    }
}

/**
 * This is the service provider for the patient list API calls
 */
@Injectable({
    providedIn: 'root',
})
export class PatientListService {
    private readonly logger = getLogger('PatientListService');
    lastPageRequested: number = 0;
    finalPage: number;
    patientListRequests: {[key: number]: RequestObservable} = {};
    patientListRequestObservable: Observable<any>;
    patientListRequestError: boolean;

    constructor(
        public gateway: GatewayService,
        public toastCtrl: ToastController,
        public translate: TranslateService
    ) {}

    /**
     * Set up and return patient list request observables
     */
    loadPatients(): Observable<any> {
        // If we were already loading the patient list we'll just continue waiting for that to complete
        if (this.patientListRequestObservable) {
            return this.patientListRequestObservable;
        }

        // Load the patient list in batches using X parallel requests that each keep requesting another batch until we've loaded all patients.
        // A batched approach was needed because some environments have too much data for the old backend behind this to handle in one batch.
        let observables = [];
        for (let requestKey = 1; requestKey <= 5; requestKey++) {
            this.patientListRequests[requestKey] = new RequestObservable();
            observables.push(this.patientListRequests[requestKey].observable);
            this.loadPatientListPage(++this.lastPageRequested, requestKey);
        }

        // merge all the request observables into one stream and make it shareable to patientlist and locally
        this.patientListRequestObservable = zip(...observables)
            .pipe(
                map((patientList: Observable<void>[]) => patientList.flat(1)),
                share()
            );

        const resetVariables = () => {
            setTimeout( () => {
                this.finalPage = undefined;
                this.lastPageRequested = 0;
                this.patientListRequests = {};
                this.patientListRequestObservable = null;
                this.patientListRequestError = false;
            }, 500);
        };

        this.patientListRequestObservable.subscribe({
            // The only local behavior required once all the observables have completed is to reset the local variables
            // And notify user if there were any request errors
            next: () => {
                if (this.patientListRequestError) this.alertUser();
                resetVariables();
            }
        });

        return this.patientListRequestObservable;
    }

    /**
     * handle patient list pagination requests and response interactions with observables
     */
    loadPatientListPage(page: number, requestKey: number): void {
        const assignFinalPage = (response: any) => {
            if (
                !this.finalPage &&
                response &&
                response.meta &&
                response.meta.pagination
            ) {
                this.finalPage = response.meta.pagination.last_page || 0;
            }
        };

        const loadNextPage = () => {
            let nextPage = this.lastPageRequested + 1;

            if (nextPage <= this.finalPage) {
                this.loadPatientListPage(++this.lastPageRequested, requestKey);
            } else {
                // Each of the 5 patientListRequest Observables will trigger 'next' only one time. When all 5 have done that
                // patientListRequestObservable (which the patient-list page subscribes to) will trigger 'next' one time
                // and pass all responses as one array regardless of any errors an individual or all requests encountered
                this.patientListRequests[requestKey].next(this.patientListRequests[requestKey].response);
            }
        };

        this.getPatientList(page)
            .subscribe({
                next: (response: any) => {
                    assignFinalPage(response);
                    // Concat the patient list responses together, once all requests are finished the sum of the responses will be passed to patient-list.page
                    this.patientListRequests[requestKey].response = [...this.patientListRequests[requestKey].response, ...response.patientlist];
                },
                error: (err: Error) => {
                    // The Error is consumed here and does not bubble up to the wrapping observable patientListRequestObservable
                    // Bubbling the error up will prevent desired behavior and will result in a never ending loading spinner
                    this.patientListRequestError = true;
                    this.logger.phic.error('Error loading patientlist page ' + page, err);
                    loadNextPage();
                },
                complete: () => loadNextPage()
            });
    }

    /**
     * Call api to get the patient list
     */
    getPatientList(page: number, pageSize: number = 500): Observable<any> {
        const path = `apiv2/patientlist?status[]=Activated&status[]=Pre-Activated&status[]=Paused&status[]=Discharged&page[current]=${page}&page[size]=${pageSize}`;
        return this.gateway.get({path: path});
    }

    /**
     * Simply alert user if any of the patientlist requests errored out
     */
    async alertUser(): Promise<void> {
        let toast = await this.toastCtrl.create({
            message: this.translate.instant('PATIENT_LIST_FAIL'),
            cssClass: 'toast-fail',
            duration: 3000,
            position: 'top'
        });
        return toast.present();
    }

    /**
     * used by patient-list.page to keep track of which patient is expanded
     */
    expandedPatients(): ExpandedPatient {
        return {
            patient: undefined,
            includes: function(patient) {
                return this.patient && patient.hrsid === this.patient.hrsid;
            },
            isExpanded: function() {
                return !!this.patient;
            },
            set: function(patient) {
                patient.expanded = true;
                this.patient = patient;
            },
            unset: function() {
                this.patient.expanded = false;
                this.patient = undefined;
            }
        };
    }
}
