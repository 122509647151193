import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TokenService} from '../token/token.service';
import {Subject, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {GatewayResponse} from '@hrs/gateway';
import {environment} from '@app/env';
import {BroadcastService} from '../broadcastService';
import {getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
    private readonly logger = getLogger('ApiInterceptor');
    private _refreshing: boolean = false;
    private _refreshTokenSubject: Subject<any> = new Subject<any>();
    private authErrorStartTime: number;

    constructor(
        public broadcastService: BroadcastService,
        private tokenService: TokenService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isGatewayRequest = request.url.indexOf(environment.API_GATEWAY_URL) > -1;
        const isTokenRequest = isGatewayRequest && request.url.indexOf('/tokens') > -1;

        const verboseLogging = isTokenRequest;
        const shouldIntercept = isGatewayRequest && request.headers.get('Authorization') && !isTokenRequest;

        // Create a local copy of sessionId so that we can check it upon response/error and avoid broadcasting to logout if the app has already logged out or in while this request was pending.
        const sessionId = this.tokenService.sessionId;

        if (verboseLogging) {
            this.log('Intercepted request', {request: request});
        }

        if (shouldIntercept) {
            // Giving a 45 second window for interceptor expiration
            let expiration = this.tokenService.getExp();
            let now = Math.floor(Date.now() / 1000);
            if ((expiration - 45) < now) {
                if (this.tokenService.refresh) {
                    this.log('Token has expired', {request: request, expiration: expiration, now: now});
                    return this.handleOldToken(request, next, verboseLogging, sessionId);
                } else {
                    this.log('Token has expired but there is no refresh code', {request: request, expiration: expiration, now: now}, true);
                }
            }
        }

        return next.handle(request)
            .pipe(
                catchError(
                    (error: HttpResponse<any>, caught: Observable<HttpEvent<any>>) => {
                        if (verboseLogging) {
                            this.log('Intercepted error', {request: request, error: error}, true);
                        }

                        if (error.status === 406 || error.status === 402 || error.status === 403) {
                            this.log('Error code: ' + error.status, {request: request, error: error}, true);
                            if (shouldIntercept && !this.tokenService.isRetryingToken && this.tokenService.sessionId === sessionId) {
                                this.handleMiscAuthError(error);
                            }
                        }

                        if (error.status === 401 && shouldIntercept && !this.tokenService.isRetryingToken) {
                            this.log('Got unauthorized error', {request: request, error: error}, true);
                            if (this.tokenService.sessionId === sessionId) {
                                this.handleAuthError(error);
                            }
                            return of(error);
                        }
                        throw error;
                    }
                ),
                map((response: HttpResponse<any>) => {
                    if (response.type === HttpEventType.Response) {
                        if (shouldIntercept) {
                            this.authErrorStartTime = null;
                        }
                        if (verboseLogging) {
                            this.log('Intercepted response', {request: request, response: response});
                        }
                    }
                    return response;
                })
            );
    }

    private handleOldToken(request: HttpRequest<any>, next: HttpHandler, verboseLogging: boolean, sessionId: number): any {
        const doRequest = (req: HttpRequest<any>): Observable<HttpEvent<any>> => {
            this.log('Resumed request after token refresh', {request: request});

            return next.handle(req)
                .pipe(
                    catchError(
                        (error: any, caught: Observable<HttpEvent<any>>) => {
                            if (verboseLogging) {
                                this.log('Intercepted error from queued request', {request: request, error: error}, true);
                            }

                            if (error.status === 406 || error.status === 402 || error.status === 403) {
                                this.log('Error code from queued request: ' + error.status, {request: request, error: error}, true);
                                if (!this.tokenService.isRetryingToken && this.tokenService.sessionId === sessionId) {
                                    this.handleMiscAuthError(error);
                                }
                            }

                            if (error.status === 401 && !this.tokenService.isRetryingToken) {
                                this.log(`Got unauthorized error from queued request`, {request: request, error: error}, true);
                                if (this.tokenService.sessionId === sessionId) {
                                    this.handleAuthError(error);
                                }
                                return of(error);
                            }
                            throw error;
                        }
                    ),
                    map((response: HttpResponse<any>) => {
                        if (response.type === HttpEventType.Response) {
                            this.authErrorStartTime = null;
                            if (verboseLogging) {
                                this.log('Intercepted response from queued request', {request: request, response: response});
                            }
                        }
                        return response;
                    })
                );
        };

        if (!this._refreshing) {
            this._refreshing = true;
            this.log('Refreshing token', {request: request});

            return this.tokenService.refreshToken().pipe(
                catchError((error: any, caught: Observable<HttpEvent<any>>) => {
                    this.log('Token refresh failed', {request: request, error: error}, true);
                    // If the refresh failed, just return the token we already had. We'll probably end up unauthorized with our API, but at least the frontend logic doesn't have to account for a missing token or anything.
                    return of({
                        data: {
                            attributes: {
                                token: this.tokenService.token,
                                refresh: this.tokenService.refresh
                            }
                        }
                    });
                }),
                switchMap((tokens: any) => {
                    this.log('Token refresh completed', {request: request});
                    this._refreshing = false;

                    // Make the original request now that we refreshed our token
                    let result = doRequest(this.addNewTokenData(request, tokens));

                    // Also make any other requests that were waiting for us to refresh our token
                    this._refreshTokenSubject.next(tokens);

                    return result;
                }));
        } else {
            // Another call happened around the same time as this one and already started refreshing our token, so we just have to wait for that one to get the new token.
            this.log('Pausing request while token refresh in progress', {request: request});
            return this._refreshTokenSubject.pipe(
                switchMap((tokens: any) => {
                    return doRequest(this.addNewTokenData(request, tokens));
                }));
        }
    }

    private addNewTokenData(request: HttpRequest<any>, tokens: any): HttpRequest<any> {
        let jwt = tokens.data.attributes.token;

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${jwt}`,
            }
        });
    }

    private handleMiscAuthError(rejection: GatewayResponse<any>) {
        const currentTime = new Date().getTime();
        if (!this.authErrorStartTime) {
            this.authErrorStartTime = currentTime;
        } else {
            const atLeast5MinutesWithoutSuccess = currentTime - this.authErrorStartTime >= 5 * 60 * 1000;
            if (atLeast5MinutesWithoutSuccess) {
                this.broadcastService.miscAuthError.next(rejection);
            }
        }
    }

    private handleAuthError(rejection: GatewayResponse<any>) {
        // Kick the user out to the login page since their session is no longer valid
        // Passing in the rejection object to display information to the user about what happened
        this.broadcastService.interceptorLogout.next(rejection);
    }

    private log(message: any, context: any, error = false) {
        let contextCopy = JSON.parse(JSON.stringify(context));
        if (contextCopy && contextCopy.request && contextCopy.request.body && contextCopy.request.body.data && contextCopy.request.body.data.password) {
            contextCopy.request.body.data.password = 'REDACTED';
        }
        if (error) {
            this.logger.phic.error(message, contextCopy);
            return;
        }
        this.logger.phic.debug(message, contextCopy);
    }
}

