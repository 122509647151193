import {ErrorHandler, Injectable} from '@angular/core';
import {HRSLogger, getLogger} from '@hrs/logging';

@Injectable({
    providedIn: 'root'
})
export class HRSAppErrorHandler extends ErrorHandler {
    private readonly logger: HRSLogger = getLogger('HRSAppErrorHandler');
    private mErrorCounter: number = 0;

    public handleError(error: any): void {
        this.mErrorCounter++;
        try {
            this.logger.fatal('=============================================================');
            this.logger.fatal(`      !!!!!!!! FATAL (${this.mErrorCounter}) !!!!!!!!`);
            this.logger.fatal('=============================================================');
            this.logger.fatal('>>> raw error', error);
            this.logger.fatal(`>>> error message: ${error && error.message}`);
            this.logger.fatal(`>>> error stack: ${error && error.stack}`);
            this.logger.fatal(`<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<`);
        } catch (metaError) {
            // Should never happen, but adding in anyway because paranoia
            this.logger.fatal(`!!!!!!!! CATASTROPHIC !!!!!!!!`, metaError);
        }
    }
}
