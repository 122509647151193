export enum ActivationStatus {
     ActionDeactivate = 'deactivate',
     ActionDischarge = 'discharge',
     ActionUnDischarge = 'undischarge',
     ActionReactivate = 'reactivate',
     ActionResume = 'resume',
     ActionPause = 'pause',

     StatusActivated = 'activated',
     StatusDeactivated = 'deactivated',
     StatusPreActivated = 'preactivated',
     StatusPaused = 'paused',
     StatusDischarged = 'discharged'
}
