import {Component} from '@angular/core';
import {AdminService, User} from '@clinician/providers';
import {AdminSettings} from '../enums';
import {Setting} from './admin-settings.interface';
import {TranslateService} from '@ngx-translate/core';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'hrs-admin',
    templateUrl: 'admin.page.html',
    styleUrls: ['admin.page.scss']
})

export class AdminPage {
    adminSettings = AdminSettings;
    hasCorrectAccess: boolean = false;
    selectedSetting: Setting;
    settings: Setting[];

    /**
     * To add a new tablet setting:
     * add settings object to this.settings which contains an enim which points to the AdminSettings enum,
     * add the component to admin.module declarations, do not add to app.module,
     * add the component instantiation to this template's ng-container#settingsPanel
     */
    constructor(
        private translate: TranslateService,
        private adminService: AdminService,
        private navCtrl: NavController,
        public user: User
    ) {
        this.settings = this.adminService.settings;
        this.selectSetting(this.settings[0]);
    }

    hasCorrectPassword(isCorrect: boolean): void {
        this.hasCorrectAccess = isCorrect;
    }

    selectSetting(setting: Setting): void {
        this.selectedSetting = setting;
    }

    exitAdmin(): void {
        this.navCtrl.back({animated: false});
    }
}
