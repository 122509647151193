import {PatientMetricResponse} from '../interfaces/patient-metric-response.interface';

export class Patient {
    // Patient Properties
    activity?: Activity;
    audioreminders?: boolean;
    bloodpressure?: BloodPressure;
    byodDevice?: boolean;
    clinician: Clinician;
    conditions: string[];
    device?: boolean;
    edvisit?: number;
    envphone?: string;
    expanded: boolean;
    extension: string;
    gender: string;
    hrsid: string;
    language: string;
    lastmoduleupdate?: number;
    medication: object[];
    name: Name;
    pid: string;
    pulseox: number[];
    readmission: number;
    reviewLoading?: boolean;
    reviewedState?: string;
    reviewed?: any[];
    risk: object[];
    riskText?: string;
    startdate: number;
    status: string;
    survey?: Survey;
    temperature: number[];
    volume: number;
    weight: number[];
    woundimaging: WoundImaging[];
    metrics: PatientMetricResponse;
    lastactive?: number;
    discharged?: string;

    // Conditions Variables
    conditionsString;

    constructor(data?: Partial<Patient>) {
        this.set(data);
        // Quick and dirty extend/assign fields to this model
        for (const d in data) {
            // I never see conditions return an array like ['empty'] but i heard its a thing
            // doing this just in case I am wrong, I<3apiv2
            if ( d === 'conditions' && data.conditions.length > 0 && data.conditions[0] !== 'empty') {
                // creates a string of conditions separated with a comma and space
                this.conditionsString = data[d].join(', ');
            }
        }
    }

    set(data: Partial<Patient>) {
        Object.assign(this, data);
    }
}

interface Name {
    first: string;
    last: string;
}

interface Clinician {
    name: string;
    hrsid: string;
}

interface Survey {
    questions: object[]
    time: number;
    window: number;
}

interface Activity {
    data?: ActivityData[],
    duration: number,
    goal: number,
    ts: number
}

interface ActivityData {
    duration: number,
    id: string,
    type: string,
    ts: number,
    status: string
}

interface WoundImaging {
    id: string,
    ts: number,
    status: string
}

interface BloodPressure {
    data?: BloodPressureData[],
    diastolic: number,
    heartrate: number,
    id: string,
    status: string,
    systolic: number,
    ts: number
    type: string
}

interface BloodPressureData {
    diastolic: number,
    heartrate: number,
    id: string,
    status: string,
    systolic: number,
    ts: number,
    type: string
}
