import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ResetMetricsPage} from './reset-metrics.page';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: 'reset-metrics',
                component: ResetMetricsPage
            }
        ]),
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ResetMetricsPage
    ],
    schemas: [NO_ERRORS_SCHEMA],
})
export class ResetMetricsPageModule {}
