/**
 * Base class that can be extended by models
 *
 * Provides an implementation of publish-subscribe messaging pattern, but with short-circuiting in case an event has
 * already completed so that subscribers get an immediate response.
 * Unsubscribe also happens automatically on publish unless setFlag optional parameter 'closed' is set to false.
 */
export abstract class ModelWithEvents {
    // Override this in child classes to provide a list of the event names
    public events: {
        [event: string]: string
    } = {};

    private flags: {
        [event: string]: boolean
    } = {};

    private subscribers: {
        [event: string]: ((model: ModelWithEvents) => void)[]
    } = {};

    public getFlag(event: string): boolean {
        return !!this.flags[event];
    }

    public setFlag(event: string, value: boolean = true, closed?: boolean): void {
        if (!this.hasEvent(event)) return;

        this.flags[event] = value;
        if (value) {
            this.publish(event, closed);
        }
    }

    public subscribe(event: string, callback: (model: ModelWithEvents) => void): () => void {
        if (!this.hasEvent(event)) return () => {};

        if (this.getFlag(event)) {
            // The event had already completed, so we can callback immediately and return a dummy unsubscriber function
            callback(this);
            return () => {};
        } else {
            if (!this.subscribers[event]) {
                this.subscribers[event] = [];
            }
            const callbacks = this.subscribers[event];
            const index = callbacks.length;
            callbacks.push(callback);
            return () => {
                delete callbacks[index];
            };
        }
    }

    private publish(event: string, closed: boolean = true): void {
        const callbacks = this.subscribers[event];
        if (!callbacks) return;

        for (let i = 0; i < callbacks.length; i++) {
            let callback = callbacks[i];
            if (callback) {
                callback(this);
                if (closed) delete callbacks[i];
            }
        }
    }

    private hasEvent(event: string) {
        return this.events.hasOwnProperty(event);
    }
}
