<ion-header>
    <ion-toolbar class="c_app_header is-modal statusbar-padding">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title"
                   *ngIf="action == status.ActionPause">
            <span class="c_app_header--text">
                {{ 'PATIENT_PAUSE.TITLE' | translate }}
            </span>
        </ion-title>
        <ion-title class="c_app_header--title"
                   *ngIf="action == status.ActionResume" >
            <span class="c_app_header--text">
                {{ 'PATIENT_RESUME.TITLE' | translate }}
            </span>
        </ion-title>
        <ion-title class="c_app_header--title"
                   *ngIf="action == status.ActionDeactivate">
            <span class="c_app_header--text">
                {{ 'PATIENT_DEACTIVATE.TITLE' | translate }}
            </span>
        </ion-title>
        <ion-title class="c_app_header--title"
                   *ngIf="action == status.ActionReactivate" >
            <span class="c_app_header--text">
                {{ 'PATIENT_REACTIVATE.TITLE' | translate }}
            </span>
        </ion-title>
        <ion-title class="c_app_header--title ion-text-wrap"
                   *ngIf="action == status.ActionDischarge"
                   no-lines>
            <span class="c_app_header--text">
                {{ 'PATIENT_DISCHARGE.TITLE' | translate }}
            </span>
        </ion-title>
        <ion-title class="c_app_header--title ion-text-wrap"
                   *ngIf="action == status.ActionUnDischarge"
                   no-lines>
            <span class="c_app_header--text">
                {{ 'PATIENT_UNDISCHARGE.TITLE' | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="action == status.ActionPause">
    <ion-card>
        <form [formGroup]="data" (ngSubmit)="submit()">
            <ion-list>
                <ion-item>
                    <ion-label>{{ 'PATIENT_PAUSE.FORM.NOTE' | translate }}:</ion-label>
                    <ion-textarea formControlName="note" placeholder="{{ 'PATIENT_PAUSE.FORM.NOTE_PLACEHOLDER' | translate }}"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label>{{ 'PATIENT_PAUSE.FORM.EDVISIT' | translate }}</ion-label>
                    <ion-checkbox formControlName="edvisit"></ion-checkbox>
                </ion-item>

                <ion-item>
                    <ion-label>{{ 'PATIENT_PAUSE.FORM.READMITTED' | translate }}</ion-label>
                    <ion-checkbox formControlName="readmitted"></ion-checkbox>
                </ion-item>

                <div class="ion-padding">
                    <ion-button expand="block" slot="end" type="submit">
                        <ion-text>{{ 'PATIENT_PAUSE.SUBMIT_BUTTON' | translate }}</ion-text>
                    </ion-button>
                </div>
            </ion-list>
        </form>
    </ion-card>
</ion-content>

<ion-content *ngIf="action == status.ActionResume">
    <ion-card>
        <form [formGroup]="data" (ngSubmit)="submit()">
            <ion-list>
                <ion-item>
                    <ion-label>{{ 'PATIENT_RESUME.FORM.NOTE' | translate }}:</ion-label>
                    <ion-textarea formControlName="note" placeholder="{{ 'PATIENT_RESUME.FORM.NOTE_PLACEHOLDER' | translate }}"></ion-textarea>
                </ion-item>

                <div class="ion-padding">
                    <ion-button expand="block" slot="end" type="submit">
                        <ion-text>{{ 'PATIENT_RESUME.SUBMIT_BUTTON' | translate }}</ion-text>
                    </ion-button>
                </div>
            </ion-list>
        </form>
    </ion-card>
</ion-content>

<ion-content *ngIf="action == status.ActionDeactivate" class="c_patient_status--deactivate">
    <ion-card>
        <form [formGroup]="data" (ngSubmit)="submit()">
            <ion-list>
                <ion-item>
                    <ion-label>{{ 'PATIENT_DEACTIVATE.FORM.NOTE' | translate }}:</ion-label>
                    <ion-textarea formControlName="note" placeholder="{{ 'PATIENT_DEACTIVATE.FORM.NOTE_PLACEHOLDER' | translate }}"></ion-textarea>
                </ion-item>

                <ion-item>
                    <ion-label>{{ 'PATIENT_DEACTIVATE.FORM.EDVISIT' | translate }}</ion-label>
                    <ion-checkbox formControlName="edvisit"></ion-checkbox>
                </ion-item>

                <ion-item>
                    <ion-label>{{ 'PATIENT_DEACTIVATE.FORM.READMITTED' | translate }}</ion-label>
                    <ion-checkbox formControlName="readmitted"></ion-checkbox>
                </ion-item>

                <div class="ion-padding">
                    <ion-button expand="block" slot="end" type="submit">
                        <ion-text>{{ 'PATIENT_DEACTIVATE.SUBMIT_BUTTON' | translate }}</ion-text>
                    </ion-button>
                </div>
            </ion-list>
        </form>
    </ion-card>
</ion-content>

<ion-content *ngIf="action == status.ActionReactivate">
    <ion-card>
        <form [formGroup]="data" (ngSubmit)="submit()">
            <ion-list>
                <ion-item>
                    <ion-label>{{ 'PATIENT_REACTIVATE.FORM.NOTE' | translate }}:</ion-label>
                    <ion-textarea formControlName="note" placeholder="{{ 'PATIENT_REACTIVATE.FORM.NOTE_PLACEHOLDER' | translate }}"></ion-textarea>
                </ion-item>

                <div class="ion-padding">
                    <ion-button expand="block" slot="end" type="submit">
                        <ion-text>{{ 'PATIENT_REACTIVATE.SUBMIT_BUTTON' | translate }}</ion-text>
                    </ion-button>
                </div>
            </ion-list>
        </form>
    </ion-card>
</ion-content>

<ion-content *ngIf="action == status.ActionDischarge">
    <ion-card>
        <ion-card-header class="patient-discharge-info">{{ 'PATIENT_DISCHARGE.INFO' | translate }}</ion-card-header>
        <form [formGroup]="data" (ngSubmit)="submit()">
            <ion-item>
                <ion-label position="stacked">{{ 'PATIENT_DISCHARGE.INPUT.PLACEHOLDER' | translate }}</ion-label>
                <ion-datetime name="date"
                              formControlName="date"
                              display-format="MM/DD/YYYY"
                              picker-format="MM DD YYYY"
                              placeholder="{{ 'PATIENT_DISCHARGE.INPUT.PLACEHOLDER' | translate }}"

                ></ion-datetime>
            </ion-item>
            <div class="ion-padding">
                <ion-button expand="block" slot="end" type="submit" [disabled]="data.value.date === ''">
                    <ion-text>{{ 'PATIENT_DISCHARGE.SUBMIT_BUTTON' | translate }}</ion-text>
                </ion-button>
            </div>
        </form>
    </ion-card>
</ion-content>

<ion-content *ngIf="action == status.ActionUnDischarge">
    <ion-card>
        <ion-card-header class="patient-discharge-info">{{undischargeInfo}}</ion-card-header>
        <div class="c_patient_status--buttons ion-padding">
            <ion-button expand="block" (click)="dismiss()">
                <ion-text>{{ 'PATIENT_UNDISCHARGE.CANCEL_BUTTON' | translate }}</ion-text>
            </ion-button>
            <ion-button expand="block" (click)="submit()">
                <ion-text>{{ 'PATIENT_UNDISCHARGE.SUBMIT_BUTTON' | translate }}</ion-text>
            </ion-button>
        </div>
    </ion-card>
</ion-content>
