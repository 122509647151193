import {Injectable} from '@angular/core';
import {DailyMetricsModel} from '../../../common/models/daily-metrics.model';
import {GatewayApi} from '@hrs/providers';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HistoricalDataService {
    constructor(
      private gatewayApi: GatewayApi
    ) {}

    public getLatest7Days(hrsid: string): Observable<DailyMetricsModel[]> {
        const latest7DaysRange = {
            start: moment().subtract(6, 'd').format('YYYYMMDD'),
            end: moment().format('YYYYMMDD')
        };
        return this.getHistoricalDateRange(hrsid, latest7DaysRange);
    }

    public getNext7Days(hrsid: string): Observable<DailyMetricsModel[]> {
        const start = moment().subtract(7, 'd');
        const next7DaysRange = {
            end: start.format('YYYYMMDD'),
            start: moment(start).subtract(6, 'd').format('YYYYMMDD')
        };
        return this.getHistoricalDateRange(hrsid, next7DaysRange);
    }

    public getHistoricalDateRange(hrsid: string, range: DateRange): Observable<DailyMetricsModel[]> {
        return this.gatewayApi.get(`apiv2/patients/${hrsid}?&start=${range.start}&end=${range.end}`, {})
            .pipe(map((res: any) => {
                let historicalData = [];
                for (let i = 0; i < res.length; i++) {
                    historicalData.push(DailyMetricsModel.init(res[i]));
                }
                return historicalData;
            }
            ));
    }
}

interface DateRange {
    start: string; // YYYYMMDD
    end: string // YYYYMMDD
}
