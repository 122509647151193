import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MenuController} from '@ionic/angular';
import {Setting} from '../../admin/admin-settings.interface';
import {AdminSettings} from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    static PASSCODE: string = '41213';
    adminSettings = AdminSettings;
    settings: Setting[];
    private totalButtonClicks: number = 0;
    private openAdminTimeout: ReturnType<typeof setTimeout>;

    constructor(
        private menu: MenuController,
        private router: Router,
    ) {
        // each 'Setting' represents a nav item for the admin area
        this.settings = [
            {
                fullLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_FULL',
                tabLabel: 'ADMIN_DOMAIN_SETTINGS_LABEL_TAB',
                icon: 'earth-outline',
                enum: this.adminSettings.DOMAIN
            }
        ];
    }

    /**
     * If the device used is a tablet - access to the admin is granted thru a 'secret' button
     * located in the menu at the version number - 5 clicks are required to open admin
     * if 2 seconds passes between a click the counter is reset
     */
    openAdmin(): void {
        this.totalButtonClicks += 1;
        clearTimeout(this.openAdminTimeout);
        if (this.totalButtonClicks === 5) {
            this.totalButtonClicks = 0;
            this.router.navigate(['admin']);
            this.menu.isOpen().then((isOpen) => {
                if (isOpen) this.menu.close();
            });
        } else {
            this.openAdminTimeout = setTimeout(() => this.totalButtonClicks = 0, 2000);
        }
    }

    rerouteHome(): void {
        this.router.navigate(['home']);
    }
}
