<div class="c_admin_access">
    <hrs-form-item
        [hasInvalidInput]="hasError"
        [errorMessage]="errorMessage">
        <hrs-label
            class="c_admin_access--label"
            slot="start">
            {{ 'ADMIN_ACCESS_HEADER' | translate }}
        </hrs-label>
        <hrs-input
                class="c_admin_access--input"
                type="text"
                inputmode="numeric"
                maxlength="5"
                placeholder="{{ 'ADMIN_PASSCODE_PLACEHOLDER' | translate }}"
                [(ngModel)]="enteredPasscode"
                (ngModelChange)="passcodeChangeHandler()"
        ></hrs-input>
    </hrs-form-item>
</div>

