import {Injectable} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Device} from '@ionic-native/device/ngx';
import {GatewayApi} from '@hrs/providers';
import {Platform} from '@ionic/angular';
import {GatewayService} from '@hrs/gateway';

/*
  The main goal of this class is to provider a custom header field for all requests it should look like
  "hrs-user-agent": "ClinicianConnectMobile/1.23.23 UUID/1231201231-123-123123j123lj23 iOS/12.01"
*/
@Injectable({
    providedIn: 'root',
})
export class UserAgentProvider {
  private _appName: string;
  private _appVersionNumber: string;
  private _uuid: string;
  private _osName: string;
  private _osVersion: string;
  private _hrsUserAgentHeader: string;
  private _fcmVersion = 'FCM/v1';
  private _nativeBridge = 'NativeBridge/Capacitor';

  constructor(
      private appVersion: AppVersion,
      private device: Device,
      private gatewayApi: GatewayApi,
      private gatewayService: GatewayService,
      private platform: Platform
  ) {}

  public async getUserAgentInfo(): Promise<void> {
      if (this.platform.is('cordova')) {
          await this.getAppName();
          await this.getAppVersion();
          this.getUUID();
          this.getOSName();
          await this.getOSVersion();
          this._hrsUserAgentHeader = `${this._appName}/${this._appVersionNumber} UUID/${this._uuid} ${this._osName}/${this._osVersion} ${this._fcmVersion} ${this._nativeBridge}`;
          this.gatewayApi.hrsUserAgentHeader = this._hrsUserAgentHeader;
          this.gatewayService.hrsUserAgentHeader = this._hrsUserAgentHeader;
      }
  }

  private async getAppName(): Promise<void> {
      const name = await this.appVersion.getAppName();
      // remove whitespace
      this._appName = name.replace(/\s/g, '');
  }

  private async getAppVersion(): Promise<void> {
      this._appVersionNumber = await this.appVersion.getVersionNumber();
  }

  private getUUID(): void {
      this._uuid = this.device.uuid;
  }

  private getOSName() {
      if (this.platform.is('ios')) {
          this._osName = 'iOS';
      } else if (this.platform.is('android')) {
          this._osName = 'Android';
      }
  }

  private async getOSVersion() {
      // there is a .str on this.platform.version but if there is no minor version it reads '9.undefined'
      // IONIC UPGRADE - this original code was not working with upgrade
      //   this._osVersion = this.platform.versions().num.toString();
      if (!this._appVersionNumber) await this.getAppVersion();
      this._osVersion = this._appVersionNumber;
  }
}
