import {MetricEntry} from '@hrs/models';

export class StepsEntry extends MetricEntry {
    public steps: number;

    constructor(entry) {
        super(entry.ts, entry.id);
        this.steps = entry.steps;
    }
}
