import {SurveyEntry} from './models';

export class SurveyGroupModel {
    public answerCount: number;
    public averageScore: string;
    public scoreTotal: number;
    public scoreType: string;
    public scoreMessage: string;
    public questions: SurveyEntry[];
    public name: string;
}

