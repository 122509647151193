import {environment} from '@app/env';

export class LocalUpdateInterval {
    /**
     * Get HRS TAB Local Update Interval Length
     * @return number
     *
     */
    static get(): number {
        return environment.UPDATEINTERVAL;
    }
}
