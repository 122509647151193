import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@app/env';
import {Subject, of, Observable} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import * as jwtDecode from 'jwt-decode';
import {TokenData} from '../../interfaces/token-data-response.interface';
import {TokenResponse} from '@hrs/gateway';
import {retryBackoff} from 'backoff-rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
  url: string = environment.API_GATEWAY_URL;
  token: string = null;
  refresh: string = null;
  authTokenData: TokenData;
  sourceApp: string;
  refreshTokenSubject: Subject<any> = new Subject<any>();
  isRetryingToken: boolean = false;
  sessionId: number = 0;

  constructor(private http: HttpClient) {
  }

  public refreshToken(): Observable<any> {
      this.isRetryingToken = true;
      const credentials = {
          data: {
              type: 'refresh',
              code: this.refresh,
              source: this.sourceApp
          }
      };
      return this.http
          .post(`${this.url}/v1/tokens`, credentials)
          .pipe(
              tap((tokens: {data: TokenResponse}) => {
                  this.storeTokens(tokens.data);
                  this.isRetryingToken = false;
                  this.refreshTokenSubject.next(tokens.data);
              }),
              // Upon failing this retries 3 times with a a backoff delay in between 5 seconds, 10 seconds, 20 seconds roughly
              // Not exceeding 45 seconds in between b/c we have a 45 second window on calling the refresh token on api interceptor
              retryBackoff({
                  initialInterval: 5000,
                  maxRetries: 3,
                  maxInterval: 20000,
              }),
              // If the final retry fails, this catches the error and returns it
              catchError((error) => {
                  this.isRetryingToken = false;
                  return of(error);
              }));
  }

  public storeTokens(tokenData: TokenResponse): void {
      let tokens = tokenData.attributes;
      this.token = tokens.token;
      this.refresh = tokens.refresh;
      this.decodeTokenData(tokens.token);
  }

  public removeTokens(): void {
      this.token = null;
      this.refresh = null;
      this.authTokenData = null;
  }

  private decodeTokenData(token) {
      let tokenData = jwtDecode(token);
      this.authTokenData = tokenData as any;
      return tokenData;
  }

  public getExp(): number {
      return this.authTokenData ? this.authTokenData.exp : null;
  }
}
