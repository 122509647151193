import {Component} from '@angular/core';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {TranslateService} from '@ngx-translate/core';
import {ToastController} from '@ionic/angular';
import {User} from '../services/user/user';
import {environment} from '@app/env';
import {GatewayApi} from '@hrs/providers';
import {of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'page-organizational-login',
    templateUrl: 'organizational-login.page.html'
})
export class OrganizationalLoginPage {
    email: string;
    loggingIn: boolean;
    private loggingInSSOSubscription: Subscription;
    private loggedInSubscription: Subscription;
    private emailLookupErrorString: string;
    private loginErrorString: string;
    private loginSuccessString: string;

    constructor(
        private browser: InAppBrowser,
        private user: User,
        private toastCtrl: ToastController,
        private translateService: TranslateService,
        private gatewayApi: GatewayApi,
        private router: Router
    ) {
        this.translateService.get('ORGANIZATIONAL_LOGIN.EMAIL_LOOKUP_ERROR').subscribe((value) => {
            this.emailLookupErrorString = value;
        });
        this.translateService.get('LOGIN_ERROR').subscribe((value) => {
            this.loginErrorString = value;
        });
        this.translateService.get('LOGIN_SUCCESS').subscribe((value) => {
            this.loginSuccessString = value;
        });

        this.loggingInSSOSubscription = this.user.loggingInSSOSubject.subscribe(() => {
            this.loggingIn = true;
        });

        this.loggedInSubscription = this.user.loginResultSubject.subscribe((success) => {
            let toastMessage = this.loginSuccessString;
            if (success) {
                if (this.user.data.type !== 'clinician') {
                    toastMessage = this.loginErrorString;
                } else {
                    this.router.navigateByUrl('');
                }
            } else {
                toastMessage = this.loginErrorString;
            }
            this.loggingIn = false;
            this.viewToast(toastMessage, 3000);
        });
    }

    ngOnDestroy() {
        this.loggingInSSOSubscription.unsubscribe();
        this.loggedInSubscription.unsubscribe();
    }

    async viewToast(message: string, time: number): Promise<any> {
        let toast = await this.toastCtrl.create({
            message: message,
            duration: time,
            buttons: [
                {
                    text: this.translateService.instant('CLOSE_BUTTON'),
                    role: 'cancel'
                }
            ],
            position: 'top'
        });
        return await toast.present();
    }

    doLogin(): void {
        this.loggingIn = true;

        let showEmailLookupError = () => this.viewToast(this.emailLookupErrorString, 5000);

        this.gatewayApi.post('samlemail', {data: {email: this.email}}).pipe(
            tap((res: any) => {
                if (res.data.environment) {
                    // Open browser to the user organization's login page
                    let url = `https://${res.data.environment}.auth.${environment.HRS_DOMAIN}/login?mobile=true`;
                    this.browser.create(url, '_system', 'location=no,clearsessioncache=yes,clearcache=yes');
                    // Set loggingIn to false because if they fail to login via their organization's login page in the browser,
                    // they can try again from our app. We don't receive any event indicating that they failed there.
                    this.loggingIn = false;
                    // After they successfully login in the browser, it will redirect back to our app using the deeplink functionality
                    // in app.component, login to our API, and inform this page to navigate to the MainPage via loggedInSubscription.
                } else {
                    showEmailLookupError();
                    this.loggingIn = false;
                }
            }),
            catchError(() => {
                showEmailLookupError();
                this.loggingIn = false;
                return of({});
            })
        ).subscribe();
    }
}
